import React from 'react';
import { Avatar } from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import coloriVetrina from "json/coloriVetrina";
import PropTypes from "prop-types";

function StatusAvatar({ stato }) {
    let avatarIcon = null;
    let backgroundColor = '';

    switch (stato) {
        case 1:
            avatarIcon = <CheckIcon />;
            backgroundColor = coloriVetrina.green;
            break;
        case 3:
            avatarIcon = <HourglassBottomIcon />;
            backgroundColor = coloriVetrina.warning;
            break;
        case 4:
            avatarIcon = <ClearIcon />;
            backgroundColor = coloriVetrina.error;
            break;
        default:
            break;
    }

    if (avatarIcon) {
        return (
            <Avatar style={{ backgroundColor }}>
                {avatarIcon}
            </Avatar>
        );
    } else {
        return null;
    }
}

StatusAvatar.propTypes = {
    stato: PropTypes.number
}

export default StatusAvatar;