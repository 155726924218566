import React from "react";
import JobErCard from "components/JobErCard";
import { Divider, Grid } from "@mui/material";
import JobErSelect from "components/JobErSelect";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import options from "../../json/option_configuration.json";
import { asyncRicercaRuoli } from "utils/utilsRicerca";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function CardProfiloEsperienzeLavorative(props) {
  const alertDeleteEsperienzeLavorative = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questa esperienza di lavoro?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Esperienze Lavorative"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((esperienza, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <React.Fragment key={`${index}-first`}>
                  <Grid item xs={6} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Lavori ?"}
                      options={options.yes_no}
                      onChange={(data) => {
                        props.onSetDati("lavoriAttualmente", data?.label ? data?.label : null);
                      }}
                      value={esperienza?.lavoriAttualmente ? { label: esperienza.lavoriAttualmente, value: esperienza.lavoriAttualmente } : null}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JobErInput
                      label="Data inizio"
                      type="date"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.dataInizio ? esperienza.dataInizio : ""}
                      onChange={(e) => props.onSetDati("dataInizio", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobErInput
                      label="Azienda"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.nomeAzienda ? esperienza.nomeAzienda : ""}
                      onChange={(e) => props.onSetDati("nomeAzienda", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <JobErAsyncSelect
                      mode="white"
                      menuPosition="fixed"
                      isMulti={false}
                      isClearable="true"
                      placeholder={"Ruolo"}
                      loadOptions={asyncRicercaRuoli}
                      noOptionsMessage={() => "Nessun ruolo trovato"}
                      value={
                        esperienza?.ruolo
                          ? {
                              value: esperienza.ruolo,
                              label: esperienza.ruolo,
                            }
                          : null
                      }
                      onChange={(data) => {
                        props.onSetDati("ruolo", data?.label ? data?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Tipologia contratto"}
                      options={options.tipologie_contratto}
                      onChange={(data) => {
                        props.onSetDati("tipologiaContratto", data?.label ? data?.label : null);
                      }}
                      value={
                        esperienza?.tipologiaContratto
                          ? options.tipologie_contratto.filter(
                              (opt) => opt.label === esperienza.tipologiaContratto || opt.value === esperienza.tipologiaContratto
                            )
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobErInput
                      label="Descrizione"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.descrizione ? esperienza.descrizione : ""}
                      onChange={(e) => props.onSetDati("descrizione", e.target.value)}
                    />
                  </Grid>
                </React.Fragment>
              )}
              {index > 0 && (
                <>
                  <Grid item xs={12} m={1.5}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <JobErInput
                      label="Azienda"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza ? esperienza.nomeAzienda : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "nomeAzienda", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobErAsyncSelect
                      mode="white"
                      menuPosition="fixed"
                      isMulti={false}
                      isClearable="true"
                      placeholder={"Ruolo"}
                      loadOptions={asyncRicercaRuoli}
                      noOptionsMessage={() => "Nessun ruolo trovato"}
                      value={
                        esperienza?.ruolo
                          ? {
                              value: esperienza.ruolo,
                              label: esperienza.ruolo,
                            }
                          : null
                      }
                      onChange={(data) => {
                        props.onSetNestedDati(esperienza.uuid, "ruolo", data?.label ? data?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JobErInput
                      label="Data inizio"
                      type="date"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.dataInizio ? esperienza.dataInizio : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "dataInizio", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JobErInput
                      label="Data fine"
                      type="date"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.dataFine ? esperienza.dataFine : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "dataFine", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Tipologia contratto"}
                      options={options.tipologie_contratto}
                      onChange={(data) => {
                        props.onSetNestedDati(esperienza.uuid, "tipologiaContratto", data?.label ? data?.label : null);
                      }}
                      value={
                        esperienza?.tipologiaContratto
                          ? options.tipologie_contratto.filter(
                              (opt) => opt.label === esperienza.tipologiaContratto || opt.value === esperienza.tipologiaContratto
                            )
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobErInput
                      label="Descrizione"
                      coloreTesto={coloriVetrina.black}
                      coloreBordo={coloriVetrina.gray}
                      size="small"
                      value={esperienza?.descrizione ? esperienza.descrizione : ""}
                      onChange={(e) => props.onSetNestedDati(esperienza.uuid, "descrizione", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobButton
                      label="Rimuovi"
                      colorLabel={coloriVetrina.error}
                      colorBorder={coloriVetrina.error}
                      onClick={() => alertDeleteEsperienzeLavorative(esperienza.uuid)}
                    />
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto" mr={1}>
                <JobButton label="Salva" colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave()} />
                <JobButton label="Aggiungi esperienza" onClick={() => props.onAddNestedObject()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloEsperienzeLavorative;
