import React from "react";
import Chip from "@mui/material/Chip";
import SchoolIcon from "@mui/icons-material/School";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsFiltriTitoloStudio(props) {
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return props.titoloStudio ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <SchoolIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
          </Avatar>
        }
        label={props.titoloStudio.label}
        /* onDelete={handleDelete} */
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
        }}
        /* deleteIcon={<CloseIcon />} */
      />
    </Grid>
  ) : null;
}
export default ChipsFiltriTitoloStudio;
