import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import { useNavigate } from "react-router-dom";
import JobButton from "components/JobButton";
import { ModalContext } from "context/modalLogin";

function SlideAziendaJobEr(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
            Cerchi i migliori talenti per la tua azienda?
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh" }}>
            Ecco perchè scegliere JOB Confartigianato Emilia Romagna
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Ampio bacino di talenti:</b>
            <br></br>
            Trova i candidati perfetti per la tua azienda, con una vasta gamma di competenze e profili professionali.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Risparmia tempo e denaro:</b>
            <br></br>
            Pubblica annunci di lavoro in modo facile e veloce, insieme al nostro team, senza dover investire in costose campagne pubblicitarie;
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            label={"Effettua L'Accesso"}
            onClick={() => {
              setIsModalOpen(true);
            }}
            fontSize="18px"
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <JobButton
            colorBackground={coloriVetrina.white}
            colorLabel={coloriVetrina.blue}
            colorBorder={coloriVetrina.white}
            size="large"
            label={"Registrati"}
            onClick={() => navigate("/Vetrina/Registrati")}
            fontSize="18px"
          />
        </Grid>
      </Grid>
    </>
  );
}
export default SlideAziendaJobEr;
