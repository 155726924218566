import React, { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { ReactComponent as BB_LONG_ER } from "../../svgicon/JOB_LOGO_CONFER_WHITE.svg";
import { ReactComponent as BB_LONG_MORE } from "../../svgicon/Logo_MylapamJob_WHITE.svg";
import { ReactComponent as BB_SHORT } from "../../svgicon/JOB_LOGO_CONFER_WHITE.svg";
import { useNavigate } from "react-router-dom";
import { LockOpen } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import MenuPortableDevice from "./MenuPortableDevice";
import { ModalContext } from "context/modalLogin";
import PopoverLogin from "./Login/PopoverLogin";
import coloriVetrina from "json/coloriVetrina";

const pages = [
  { descrizione: "Home", url: "Vetrina/Home" },
  { descrizione: "Per i candidati", url: "Vetrina/Persone" },
  { descrizione: "Per le aziende", url: "Vetrina/Aziende" },
];

function AppBarJob(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  const activePage = props.activePage;
  const colorText = props.color;
  const bgcolor = props.bgcolor;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <AppBar position="static" sx={{ backgroundColor: bgcolor, pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 } }}>
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <BB_LONG_MORE
              fill={colorText}
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/Vetrina/Home")}
            ></BB_LONG_MORE>
          ) : (
            <BB_LONG_ER
              fill={colorText}
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xxl bb_menu"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/Vetrina/Home")}
            ></BB_LONG_ER>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <MenuPortableDevice pages={pages} />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" }, mt: 2 }}>
          <BB_SHORT fill={colorText} className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_sm" onClick={() => navigate("/Vetrina/Home")}></BB_SHORT>
        </Box>
        <Grid container justifyContent={"space-between"} sx={{ display: { xs: "none", md: "flex" }, ml: 5 }}>
          <Box sx={{ display: "flex" }}>
            {pages.map((page, index) => {
              return page.url !== "Vetrina/Home" ? (
                <Button
                  className="menu-bb"
                  key={page.url + "_md"}
                  onClick={() => navigate("/" + page.url.replaceAll(" ", ""))}
                  sx={{ color: colorText, display: "block", mr: 4 }}
                >
                  <Typography sx={{ marginTop: "1vh", color: colorText }}>{page.descrizione}</Typography>
                </Button>
              ) : null;
            })}
          </Box>
          <Box>
            <Button
              className="btn-bb-slim-cdq"
              style={{
                "--back": open ? colorText : bgcolor,
                "--col": open ? bgcolor : colorText,
              }}
              onClick={() => navigate("/Vetrina/Registrati")}
              sx={{
                ml: 2,
                mr: 4,
                borderColor: colorText,
                border: "solid .3vh",
                borderRadius: "5vh 5vh 5vh 5vh !important",
                backgroundColor: coloriVetrina.white + " !important",
              }}
            >
              <AddIcon sx={{ mr: 1, color: coloriVetrina.blue }} />
              <Typography sx={{ color: coloriVetrina.blue }}>Registrati</Typography>
            </Button>

            <Button
              id="buttoneLogin"
              className="btn-bb-slim-cdq"
              style={{
                "--back": open ? colorText : bgcolor,
                "--col": open ? bgcolor : colorText,
              }}
              sx={{
                ml: 2,
                mr: 4,
                borderColor: "#FFFFFF!important",
                border: "solid .3vh",
                borderRadius: "5vh 5vh 5vh 5vh !important",
              }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <LockOpen sx={{ mr: 1 }} />
              <Typography sx={{ color: colorText }}>Login</Typography>
            </Button>
          </Box>
        </Grid>
      </Toolbar>
      <PopoverLogin />
    </AppBar>
  );
}
export default AppBarJob;
