import React from "react";
import { Grid } from "@mui/material";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";

function AnnuncioStep6(props) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12}>
            <JobErInput
              label="Ulteriori competenze"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati ? props.dati.competenzeCandidato : ""}
              onChange={(e) => props.onSetDati("competenzeCandidato", e.target.value)}
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <JobErInput
              label="Profilo ricercato"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati ? props.dati.profiloCandidato : ""}
              onChange={(e) => props.onSetDati("profiloCandidato", e.target.value)}
              multiline={true}
              rows={5}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep6;
