import React from 'react';
import AnnuncioStep0 from 'components/PortaleAziende/AnnuncioStep0';
import AnnuncioStep1 from 'components/PortaleAziende/AnnuncioStep1';
import AnnuncioStep2 from 'components/PortaleAziende/AnnuncioStep2';
import AnnuncioStep3 from 'components/PortaleAziende/AnnuncioStep3';
import AnnuncioStep4 from 'components/PortaleAziende/AnnuncioStep4';
import AnnuncioStep5 from 'components/PortaleAziende/AnnuncioStep5';
import AnnuncioStep6 from 'components/PortaleAziende/AnnuncioStep6';
import PropTypes from "prop-types";

function DynamicAnnuncioStep({ activeStep, datiAnnuncio, setDatiAnnuncio }) {
    switch (activeStep) {
        case 0:
            return (
                <AnnuncioStep0
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 1:
            return (
                <AnnuncioStep1
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 2:
            return (
                <AnnuncioStep2
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 3:
            return (
                <AnnuncioStep3
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 4:
            return (
                <AnnuncioStep4
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 5:
            return (
                <AnnuncioStep5
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )
        case 6:
            return (
                <AnnuncioStep6
                    dati={datiAnnuncio}
                    onSetDati={(key, val) =>
                        setDatiAnnuncio({
                            ...datiAnnuncio,
                            [key]: val,
                        })}
                />
            )

        default:
            return null;
    }
}

DynamicAnnuncioStep.propTypes = {
    activeStep: PropTypes.number,
    datiAnnuncio: PropTypes.array,
    setDatiAnnuncio: PropTypes.func
  };

export default DynamicAnnuncioStep;