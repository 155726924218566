import moment from "moment";
import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function verificaVisibilitaAnnuncio(filtri, annuncio) {
  if (filtri.comune && filtri.comune.length > 0 && annuncio.comuneLavoro) {
    if (filtri.comune.filter((com) => com.value === annuncio.comuneLavoro).length === 0) {
      return false;
    }
  }

  if (filtri.provincia && filtri.provincia.length > 0 && annuncio?.comune?.prov) {
    if (filtri.provincia.filter((com) => com.value === annuncio.comune.prov).length === 0) {
      return false;
    }
  }

  if (filtri.esperienza && Object.keys(filtri.esperienza).length > 0) {
    if (filtri.esperienza.value === "nessuno" && annuncio.anniEsperienza !== null) {
      return false;
    }
    if (filtri.esperienza.value === "5_anni" && (!annuncio.anniEsperienza || annuncio.anniEsperienza < 5)) {
      return false;
    }
    if (filtri.esperienza.value === "10_anni" && (!annuncio.anniEsperienza || annuncio.anniEsperienza < 10)) {
      return false;
    }
  }
  if (filtri.titoloStudio && Object.keys(filtri.titoloStudio).length > 0) {
    if (filtri.titoloStudio.value === "diploma" && !annuncio?.titoloStudio?.includes("scuola_superiore")) {
      return false;
    }
    if (filtri.titoloStudio.value === "laurea" && !annuncio?.titoloStudio?.includes("laurea")) {
      return false;
    }
  }
  if (filtri.periodo && Object.keys(filtri.periodo).length > 0) {
    if (filtri.periodo.value === "mese_corrente" && (!annuncio.dataPubblicazione || !dateInCurrentMonth(annuncio.dataPubblicazione))) {
      return false;
    }
    if (filtri.periodo.value === "ultima_settimana" && (!annuncio.dataPubblicazione || !dateInCurrentWeek(annuncio.dataPubblicazione))) {
      return false;
    }
    if (filtri.periodo.value === "ultimi6" && (!annuncio.dataPubblicazione || !isDateInLast6Months(annuncio.dataPubblicazione))) {
      return false;
    }
  }
  if (filtri.settore && filtri.settore.length > 0 && annuncio?.codificaMansione?.descrizione) {
    if (filtri.settore.filter((com) => com.label === annuncio.codificaMansione.descrizione).length === 0) {
      return false;
    }
  }
  return true;
}

function dateInCurrentMonth(date) {
  let month = date.split("-")[1];
  let year = date.split("-")[0];

  let currentdate = new Date();
  let cur_month = currentdate.getMonth() + 1;
  let cur_year = currentdate.getFullYear();

  return !!(cur_month === month && year === cur_year);
}

function dateInCurrentWeek(date) {
  const now = moment();
  const input = moment(date);
  return now.isoWeek() === input.isoWeek();
}

function isDateInLast6Months(date) {
  // Converti la data in un oggetto Moment
  const momentDate = moment(date);
  // Controlla se la data è negli ultimi 6 mesi
  return momentDate.isAfter(moment().subtract(6, "months"));
}

export function getElencoAnnunci(authUser, setElencoAnnunci, setLoading) {
  setLoading(true);

  fetch(process.env.REACT_APP_URL + "/persone/elencoAnnunci/" + authUser.codiceFiscale, {
    method: "GET",
    headers: getAuthHeader(),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        setLoading(false);
        setElencoAnnunci(result);
      },
      (error) => {
        setLoading(false);
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
      }
    );
}

export function getElencoUltimiAnnunci(setUltimiAnnunci, setLoading) {
  setLoading(true);
  fetch(process.env.REACT_APP_URL + "/vetrina/elencoUltimiAnnunci", {
    method: "GET",
  })
    .then((res) => res.json())
    .then(
      (result) => {
        setLoading(false);
        setUltimiAnnunci(result);
      },
      (error) => {
        setLoading(false);
        console.error("Si è verificato un errore nel caricamento degli annunci");
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
      }
    );
}
