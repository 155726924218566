import React, { useEffect, useState } from "react";
import { useJobErController } from "context";
import LayoutAziende from "components/PortaleAziende/LayoutAziende";
import { Grid } from "@mui/material";
import { getHomeAnnunciAzienda, getHomeCandidatureAzienda, getHomePresentazioneAzienda } from "./utils/utilsHomepage";
import HomeAziendePresentazione from "./HomeAziende/HomeAziendePresentazione";
import HomeAziendeAnnunci from "./HomeAziende/HomeAziendeAnnunci";
import HomeAziendeCandidature from "./HomeAziende/HomeAziendeCandidature";

function HomepageAziende(props) {
  const [annunci, setAnnunci] = useState({ dati: null, loading: true });
  const [presentazione, setPresentazione] = useState({ dati: null, loading: true });
  const [candidature, setCandidature] = useState({ dati: null, loading: true });
  const [controller] = useJobErController();
  const { authUser } = controller;

  useEffect(() => {
    if (authUser) {
      getHomeAnnunciAzienda(localStorage.getItem("partitaIva"), null).then((res) => setAnnunci({ dati: res, loading: false }));
      getHomePresentazioneAzienda(localStorage.getItem("partitaIva"))
        .then((res) => {
          if (res) {
            setPresentazione({
              dati: {
                copertina: res.copertina ? res.copertina : null,
                miniatura: res.miniatura ? res.miniatura : null,
                ragioneSociale: res.ragioneSociale ? res.ragioneSociale : "",
                sito: res.sitoWeb ? res.sitoWeb : null,
              },
              loading: false,
            });
          }
        })
        .catch((error) => {
          setPresentazione({
            dati: {
              copertina: null,
              miniatura: null,
              ragioneSociale: null,
              sito: null,
            },
            loading: false,
          });
          console.error("Si è verificato un errore");
        });
      getHomeCandidatureAzienda(localStorage.getItem("partitaIva"), null).then((res) => setCandidature({ dati: res, loading: false }));
    }
  }, [authUser]);

  return (
    <LayoutAziende
      child={
        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: 2, md: 0 },
            backgroundColor: "#002b48!important",
          }}
        >
          <HomeAziendePresentazione presentazione={presentazione} />
          <HomeAziendeAnnunci annunci={annunci} />
          <HomeAziendeCandidature candidature={candidature} />
        </Grid>
      }
    />
  );
}

export default HomepageAziende;
