import LayoutPersone from "components/PortalePersone/LayoutPersone";
import React, { useEffect, useState, useRef } from "react";
import { Grid, Divider } from "@mui/material";
import JobErCard from "components/JobErCard";
import SezioneFiltriAnnunci from "components/PortalePersone/SezioneFiltriAnnunci";
import ListaAnnunci from "components/PortalePersone/ListaAnnunci";
import { useSearchParams } from "react-router-dom";
import { useJobErController } from "context";
import { getElencoAnnunci } from "components/PortalePersone/utils/utils_annuncio";
import { eliminaCandidaturaAnnuncio } from "components/PortalePersone/utils/utils_candidatura";
import CircularProgress from "@mui/material/CircularProgress";
import JobLoading from "components/JobLoading";

function AnnunciPersone(props) {
  const [filter, setFilter] = useState({});
  const [elencoAnnunci, setElencoAnnunci] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const [uuidParam, setUuidParam] = useState(null);

  const [controller] = useJobErController();
  const { authUser } = controller;

  const articleRefs = useRef([]);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key === "annuncio") {
        setUuidParam(value);
      }
    });

    if (authUser?.codiceFiscale) {
      getElencoAnnunci(authUser, setElencoAnnunci, setLoading);
    }

    const articleElement = articleRefs.current[uuidParam];
    if (articleElement) {
      articleElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [authUser]);

  return (
    <>
      {loading ? (
        <JobLoading />
      ) : (
        <LayoutPersone
          child={
            <Grid container p={2}>
              <Grid item xs={12}>
                <JobErCard
                  title="Elenco Annunci"
                  style={{ minHeight: "300px" }}
                  child={
                    <>
                      <SezioneFiltriAnnunci filter={filter} onSetFilter={(filtro, dato) => setFilter({ ...filter, [filtro]: dato })} />
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                      {elencoAnnunci && loading === false ? (
                        <ListaAnnunci
                          searchParams={uuidParam}
                          filtri={filter}
                          annunci={elencoAnnunci}
                          setAnnunci={setElencoAnnunci}
                          user={authUser}
                          onSendCandidatura={(candidatura) =>
                            setElencoAnnunci(() => {
                              const updatedAnnunci = elencoAnnunci.map((annuncio) => {
                                if (annuncio.uuid === candidatura.uuidAnnuncio) {
                                  let candidature = [];
                                  candidature.push(candidatura);
                                  return {
                                    ...annuncio,
                                    candidature: candidature,
                                  };
                                }
                                return annuncio;
                              });
                              return updatedAnnunci;
                            })
                          }
                          onRemoveCandidatura={(annuncio) => {
                            if (annuncio?.candidature[0]) {
                              eliminaCandidaturaAnnuncio(setElencoAnnunci, elencoAnnunci, annuncio);
                            }
                          }}
                        />
                      ) : (
                        <Grid container justifyContent="center" mt={4}>
                          <Grid item xs={12}>
                            <CircularProgress />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  }
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}
export default AnnunciPersone;
