import JobTable from 'components/JobTable';
import { useEffect, useMemo, useState } from 'react';
import { getAnnunciConfartigianato } from "components/PortaleConfartigianato/utils_confartigianato/annunciConfartigianato"
import { useJobErController } from "context";
import Chip from '@mui/material/Chip';
import coloriVetrina from "json/coloriVetrina"

function StoricoAnnunci (props) {

    const [annunci, setAnnunci] = useState([])
    const [controller] = useJobErController();
    const { authUser } = controller;
    const [pending, setPending] = useState(false)

    useEffect(() => {

        if(props.value === props.index)
        {
            if(authUser)
            {
              getAnnunciConfartigianato(setAnnunci, authUser.codConfartigianato, setPending)
            }
        }

    }, [props.value])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'statoLabel',
        header: 'Stato',
        size: 150,
        Cell: ({row}) => (
          <Chip label={row.original.statoLabel.toUpperCase()} variant="outlined" sx={{ color: row.original.statoColor, borderColor: row.original.statoColor }} />
        )   
      },
      {
        accessorKey: 'tipoRicerca',
        header: 'Modalità',
        size: 150,
        Cell: ({row}) => {
          switch(row.original.tipoRicerca)
          {
            case "attiva":
            return (
              <Chip label={row.original.tipoRicerca.toUpperCase()} variant="outlined" sx={{ color: coloriVetrina.green, borderColor: coloriVetrina.green }} />
            )
            case "passiva":
            return (
              <Chip label={row.original.tipoRicerca.toUpperCase()} variant="outlined" sx={{ color: coloriVetrina.error, borderColor: coloriVetrina.error }} />
            )
            default:
              return(<></>)
          }
        }
      },
      {
        accessorKey: 'ragioneSocialePiva',
        header: 'Ragione Sociale',
        size: 200,
      },
      {
        accessorKey: 'partitaIva',
        header: 'Partiva Iva',
        size: 150,
      },
      {
        accessorKey: 'titoloAnnuncio',
        header: 'Titolo Annuncio',
        size: 150,
      },
      {
        accessorKey: 'posizioneLavorativaDescrizione',
        header: 'Posizione Ricercata',
        size: 150,
      },
      {
        accessorKey: 'countCandidature',
        header: 'Candidati',
        size: 150,
      },
    ],
    [],
  );

  return(
    props.value === props.index ? 
        <JobTable data={annunci} columns={columns} state={{isLoading: pending}} />
    : null);
};

export default StoricoAnnunci