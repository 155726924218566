import React from "react";
import "../css/JobEr.css";
import { Button } from "@mui/material";
import coloriVetrina from "../json/coloriVetrina.json";
import CircularProgress from '@mui/material/CircularProgress';

function JobButton(props) {
    
    return (
        <Button
            className="btn-bb-slim"
            size={props.size}
            style={{
                cursor: props.cursor ? props.cursor : "pointer",
                backgroundColor: props.colorBackground ? props.colorBackground : coloriVetrina.white,
                color: props.colorLabel ? props.colorLabel : coloriVetrina.blue,
                border: "2px solid " + (props.colorBorder ? props.colorBorder : coloriVetrina.blue),
                fontSize: props.fontSize ? props.fontSize : "12px",
            }}
            onClick={(e) => props.onClick ? props.onClick(e) : () => null}
            disabled={(props.disabled || props.loading) ? true : false}
        >
            {props.label && !props.loading ? props.label : props.loading ? <CircularProgress size={ props.fontSize ? parseInt(props.fontSize.replace("px", '')) : 12 } /> : "testo"}
        </Button>
    )
}

export default JobButton;