import React from "react";
import "../css/JobEr.css";
import { Typography } from "@mui/material";
import coloriVetrina from "../json/coloriVetrina.json";

function JobTypography(props) {
    
    let typographyProps = {};

    switch (props.tipo) {
        case "titoloLista":
            typographyProps = {
                sx: {
                    fontSize: "16px!important",
                    color: coloriVetrina.blue,
                    fontWeight: "bold!important",
                }
            };
            break;
        case "descrizioneLista":
            typographyProps = {
                sx:{ display: "inline" },
                component:"span",
                variant:"body2",
                color:"text.secondary",
            };
            break;
        case "titoloSezione":
            typographyProps = {
                variant:"h6",
                fontWeight:"bold",
                style:{ color: coloriVetrina.blue }
            };
            break;
        default:
            typographyProps = {
                sx:{ display: "inline" },
                component:"span",
                variant:"body2",
                color:"text.secondary",
            };
            break;
    }
    
    return (
        <Typography {...typographyProps}>
            {props.testo ? props.testo: ""}
        </Typography>
    )
}

export default JobTypography;