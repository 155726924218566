import { Toast } from "components/Toast";

export function VerificaStep(dati, stepWithError, setStepWithError, stepCompleted, setStepCompleted) {

    var copyStepCompleted = [...stepCompleted]
    var copyStepWithError = [...stepWithError]

    for (var i = 1; i < 8; i++) {
        if (i === 1) {

            if (dati.tipoRicerca) {
                if (!stepCompleted.includes(i)) {
                    copyStepCompleted.push(i)
                }

                if (stepWithError.includes(i)) {
                    copyStepWithError = copyStepWithError.filter(obj => obj !== i)
                }

            } else {
                if (!stepWithError.includes(i)) {
                    copyStepWithError.push(i)
                }

                if (stepCompleted.includes(i)) {
                    copyStepCompleted = stepCompleted.filter(obj => obj !== i)
                }

                Toast.fire({ icon: "error", title: "Step " + i + ", compilare tutti i campi obbligatori!" });
            }
        } else if (i === 2) {
            if (
                dati.titoloAnnuncio &&
                dati.indirizzo &&
                (dati.posizioneLavorativa || dati.codificaMansione)
            ) {
                if (!stepCompleted.includes(i)) {
                    copyStepCompleted.push(i)
                }

                if (stepWithError.includes(i)) {
                    copyStepWithError = copyStepWithError.filter(obj => obj !== i)
                }

            } else {
                if (!stepWithError.includes(i)) {
                    copyStepWithError.push(i)
                }

                if (stepCompleted.includes(i)) {
                    copyStepCompleted = stepCompleted.filter(obj => obj !== i)
                }

                Toast.fire({ icon: "error", title: "Step " + i + ", compilare tutti i campi obbligatori!" });
            }
        } else if (i === 3) {

            if (
                dati?.posizioniAperte && // Checks if there are open positions
                dati?.descrizioneLavoro && // Checks if there is a job description
                dati?.richiestaEsperienza && // Checks if experience requirement is specified
                (
                    (typeof dati?.richiestaEsperienza === "object" && // If `richiestaEsperienza` is an object
                        dati?.richiestaEsperienza?.value === "no") || // and its value is "no"
                    (typeof dati?.richiestaEsperienza === "object" && 
                        dati?.richiestaEsperienza?.value === "si" && dati?.anniEsperienza) || // OR its value is "si" and years of experience is provided
                    (typeof dati?.richiestaEsperienza === "string" && // OR if `richiestaEsperienza` is a string
                        dati?.richiestaEsperienza === "no") || // and its value is "no"
                    (dati?.richiestaEsperienza === "si" && dati?.anniEsperienza) // OR its value is "si" and years of experience is provided
                )
            ) {
                if (!stepCompleted.includes(i)) {
                    copyStepCompleted.push(i)
                }

                if (stepWithError.includes(i)) {
                    copyStepWithError = copyStepWithError.filter(obj => obj !== i)
                }

            } else {
                if (!stepWithError.includes(i)) {
                    copyStepWithError.push(i)
                }

                if (stepCompleted.includes(i)) {
                    copyStepCompleted = stepCompleted.filter(obj => obj !== i)
                }

                Toast.fire({ icon: "error", title: "Step " + i + ", compilare tutti i campi obbligatori!" });
            }
        } else {

            if (!stepCompleted.includes(i)) {
                copyStepCompleted.push(i)
            }

        }

        setStepCompleted(copyStepCompleted)
        setStepWithError(copyStepWithError);
    }

    if (copyStepWithError.length > 0) {
        return false
    }
    else{
        return true
    }
}