import React, { useEffect, useState } from "react";
import JobErCard from "components/JobErCard";
import { Grid, Typography, Divider } from "@mui/material";
import JobErDropzone from "components/JobErDropzone";
import coloriVetrina from "../../json/coloriVetrina.json";
import { getBase64, downloadDocumento } from "utils/utilsDati";
import JobButton from "components/JobButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteDocumentoProfilo } from "components/PortalePersone/utils/utils_profilo";
import { Toast } from "components/Toast";
import Swal from "sweetalert2";

function CardProfiloDocumenti(props) {
  const [docsCv, setDocsCv] = useState([]);

  const alertDeleteDocumento = (tipoDocumento) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare il documento?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocumentoProfilo(props.authUser, tipoDocumento, props.setDati, setDocsCv);
      } else if (result.isDenied) {
      }
    });
  };

  useEffect(() => {
    let arrayDoc = [];

    props.dati.forEach((element) => {
      if (element.documento) {
        arrayDoc.push(element.documento);
      }
    });

    setDocsCv(arrayDoc);
  }, [props.dati]);

  return (
    <JobErCard
      title="Documenti"
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{
                fontSize: "14px",
                textAlign: "left",
                fontWeight: "bold",
                color: coloriVetrina.blue,
              }}
            >
              Curriculum vitae
            </Typography>
          </Grid>
          {props.dati[0]?.documento ? (
            <Grid item xs={12}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ArrowCircleDownIcon
                        sx={{ color: coloriVetrina.blue }}
                        onClick={() => {
                          let fileName = "cv_" + props.authUser.codiceFiscale;
                          downloadDocumento(docsCv[0], fileName);
                        }}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <DeleteIcon
                        sx={{ color: coloriVetrina.error }}
                        onClick={() => {
                          alertDeleteDocumento("cv");
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="CV Caricato" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <JobErDropzone
                maxFiles={1}
                files={docsCv}
                onRemoveFile={() => setDocsCv([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    if (data && data.includes("application/pdf")) {
                      setDocsCv([]);
                      let arrayCv = [];
                      arrayCv.push(data);
                      setDocsCv(arrayCv);
                    } else {
                      Toast.fire({ icon: "error", title: "Puoi caricare solo PDF" });
                    }
                  })
                }
              />
            </Grid>
          )}
          {/* <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: coloriVetrina.blue,
                }}
              >
                Lettera motivazionale
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <JobErDropzone
                maxFiles={1}
                files={docLm}
                onRemoveFile={() => setDocs([])}
                onSetFiles={(files) =>
                  getBase64(files[0]).then((data) => {
                    props.onSetDati("doc_lm", data);
                    Toast.fire({ icon: "success", title: "Documento caricato" });
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <JobErInput
                label="Hobby"
                coloreTesto={coloriVetrina.black}
                coloreBordo={coloriVetrina.gray}
                size="small"
                value={props.dati ? props.dati.hobby : ""}
                onChange={(e) => props.onSetDati("hobby", e.target.value)}
              />
            </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <JobButton colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave(docsCv)} label={"Salva"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloDocumenti;
