import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobButton from "components/JobButton";

function SelezioneLogin(props) {
  return (
    <Grid container style={{ padding: 30, paddingTop: 0 }}>
      {!props.noimage ? (
        <Grid item xs={12} textAlign="center" mb={2}>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <img
              alt="immagine modena-reggio"
              src={require("../../../immagini/MyLapamJOB_Logo.png")}
              style={{
                height: "70px",
                width: "280px",
                borderRadius: 15,
                marginBottom: "20px",
              }}
            />
          ) : (
            <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
          )}
        </Grid>
      ) : null}
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6" fontWeight="bold" fontSize="15px" style={{ color: coloriVetrina.blue }}>
          SELEZIONA TIPO ACCESSO
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} mt={3} textAlign="center">
        <JobButton
          label="Azienda"
          size="large"
          fontSize="18px"
          onClick={() => {
            if (process.env.REACT_APP_AMBIENTE === "modena-reggio") {
              window.location.href = "https://my.lapam.eu";
              return null;
            } else {
              props.onSelectTipoAccesso("azienda");
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} mt={3} textAlign="center">
        <JobButton
          label="Candidato"
          size="large"
          fontSize="18px"
          onClick={() => {
            props.onSelectTipoAccesso("persona");
          }}
        />
      </Grid>
    </Grid>
  );
}
export default SelezioneLogin;
