import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import coloriVetrina from "../../json/coloriVetrina.json";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FactoryIcon from "@mui/icons-material/Factory";
import ImageIcon from "@mui/icons-material/Image";
import LanguageIcon from "@mui/icons-material/Language";
import { Avatar } from "@mui/material";

function StepperHomepage(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {props.presentazione
        ? Object.keys(props.presentazione).map((step, index) => (
            <Step key={step + "_" + index} completed={false}>
              <StepLabel
                icon={
                  <Avatar
                    variant="outlined"
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: coloriVetrina.blue,
                    }}
                  >
                    {step === "miniatura" || step === "copertina" ? (
                      <ImageIcon sx={{ fontSize: "18px" }} />
                    ) : step === "ragioneSociale" ? (
                      <FactoryIcon sx={{ fontSize: "18px" }} />
                    ) : step === "sito" ? (
                      <LanguageIcon sx={{ fontSize: "18px" }} />
                    ) : null}
                  </Avatar>
                }
                onClick={() => setActiveStep(index)}
                optional={
                  props.presentazione[step] && props.presentazione[step] !== "" ? (
                    <Typography
                      component="p"
                      ml={1}
                      sx={{
                        fontSize: "12px!important",
                        color: coloriVetrina.green,
                        textTransform: "capitalize",
                      }}
                    >
                      <CheckIcon sx={{ fontSize: "12px!important" }} /> Compilato
                    </Typography>
                  ) : (
                    <Typography
                      component="p"
                      ml={1}
                      sx={{
                        fontSize: "12px!important",
                        color: coloriVetrina.error,
                        textTransform: "capitalize",
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "12px!important" }} />
                      Da compilare
                    </Typography>
                  )
                }
              >
                <Typography
                  component="p"
                  ml={1}
                  sx={{
                    fontSize: "14px!important",
                    color: coloriVetrina.blue,
                    textTransform: "capitalize",
                  }}
                >
                  {step === "ragioneSociale" ? "Ragione Sociale" : step}
                </Typography>
              </StepLabel>
              <StepContent>
                {(step === "miniatura" || step === "copertina") && props.presentazione[step] && props.presentazione[step] !== "" ? (
                  <img
                    src={props.presentazione[step]}
                    style={{
                      maxHeight: "200px",
                      width: "100%",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <Typography
                    component="p"
                    mt={1}
                    ml={1}
                    sx={{
                      fontSize: "20px!important",
                      color: coloriVetrina.blue,
                      fontWeight: "bold!important",
                      textAlign: "left",
                    }}
                  >
                    {props.presentazione[step]}
                  </Typography>
                )}
              </StepContent>
            </Step>
          ))
        : null}
    </Stepper>
  );
}

export default StepperHomepage;
