import React from "react";
import JobErCard from "components/JobErCard";
import { Grid, Divider } from "@mui/material";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function CardProfiloTitoliStudio(props) {
  const alertDeleteTitoloStudio = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questo interesse?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Titoli studio"
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((titoloStudio, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6} textAlign="left">
                <JobErSelect
                  mode="white"
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder={"Titolo di ottenuto"}
                  options={options.titoli_studio}
                  onChange={(data) => {
                    props.onSetNestedDati(titoloStudio.uuid, "titoloStudio", data.label);
                  }}
                  value={titoloStudio?.titoloStudio ? { label: titoloStudio.titoloStudio, value: titoloStudio.titoloStudio } : null}
                />
              </Grid>
              <Grid item xs={12} md={6} textAlign="left">
                <JobErSelect
                  mode="white"
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder={"Corso frequentato"}
                  onChange={(data) => {
                    props.onSetNestedDati(titoloStudio.uuid, "corsoFrequentato", data.label);
                  }}
                  value={
                    titoloStudio?.corsoFrequentato
                      ? options.corso_frequentato.filter((opt) => opt.label.toLowerCase() === titoloStudio.corsoFrequentato.toLowerCase())
                      : null
                  }
                  options={options.corso_frequentato}
                />
              </Grid>
              <Grid item xs={12} textAlign="left">
                <JobErSelect
                  mode="white"
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder={"In corso ?"}
                  options={options.yes_no}
                  onChange={(data) => {
                    props.onSetNestedDati(titoloStudio.uuid, "studiInCorso", data?.label ? data?.label : data);
                  }}
                  value={
                    titoloStudio?.studiInCorso ? options.yes_no.filter((opt) => opt.label.toLowerCase() === titoloStudio.studiInCorso.toLowerCase()) : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JobErInput
                  label="Data inizio"
                  type="date"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={coloriVetrina.gray}
                  size="small"
                  value={titoloStudio?.dataInizioCorso ? titoloStudio.dataInizioCorso : ""}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "dataInizioCorso", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JobErInput
                  label="Data fine"
                  type="date"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={coloriVetrina.gray}
                  size="small"
                  value={titoloStudio?.dataFineCorso ? titoloStudio.dataFineCorso : ""}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "dataFineCorso", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <JobErInput
                  label="Istituto formazione"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={coloriVetrina.gray}
                  size="small"
                  value={titoloStudio?.istitutoFormazione || ""}
                  onChange={(e) => props.onSetNestedDati(titoloStudio.uuid, "istitutoFormazione", e.target.value)}
                />
              </Grid>

              {index > 0 && (
                <Grid item xs={12}>
                  <JobButton
                    label="Rimuovi"
                    colorLabel={coloriVetrina.error}
                    colorBorder={coloriVetrina.error}
                    onClick={() => alertDeleteTitoloStudio(titoloStudio.uuid)}
                  />
                </Grid>
              )}
              <Grid item xs={12} m={1.5}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <JobButton label="Salva" colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave()} />
                <JobButton label="Aggiungi titolo di studio" onClick={() => props.onAddNestedObject()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloTitoliStudio;
