import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Button, Divider, Avatar } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useJobErController } from "context";
import LoginIcon from "@mui/icons-material/Login";
import JobErSelect from "components/JobErSelect";

function MenuAvatarPortableDevice(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [optionAziende, setOptionAziende] = useState([]);
  const [aziendaSelezionata, setAziendaSelezionata] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (authUser && authUser.abbinamenti && authUser.abbinamenti.length > 1) {
      var opzioni = [];
      authUser.abbinamenti.map((abbinamento) =>
        opzioni.push({
          label: abbinamento.anagraficaAzienda.ragioneSociale,
          value: abbinamento.anagraficaAzienda.partitaIva,
          disabled: abbinamento.anagraficaAzienda.partitaIva === localStorage.getItem("partitaIva") ? true : false,
        })
      );
      setOptionAziende(opzioni);
      var gestita = authUser.abbinamenti.filter((abbinamento) => abbinamento.partitaIva === localStorage.getItem("partitaIva"));
      if (gestita && gestita[0]) {
        setAziendaSelezionata({
          label: gestita[0].anagraficaAzienda.ragioneSociale,
          value: gestita[0].anagraficaAzienda.partitaIva,
        });
      }
    }
  }, [authUser]);

  return (
    <>
      <Avatar
        alt="Remy Sharp"
        sx={{ width: 55, height: 55 }}
        /*src={imageProfilo}*/
        onClick={handleOpenNavMenu}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "90%",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container justifyContent="center">
            <Grid item xs="auto"></Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                color={coloriVetrina.blue}
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  textTransform: "uppercase",
                  whiteSpace: "normal",
                }}
              >
                Stai gestendo
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <JobErSelect
                mode="white"
                menuPosition="fixed"
                isClearable="true"
                placeholder={"Azienda gestita"}
                onChange={(data) => setAziendaSelezionata(data)}
                value={aziendaSelezionata}
                options={optionAziende}
              />
            </Grid>
            <Grid item xs={12} mt={1} textAlign="center">
              <Button
                className="btn-bb-slim"
                sx={{
                  p: "0.6vh !important",
                  textAlign: "left",
                }}
                style={{
                  "--col": coloriVetrina.blue,
                  border: "1px solid " + coloriVetrina.blue,
                }}
                onClick={() => null}
              >
                <LoginIcon fontSize="16px" />
                <Typography component="p" fontSize="14px" ml={1} sx={{ fontSize: "15px!important" }}>
                  Gestisci
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} mt={1} textAlign="center">
              <Divider />
            </Grid>
            <Grid item xs={12} mt={2} textAlign="center">
              <Typography
                variant="h3"
                color={coloriVetrina.blue}
                sx={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                  fontWeight: "bold",
                }}
              >
                {authUser ? authUser.nome + " " + authUser.cognome : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2} textAlign="center">
              <Typography
                variant="h3"
                color={coloriVetrina.blue}
                sx={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                }}
              >
                {authUser ? authUser.email : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" mt={1}>
              <Button
                className="btn-bb-slim"
                sx={{
                  p: "0.8vh !important",
                  textAlign: "left",
                }}
                style={{
                  "--col": coloriVetrina.error,
                  border: "1px solid " + coloriVetrina.error,
                }}
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("partitaIva");
                  navigate("/Vetrina/Home");
                }}
              >
                <PowerSettingsNewIcon fontSize="16px" />
                <Typography component="p" fontSize="14px" ml={1} sx={{ fontSize: "15px!important" }}>
                  Logout
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuAvatarPortableDevice;
