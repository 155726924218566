import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import JobErSelect from "components/JobErSelect";
import coloriVetrina from "../../json/coloriVetrina.json";
import { disponibilitaTipologiaAnnunci } from "pages/portale_aziende/utils/utilsAnnunci";
import { useJobErController } from "context";

function AnnuncioStep0(props) {
  const [controller, dispatch] = useJobErController();
  const { authUser } = controller;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (authUser?.codConfartigianato && options.length === 0) {
      disponibilitaTipologiaAnnunci(authUser, setOptions);
    }
  }, [authUser]);

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" mt={0} spacing={2} p={2}>
          <Grid item xs={12} md={7} textAlign="left">
            <JobErSelect
              name="tipoRicerca"
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Modalità ricerca *"}
              onChange={(data) => {
                props.onSetDati("tipoRicerca", data);
              }}
              value={
                props.dati && props.dati.tipoRicerca && typeof props.dati.tipoRicerca === "object"
                  ? props.dati.tipoRicerca
                  : props.dati && props.dati.tipoRicerca && typeof props.dati.tipoRicerca === "string"
                  ? {
                      label: props.dati.tipoRicerca,
                      value: props.dati.tipoRicerca,
                    }
                  : null
              }
              options={options}
            />
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <Typography
              color={coloriVetrina.green}
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Ricerca attiva:
            </Typography>
            <Typography
              color={coloriVetrina.blue}
              sx={{
                fontSize: "14px",
                marginLeft: "5px",
              }}
            >
              Gestiremo noi l'annuncio. Faremo in modo di presentarti i migliori candidati. Il costo sarà per assunzione.
              <ul>
                <li> Sarà Lapam a gestire l'annuncio </li>
                <li> Le candidature saranno filtrate in base ai criteri inseriti nell'annuncio di lavoro </li>
                <li> Le informazioni anagrafiche dei candidati proposti non saranno visibili fino all'eventuale assunzione </li>
                <li> Il costo per la singola assunzione è pari al 10% della RAL del candidato assunto </li>
                <li> La gestione dell'iter della candidatura sarà gestito da Lapam </li>
                <li> Nessun costo nel caso in cui i candidati presentati non vengano assunti dall'azienda </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <Typography
              color={coloriVetrina.error}
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Ricerca Passiva:
            </Typography>
            <Typography
              color={coloriVetrina.blue}
              sx={{
                fontSize: "14px",
                marginLeft: "5px",
              }}
            >
              I candidati iscritti in piattaforma potranno candidarsi liberamente al seguente annuncio direttamente dalla loro area riservata.
              <ul>
                <li> Gestione autonoma dell'annuncio </li>
                <li> Possibilità di visualizzare tutte le informazioni della persona dopo che si è candidata all'annuncio </li>
                <li> Nessun costo nel caso in cui si decida di assumere un candidato sbloccato </li>
                <li> Possibilità di gestire l'iter di candidatura dentro o fuori la piattaforma </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep0;
