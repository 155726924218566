import React from "react";
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";
import coloriVetrina from "../json/coloriVetrina.json";

function JobErInput(props) {
  return (
    <FormControl variant="outlined" fullWidth size={props.size}>
      <InputLabel style={{ color: props.coloreTesto ? props.coloreTesto : coloriVetrina.gray, fontSize: "14px", zIndex: 0 }}>{props.label}</InputLabel>
      <OutlinedInput
        disabled={props.disabled}
        multiline={props.multiline || false}
        rows={props.rows || 1}
        type={props.type || "text"}
        label={props.label}
        onBlur={props.onBlur ? (e) => props.onBlur(e) : () => null}
        style={{
          borderRadius: 15,
        }}
        onChange={(e) => (props.onChange ? props.onChange(e) : () => null)}
        onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : () => null)}
        sx={{
          input: {
            color: props.coloreTesto ? props.coloreTesto : coloriVetrina.gray,
          },
          textarea: {
            color: props.coloreTesto ? props.coloreTesto : coloriVetrina.gray,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? coloriVetrina.error + "!important" : props.coloreBordo,
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? coloriVetrina.error + "!important" : props.coloreBordo,
          },
          "&:hover > .MuiOutlinedInput-notchedOutline": {
            borderColor: !props.disabled ? (props.error ? coloriVetrina.error + "!important" : "#1E88E5") : null,
            borderWidth: !props.disabled ? 2 : null,
          },
        }}
        value={props.value}
        endAdornment={props.endAdornment}
        startAdornment={props.startAdornment}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
      {props.helperText && props.helperText.trim() !== "" ? (
        <FormHelperText
          sx={{
            color: props.helperTextError ? "red" : coloriVetrina.white,
            fontSize: "12px!important",
          }}
        >
          {props.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
export default JobErInput;
