import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";
import { convetTimestampToDateIta } from "utils/utilsDati";
import coloriVetrina from "json/coloriVetrina";

export function getCandidatiConfartigianato(setAnnunci, filiale, setPending) {
  setPending(true);

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/confartigianato/getCandidati/" + filiale, {
    headers: headers,
    method: "GET",
  })
    .then((res) => res.json())
    .then((result) => {
      for (const element of result) {
        element.dataCandidaturaIta = convetTimestampToDateIta(element.dataCandidatura);

        if (element.nominativo) {
          element.nominativo = element.nominativo.toUpperCase();
        }

        switch (element.statoCandidatura) {
          case 1:
            element.statoCandidaturaLabel = "ASSUNTO";
            element.statoCandidaturaColor = coloriVetrina.green;
            break;
          case 3:
            element.statoCandidaturaLabel = "IN VALUTAZIONE";
            element.statoCandidaturaColor = coloriVetrina.warning;
            break;
          case 4:
            element.statoCandidaturaLabel = "RIFIUTATA";
            element.statoCandidaturaColor = coloriVetrina.error;
            break;
          default:
            element.statoCandidaturaLabel = "";
            element.statoCandidaturaColor = "";
            break;
        }
      }

      setAnnunci(result);
      setPending(false);
    })
    .catch((err) => {
      Toast.fire({
        icon: "error",
        title: "Si è verificato un errore nel caricamento degli annunci",
      });
      setPending(false);
    });
}
