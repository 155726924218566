import React from "react";
import Chip from "@mui/material/Chip";
import SchoolIcon from "@mui/icons-material/School";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsStudi(props) {
  return props.studi ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <SchoolIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
          </Avatar>
        }
        label={
          props.studi === "scuola_media"
            ? "Scuola Media"
            : props.studi === "scuola_superiore_professionale"
            ? "Scuola Superiore Professionale"
            : props.studi === "scuola_superiore_tecnico"
            ? "Scuola Superiore Tecnico"
            : props.studi === "scuola_superiore_liceo"
            ? "Scuola Superiore Liceo"
            : props.studi === "laurea_triennale"
            ? "Laurea Triennale"
            : props.studi === "laurea_magistrale"
            ? "Laurea Magistrale"
            : props.studi === "corso_ifts"
            ? "Corso IFTS"
            : props.studi === "corso_its"
            ? "Corso ITS"
            : "Non Specificato"
        }
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsStudi;
