import React from "react";
import { Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import JobButton from "components/JobButton";
import JobErCard from "components/JobErCard";
import { useNavigate } from "react-router-dom";
import coloriVetrina from "json/coloriVetrina.json";
import { uuidv4 } from "utils/utilsDati";

function HomeAziendeAnnunci(props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={4}>
      <JobErCard
        title="Ultimi Annunci attivi"
        child={
          <Grid container>
            {props.annunci?.dati && props.annunci.dati.length > 0 ? (
              <Grid item xs={12} mt={2}>
                <List
                  sx={{
                    width: "100%",
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  {props.annunci.dati.map((annuncio, index) => (
                    <React.Fragment key={"list_annuncio_" + index}>
                      <ListItem
                        alignItems="flex-start"
                        onClick={() => {
                          navigate("/Aziende/Annunci?uuid=" + annuncio.uuid);
                        }}
                        style={{ cursor: "pointer" }}
                        key={annuncio.uuid}
                      >
                        {annuncio.codificaMansione ? (
                          <ListItemAvatar key={annuncio.uuid || uuidv4() + "_candidatura"}>
                            <img
                              src={require("immagini/ambiti_lavoro/gr" + annuncio.codificaMansione.gruppo + "-260x145.jpg")}
                              style={{
                                height: "70px",
                                width: "110px",
                                borderRadius: 15,
                              }}
                              alt=""
                            />
                          </ListItemAvatar>
                        ) : null}

                        <ListItemText
                          key={annuncio.uuid + "_text"}
                          sx={{ marginLeft: 2 }}
                          primary={annuncio.titoloAnnuncio.toUpperCase()}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                            color: coloriVetrina.blue,
                          }}
                          secondary={
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  component="p"
                                  ml={1}
                                  sx={{
                                    fontSize: "14px!important",
                                    color: coloriVetrina.blue,
                                  }}
                                >
                                  {annuncio.descrizioneLavoro.length > 200
                                    ? annuncio.descrizioneLavoro.substring(0, 200).replace(/<[^>]*>?/gm, "") + " ..."
                                    : annuncio.descrizioneLavoro.replace(/<[^>]*>?/gm, "")}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                      </ListItem>
                      {index < props.annunci?.dati.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            ) : (
              <Grid item xs={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
                <Grid item xs={12} textAlign="center">
                  <Typography fontSize="14px" fontWeight="bold" sx={{ color: coloriVetrina.blue }}>
                    Nessun annuncio
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} mt={2} textAlign="center">
              <JobButton
                onClick={() => {
                  navigate("/Aziende/Annunci");
                }}
                label={"Gestisci annunci"}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

export default HomeAziendeAnnunci;
