import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function salvaSede(nuovaSede, authUser, sedi, setSedi, setShowForm, setLabelBottone, setNuovaSede, setTitoloForm) {
  if (nuovaSede.comune && nuovaSede.indirizzo) {
    var obj = nuovaSede;
    obj.userIns = authUser?.email ? authUser.email : null;
    obj.dataIns = new Date();
    obj.partitaIva = authUser?.partitaIva ? authUser.partitaIva : null;

    var headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/aziende/salvaSede", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.status === 200) {
          Toast.fire({ icon: "success", title: "Sede salvata con successo!" });
          return res.json();
        } else {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della sede!" });
        }
      })
      .then((jsonText) => {
        var sediCopia = [...sedi];
        sediCopia.push(jsonText);
        setSedi(sediCopia);

        setShowForm(false);
        setLabelBottone("Inserisci una sede");
        setNuovaSede({});
        setTitoloForm("Inserisci una nuova sede");
      })
      .catch((err) => {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della sede!" });
      });
  } else {
    Toast.fire({ icon: "error", title: "Inserisci tutti i campi obbligatori!" });
  }
}

export function disattivaSede(uuid, sedi, setSedi, authUser) {
  let obj = { uuid: uuid, userMod: authUser ? authUser.email : null, dataMod: new Date() };

  if (uuid) {
    var headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/aziende/disattivaSede", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.status === 200) {
          var copiaSedi = [...sedi];
          var filter = copiaSedi.filter((obj) => obj.uuid !== uuid);
          setSedi(filter);

          Toast.fire({ icon: "success", title: "Sede disattivata con successo!" });
        } else {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nella disattivazione della sede!" });
        }
      })
      .catch((err) => {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nella disattivazione della sede!" });
      });
  }
}

export function modificaSede(nuovaSede, sedi, setSedi, setShowForm, setLabelBottone, setNuovaSede, setTitoloForm) {
  if (nuovaSede.comune && nuovaSede.indirizzo) {
    var headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/aziende/modificaSede", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(nuovaSede),
    })
      .then((res) => {
        if (res.status === 200) {
          Toast.fire({ icon: "success", title: "Sede salvata con successo!" });

          return res.json();
        } else {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della sede!" });
        }
      })
      .then((jsonText) => {
        var filter = sedi.filter((obj) => obj.uuid !== nuovaSede.uuid);
        filter.push(jsonText);
        setSedi(filter);

        setShowForm(false);
        setLabelBottone("Inserisci una sede");
        setNuovaSede({});
        setTitoloForm("Inserisci una nuova sede");
      })
      .catch((err) => {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della sede!" });
      });
  } else {
    Toast.fire({ icon: "error", title: "Inserisci tutti i campi obbligatori!" });
  }
}
