import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import JobButton from "components/JobButton";
import PropTypes from "prop-types";
import { ModalContext } from "context/modalLogin";

function RegistrazioneCompletata(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    props.data !== "error" &&
    (props.tipoUtente === "persona" || props.tipoUtente === "azienda") && (
      <Grid container spacing={3} pl={20} pr={20} mt={1} justifyContent="center">
        <Grid item xs={12}>
          <TaskAltIcon sx={{ fontSize: 150, color: coloriVetrina.green }} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color={coloriVetrina.white}
            sx={{
              textAlign: "center!important",
              mt: "1vh",
              fontWeight: "bold",
            }}
          >
            Registrazione Completata!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color={coloriVetrina.white} sx={{ textAlign: "center!important", mt: "1vh" }}>
            Grazie <b>{props.tipoUtente === "azienda" ? props.data?.ragioneSociale : props.data?.nome}</b> per esserti iscritto al nostro portale.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color={coloriVetrina.white} sx={{ textAlign: "center!important", mt: "0vh" }}>
            Clicca sul link che ti abbiamo inoltrato per email per confermare il tuo indirizzo di posta elettronica e accedere subito a tutti i servizi dedicati
            a te
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            fontSize="18px"
            label={"Torna Alla Home"}
            onClick={() => {
              navigate("/Vetrina/Home");
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            fontSize="18px"
            label={"Accedi"}
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </Grid>
      </Grid>
    )
  );
}

RegistrazioneCompletata.propTypes = {
  data: PropTypes.object,
  tipoUtente: PropTypes.string,
};

export default RegistrazioneCompletata;
