import React, { useState } from "react";
import { Grid, Typography, Avatar, ListItemAvatar, Collapse, ListItemButton } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import ChipsComune from "./chips/ChipsComune";
import ChipsSettore from "./chips/ChipsSettore";
import ChipsRal from "./chips/ChipsRal";
import ChipsLingua from "./chips/ChipsLingua";
import ChipsProgrammi from "./chips/ChipsProgrammi";
import ChipsStudi from "./chips/ChipsStudi";
import ChipsBenefit from "./chips/ChipsBenefit";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function ViewAnnuncio(props) {
  const [viewDettaglio, setViewDettaglio] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        style={{ backgroundColor: props.annuncio?.uuid === props.searchParams ? coloriVetrina.searchSelected : "#fff" }}
        alignItems="flex-start"
        key={props.index}
        autoFocus={props.searchParams === props.annuncio?.uuid}
      >
        <Grid
          container
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={2}>
            {props.annuncio?.codificaMansione || props.annuncio?.posizioneLavorativa ? (
              <ListItemAvatar key={props.annuncio?.uuid + "_candidatura"}>
                <Avatar
                  src={require("../../immagini/ambiti_lavoro/gr" +
                    (props.annuncio?.codificaMansione ? props.annuncio?.codificaMansione.gruppo : props.annuncio?.posizioneLavorativa.gruppo) +
                    "-260x145.jpg")}
                  sx={{ width: 220, height: 100 }}
                  style={{
                    borderRadius: 15,
                  }}
                  alt=""
                />
              </ListItemAvatar>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="p"
              sx={{
                fontSize: "16px!important",
                color: coloriVetrina.blue,
                fontWeight: "bold!important",
              }}
            >
              {props.annuncio?.titoloAnnuncio} a {props.annuncio?.comune?.des}
            </Typography>
            <b style={{ color: coloriVetrina.blue }}>Data Pubblicazione:</b>{" "}
            {" " + props.annuncio?.dataPubblicazione && props.annuncio ? props.annuncio?.dataPubblicazione.split("-").reverse().join("/") : null}
          </Grid>
        </Grid>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container>
          <Grid item xs={12} md={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                  {props.annuncio?.descrizioneLavoro ? (
                    <div style={{ fontSize: "1.9vh !Important" }} dangerouslySetInnerHTML={{ __html: props.annuncio.descrizioneLavoro }} />
                  ) : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Collapse in={viewDettaglio} timeout="auto" unmountOnExit>
                  <Grid container mt={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        {props.annuncio?.comune ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsComune label="comune" comune={props.annuncio?.comune?.des} />
                          </Grid>
                        ) : null}
                        {props.annuncio?.codificaMansione ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsSettore settore={props.annuncio?.codificaMansione?.descrizione} />
                          </Grid>
                        ) : null}
                        {props.annuncio?.nettoMensile !== "-" || props.annuncio?.ral ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsRal netto={props.annuncio?.nettoMensile} ral={props.annuncio?.ral} />
                          </Grid>
                        ) : null}
                        {props.annuncio?.linguaPrincipale ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsLingua lingua={props.annuncio?.linguaPrincipale} />
                          </Grid>
                        ) : null}
                        {props.annuncio?.linguaSecondaria ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsLingua
                              lingua={
                                props.annuncio?.livelloLinguaSecondaria
                                  ? props.annuncio?.linguaSecondaria + " ( " + props.annuncio?.livelloLinguaSecondaria + " ) "
                                  : props.annuncio?.linguaSecondaria
                              }
                            />
                          </Grid>
                        ) : null}
                        {props.annuncio?.programmiRichiesti
                          ? props.annuncio?.programmiRichiesti.split(",").map((programma, index) => (
                              <Grid item xs="auto" mr={1} mb={1} key={"programmi_" + index}>
                                <ChipsProgrammi programmi={programma} />
                              </Grid>
                            ))
                          : null}
                        {props.annuncio?.titoloStudio ? (
                          <Grid item xs="auto" mr={1} mb={1}>
                            <ChipsStudi studi={props.annuncio?.titoloStudio} />
                          </Grid>
                        ) : null}
                        {props.annuncio?.retribuzioneSupplementare
                          ? props.annuncio?.retribuzioneSupplementare.split(",").map((benefit, index) => (
                              <Grid item xs="auto" mr={1} mb={1} key={"benefict_" + index}>
                                <ChipsBenefit benefit={benefit} />
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Grid container spacing={2} justifyContent="left">
                  <Grid item xs={12} md="auto">
                    <JobButton
                      label={"Invia Candidatura"}
                      colorBorder={coloriVetrina.green}
                      colorLabel={coloriVetrina.green}
                      onClick={() =>
                        Swal.fire({
                          text: "Sei sicuro di voler inviare questa candidatura?",
                          icon: "warning",
                          showCancelButton: true,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.onSendCandidatura(props.annuncio?.uuid);
                          } else if (result.isDenied) {
                          }
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
export default ViewAnnuncio;
