import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export async function getHomeAnnunciAzienda(partitaIva) {
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_URL +
        "/aziende/getHomeAnnunciAzienda?partitaIva=" +
        partitaIva,
      {
        headers: getAuthHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
}

export async function getAnnunciAzienda(partitaIva) {
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_URL +
        "/aziende/getAnnunciAzienda?partitaIva=" +
        partitaIva,
      {
        headers: getAuthHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
}

export function disattivaAnnuncio (uuid, annunci, setAnnunci) {
 
  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/aziende/disattivaAnnuncioAzienda/" + uuid,
    {
      headers: headers,
      method: "POST",
    }
  ).then((res) => {
    
    if (res.status === 200) {

      const { dati } = annunci; // Destructure 'dati' from 'annunci'
      const filteredDati = dati.filter(obj => obj.uuid !== uuid); // Use strict comparison

      setAnnunci({ dati: filteredDati, loading: false }); // Update state

      Toast.fire({ icon: "success", title: "Annuncio disabilitato" });
    }
    else {
      Toast.fire({ icon: "error", title: "Si è verificato un errore" });
    }
  })
    .catch((err) =>
      {
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
      }
    );
}

export async function getHomePresentazioneAzienda(partitaIva) {
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_URL +
        "/aziende/getHomePresentazioneAzienda?partitaIva=" +
        partitaIva,
      {
        headers: getAuthHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
          Toast.fire({ icon: "error", title: "Si è verificato un errore" });
        }
      );
  });
}

export async function getHomeCandidatureAzienda(partitaIva) {
  return new Promise((resolve, reject) => {

    fetch(process.env.REACT_APP_URL + "/aziende/getHomeCandidatureAzienda?partitaIva=" + partitaIva,
      {
        method: "GET",
        headers: getAuthHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result); 
        },
        (error) => {
          reject(error);
          Toast.fire({ icon: "error", title: "Si è verificato un errore" });
        }
      );
  });
}

export async function getSediAzienda(partitaIva) {
  return new Promise((resolve, reject) => {

    if(partitaIva)
    {
      fetch(
        process.env.REACT_APP_URL +
          "/aziende/getSediAziendaPiva?partitaIva=" +
          partitaIva,
        {
          headers: getAuthHeader(),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
            Toast.fire({ icon: "error", title: "Si è verificato un errore" });
          }
        );
    }
    else
    {
      fetch(
        process.env.REACT_APP_URL +
          "/aziende/getSediAziendaMetopack?metopack=" +
          localStorage.getItem("metopack"),
        {
          headers: getAuthHeader(),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
            Toast.fire({ icon: "error", title: "Si è verificato un errore" });
          }
        );
    }

    
  });
}
