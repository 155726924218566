import React from "react";
import { Grid } from "@mui/material";
import PngCandidato from "../../../immagini/vetrina_candidati.png";
import SlideCandidatoJobER from "./SlideCandidatoJobER";
import SlideCandidatoJobMORE from "./SlideCandidatoJobMORE";

function PresentazioneCandidato(props) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "flex" } }}>
        <img src={PngCandidato} style={{ height: "60vh" }} />
      </Grid>
      <Grid item xs={12} md={6}>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <SlideCandidatoJobMORE />
        ) : (
          <SlideCandidatoJobER />
        )}
      </Grid>
    </Grid>
  );
}
export default PresentazioneCandidato;
