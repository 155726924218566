import React from "react";
import { Avatar, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina";
import FactoryIcon from "@mui/icons-material/Factory";
import WorkIcon from "@mui/icons-material/Work";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import SchoolIcon from "@mui/icons-material/School";
import PublicIcon from "@mui/icons-material/Public";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AnnuncioStep1 from "./AnnuncioStep1";
import AnnuncioStep2 from "./AnnuncioStep2";
import AnnuncioStep3 from "./AnnuncioStep3";
import AnnuncioStep6 from "./AnnuncioStep6";
import AnnuncioStep5 from "./AnnuncioStep5";
import AnnuncioStep4 from "./AnnuncioStep4";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";

function StepperAnnuncioMobile(props) {
  return (
    <Stepper activeStep={props.activeStep === 0 ? 0 : props.activeStep - 1} orientation="vertical">
      <Step completed={false} index={0}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepWithError.includes(1)
                  ? coloriVetrina.warning
                  : props.stepCompleted.includes(1)
                  ? coloriVetrina.green
                  : coloriVetrina.blue,
              }}
            >
              {props.stepWithError.includes(1) ? (
                <WarningIcon sx={{ fontSize: "18px" }} />
              ) : props.stepCompleted.includes(1) ? (
                <CheckIcon sx={{ fontSize: "18px" }} />
              ) : (
                <FactoryIcon sx={{ fontSize: "18px" }} />
              )}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(1)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Titolo
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep1 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
      <Step completed={false} index={1}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepWithError.includes(2)
                  ? coloriVetrina.warning
                  : props.stepCompleted.includes(2)
                  ? coloriVetrina.green
                  : coloriVetrina.blue,
              }}
            >
              {props.stepWithError.includes(2) ? (
                <WarningIcon sx={{ fontSize: "18px" }} />
              ) : props.stepCompleted.includes(2) ? (
                <CheckIcon sx={{ fontSize: "18px" }} />
              ) : (
                <WorkIcon sx={{ fontSize: "18px" }} />
              )}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(2)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Figura Professionale
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep2 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
      <Step completed={false} index={2}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepCompleted.includes(3) ? coloriVetrina.green : coloriVetrina.blue,
              }}
            >
              {props.stepCompleted.includes(3) ? <CheckIcon sx={{ fontSize: "18px" }} /> : <FlightTakeoffIcon sx={{ fontSize: "18px" }} />}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(3)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Trasferte
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep3 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
      <Step completed={false} index={3}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepCompleted.includes(4) ? coloriVetrina.green : coloriVetrina.blue,
              }}
            >
              {props.stepCompleted.includes(4) ? <CheckIcon sx={{ fontSize: "18px" }} /> : <SchoolIcon sx={{ fontSize: "18px" }} />}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(4)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Titoli studio
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep4 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
      <Step completed={false} index={4}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepCompleted.includes(5) ? coloriVetrina.green : coloriVetrina.blue,
              }}
            >
              {props.stepCompleted.includes(5) ? <CheckIcon sx={{ fontSize: "18px" }} /> : <PublicIcon sx={{ fontSize: "18px" }} />}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(5)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Lingue
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep5 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
      <Step completed={false} index={5}>
        <StepLabel
          icon={
            <Avatar
              variant="outlined"
              sx={{
                width: "30px",
                height: "30px",
                backgroundColor: props.stepCompleted.includes(6) ? coloriVetrina.green : coloriVetrina.blue,
              }}
            >
              {props.stepCompleted.includes(6) ? <CheckIcon sx={{ fontSize: "18px" }} /> : <AccessibilityNewIcon sx={{ fontSize: "18px" }} />}
            </Avatar>
          }
          onClick={() => props.onSetActiveStep(6)}
        >
          <Typography
            component="p"
            ml={1}
            sx={{
              fontSize: "14px!important",
              color: coloriVetrina.blue,
              textTransform: "capitalize",
            }}
          >
            Competenze
          </Typography>
        </StepLabel>
        <StepContent>
          <AnnuncioStep6 dati={props.datiAnnuncio} onSetDati={(key, val) => props.onSetDatiAnnuncio(key, val)} />
        </StepContent>
      </Step>
    </Stepper>
  );
}
export default StepperAnnuncioMobile;
