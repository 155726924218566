import { Toast } from "components/Toast"
import { getAuthHeader } from "assets/utils/auth-services";

export function savePresentazione(presentazione, setPresentazione, authUser) {
    if (presentazione) {
        let headers = getAuthHeader();
        headers["Content-Type"] = "application/json";

        presentazione.partitaIva = localStorage.getItem("partitaIva");
        presentazione.ragioneSociale = authUser?.ragioneSociale;

        fetch(process.env.REACT_APP_URL + "/aziende/salvaPresentazione",
            {
                headers: headers,
                method: "POST",
                body: JSON.stringify(presentazione),
            }
        ).then(response => {
            if (!response.ok) {
                Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della presentazione!" });
                return null
            }
            return response.json(); // Parse the JSON from the response
        })
            .then(data => {
                setPresentazione(data)

                Toast.fire({ icon: "success", title: "Presentazione salvata con successo!" });
            })
            .catch(error => {
                Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della presentazione!" });
            });
    }
}