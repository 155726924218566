import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import { useNavigate } from "react-router-dom";
import { useJobErController } from "context";
import JobButton from "components/JobButton";

function MenuAvatarPortableDevice(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [controller] = useJobErController();
  const { authUser } = controller;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Avatar
        alt="Remy Sharp"
        sx={{ width: 55, height: 55 }}
        /*src={imageProfilo}*/
        onClick={handleOpenNavMenu}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "70%",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container justifyContent="center">
            <Grid item xs="auto">
              <Avatar
                alt="Remy Sharp"
                sx={{ width: 55, height: 55 }}
                /*src={imageProfilo}*/
                /* standard={true} */
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="h3"
                color={coloriVetrina.blue}
                sx={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  textTransform: "uppercase",
                }}
              >
                {authUser?.nome ? authUser.nome : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="h3"
                color={coloriVetrina.blue}
                sx={{
                  fontWeight: "regular",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                {authUser?.cognome ? authUser.cognome : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" mt={1}>
              <JobButton
                label="Logout"
                colorBorder={coloriVetrina.error}
                colorLabel={coloriVetrina.error}
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  navigate("/Vetrina/Home");
                }}
              ></JobButton>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuAvatarPortableDevice;
