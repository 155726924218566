import React, { useState } from "react";
import { useJobErController, setAuthUser } from "context";
import JobErCard from "components/JobErCard";
import { Grid } from "@mui/material";
import coloriVetrina from "json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import JobButton from "components/JobButton";
import { checkEmail, checkPassword, loginConfartigianato } from "components/PortaleConfartigianato/Login/utils/utils_login_confartigianato"
import { Toast } from "components/Toast"
import { useNavigate } from "react-router-dom";

function LoginConfartigianato(props) {

  const navigate = useNavigate();
  const [controller, dispatch] = useJobErController();

  const [utente, setUtente] = useState({ email: "", password: "" })
  const [errori, setErrori] = useState({ email: "", password: "" })
  const [loading, setLoading] = useState(false)

  const login = () => {
    if (errori?.email === "ok" && errori?.password === "ok" && utente?.email && utente?.password) {
      loginConfartigianato(utente)
        .then((user) => {
          setLoading(false);
          setAuthUser(dispatch, user);
          navigate("/Confartigianato/Home");
        })
        .catch(function (e) {

          setLoading(false);
          Toast.fire({ icon: "error", title: "Utente non riconosciuto!" });
        });
    }
  }

  return (
    <Grid
      sx={{ backgroundColor: coloriVetrina.blue, height: '100vh' }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <JobErCard
          child={
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                  <img
                    alt="Logo My Lapam Job"
                    src={require("../../../immagini/MyLapamJOB_Logo.png")}
                    style={{
                      height: "70px",
                      width: "280px",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
                )}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <JobErInput
                  text="text"
                  label="Email"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={errori?.email === "ok" || errori?.email === "" ? coloriVetrina.gray : coloriVetrina.error}
                  size="small"
                  value={utente.email ? utente.email : ""}
                  onChange={(e) => setUtente({ ...utente, email: e.target.value })}
                  onBlur={(e) => checkEmail(e.target.value, setErrori, errori)}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <JobErInput
                  type="password"
                  label="Password"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={errori?.password === "ok" || errori?.password === "" ? coloriVetrina.gray : coloriVetrina.error}
                  size="small"
                  value={utente.password ? utente.password : ""}
                  onChange={(e) => setUtente({ ...utente, password: e.target.value })}
                  onBlur={(e) => checkPassword(e.target.value, setErrori, errori)}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <JobButton
                  onClick={() => {
                    login()
                  }}
                  loading={loading}
                  label="Accedi"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}
export default LoginConfartigianato;
