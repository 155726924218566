import React, { useState } from "react";
import { Grid, Typography, Collapse, ListItemAvatar, ListItemButton, Avatar } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import ChipsComune from "./chips/ChipsComune";
import ChipsSettore from "./chips/ChipsSettore";
import ChipsRal from "./chips/ChipsRal";
import ChipsLingua from "./chips/ChipsLingua";
import ChipsProgrammi from "./chips/ChipsProgrammi";
import ChipsStudi from "./chips/ChipsStudi";
import ChipsBenefit from "./chips/ChipsBenefit";
import JobButton from "components/JobButton";
import { eliminaCandidaturaCandidatura } from "components/PortalePersone/utils/utils_candidatura";
import Swal from "sweetalert2";

function ViewCandidatura(props) {
  const [viewDettaglio, setViewDettaglio] = useState(true);

  let dataIns = null;

  if (props.candidatura?.dataInserimento) {
    let split = props.candidatura.dataInserimento.split("T");

    // Split the date part into year, month, and day
    let datePart = split[0].split("-");

    // Reverse the order and join them with "/"
    dataIns = [datePart[2], datePart[1], datePart[0]].join("/");
  }

  const rags = props.candidatura?.ragsPiva ? props.candidatura.ragsPiva : props.candidatura.ragsMetopack;

  return (
    <ListItemButton
      style={{ backgroundColor: props.candidatura.uuidCandidatura === props.searchParams ? coloriVetrina.searchSelected : "inherit" }}
      alignItems="flex-start"
      key={props.index}
      autoFocus={props.searchParams === props.candidatura.uuidCandidatura}
    >
      <Grid container>
        <Grid item xs={12} md={2} textAlign="center">
          {props.candidatura?.posizioneLavorativa ? (
            <ListItemAvatar key={props.candidatura?.uuid + "_candidatura"}>
              <Avatar
                src={require("../../immagini/ambiti_lavoro/gr" + (props.candidatura?.gruppoPosLav && props.candidatura?.gruppoPosLav) + "-260x145.jpg")}
                sx={{ width: 220, height: 100 }}
                style={{
                  borderRadius: 15,
                }}
                alt=""
              />
            </ListItemAvatar>
          ) : (
            <ListItemAvatar key={props.candidatura?.uuid + "_candidatura"}>
              <Avatar
                src={props.candidatura?.lavoroPresentazioneAzienda && props.candidatura?.lavoroPresentazioneAzienda?.copertina}
                sx={{ width: 220, height: 100 }}
                style={{
                  borderRadius: 15,
                }}
                alt=""
              />
            </ListItemAvatar>
          )}
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Typography
                component="p"
                sx={{
                  fontSize: "16px!important",
                  color: coloriVetrina.blue,
                  fontWeight: "bold!important",
                }}
              >
                {props.candidatura?.titoloAnnuncio ? props.candidatura?.titoloAnnuncio : "CANDIDATURA SPONTANEA - " + rags}
              </Typography>
            </Grid>
            {props.candidatura?.dataPubblicazione && (
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                  <b style={{ color: coloriVetrina.blue }}>Data Pubblicazione:</b>{" "}
                  {" " + props.candidatura?.dataPubblicazione ? props.candidatura?.dataPubblicazione?.split("-").reverse().join("/") : ""}
                </Typography>
              </Grid>
            )}
            {dataIns && (
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                  <b style={{ color: coloriVetrina.blue }}>Data Pubblicazione:</b> {" " + dataIns ? dataIns : ""}
                </Typography>
              </Grid>
            )}
            {props.candidatura?.descrizioneLavoro ? (
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                  {props.candidatura?.descrizioneLavoro ? (
                    <div style={{ fontSize: "1.9vh !Important" }} dangerouslySetInnerHTML={{ __html: props.candidatura.descrizioneLavoro }} />
                  ) : null}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.secondary">
                  {props.candidatura?.anagraficaAzienda ? props.candidatura?.anagraficaAzienda.ragioneSociale : null}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Collapse in={viewDettaglio} timeout="auto" unmountOnExit>
                <Grid container mt={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {props.candidatura?.anagraficaAzienda?.com ? (
                        <Grid item xs="auto">
                          <ChipsComune label="comune" comune={props.candidatura?.anagraficaAzienda?.com.des} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.descrizioneComune ? (
                        <Grid item xs="auto">
                          <ChipsComune label="comune" comune={props.candidatura?.descrizioneComune} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.descrizionePosLav ? (
                        <Grid item xs="auto" textAlign="center">
                          <ChipsSettore settore={props.candidatura?.descrizionePosLav} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.nettoMensile !== "-" || props.candidatura?.ral ? (
                        <Grid item xs="auto">
                          <ChipsRal netto={props.candidatura?.nettoMensile} ral={props.candidatura?.ral} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.linguaPrincipale ? (
                        <Grid item xs="auto">
                          <ChipsLingua lingua={props.candidatura?.linguaPrincipale} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.linguaSecondaria ? (
                        <Grid item xs="auto">
                          <ChipsLingua
                            lingua={
                              props.candidatura?.livelloLinguaSecondaria
                                ? props.candidatura?.linguaSecondaria + " ( " + props.candidatura?.livelloLinguaSecondaria + " ) "
                                : props.candidatura?.linguaSecondaria
                            }
                          />
                        </Grid>
                      ) : null}
                      {props.candidatura?.programmiRichiesti
                        ? props.candidatura?.programmiRichiesti.split("-").map((programma) => (
                            <Grid item xs="auto">
                              <ChipsProgrammi programmi={programma} />
                            </Grid>
                          ))
                        : null}
                      {props.candidatura?.titoloStudio ? (
                        <Grid item xs="auto">
                          <ChipsStudi studi={props.candidatura?.titoloStudio} />
                        </Grid>
                      ) : null}
                      {props.candidatura?.retribuzioneSupplementare
                        ? props.candidatura?.retribuzioneSupplementare.split(",").map((benefit) => (
                            <Grid item xs="auto">
                              <ChipsBenefit benefit={benefit} />
                            </Grid>
                          ))
                        : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Grid container spacing={2} justifyContent="left">
                      {props.candidatura?.stato !== 0 && (
                        <Grid item xs={12} md="auto">
                          <JobButton
                            label={"Rimuovi Candidatura"}
                            colorBorder={coloriVetrina.error}
                            colorLabel={coloriVetrina.error}
                            onClick={() => {
                              Swal.fire({
                                text: "Sei sicuro di voler rimuovere questa candidatura?",
                                icon: "warning",
                                showCancelButton: true,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  eliminaCandidaturaCandidatura(props.setCandidature, props.candidature, props.candidatura);
                                } else if (result.isDenied) {
                                }
                              });
                            }}
                            /* disabled={
                        props.annuncio.candidature[0].stato === 3 ? false : true
                      } */
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItemButton>
  );
}
export default ViewCandidatura;
