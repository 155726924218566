import React, { useEffect, useState } from "react";
import { Grid, List, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import { ReactComponent as EmiliaRomagna } from "../../svgicon/er_italy.svg";
import CircleIcon from "@mui/icons-material/Circle";
import ItemListaAnnunciEr from "components/Vetrina/PerLePersone/ItemListaAnnunciEr";
import JobButton from "components/JobButton";
import { onClickMappaEr, onClickAnnunciEr } from "pages/vetrina/utils/UtilsVetrina";
import { ProvinceER } from "utils/ProvinceER";
import JobErSelect from "components/JobErSelect";

function ItemMappaJobER(props) {
  const [selectRegione, setSelectRegione] = useState({});
  const [filtroAnnunci, setFiltroAnnunci] = useState([]);
  const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
  const [counterProvince, setCounterProvince] = useState([]);
  const [listaProfessioni, setListaProfessioni] = useState([]);
  const [selectedProfessione, setSelectedProfessione] = useState(null);

  const resetFilter = () => {
    if (provinciaSelezionata) {
      const previousElement = document.querySelector(`.selectedprov_${provinciaSelezionata.idmap}`);
      if (previousElement) {
        previousElement.classList.remove(`selectedprov_${provinciaSelezionata.idmap}`);
      }
    }

    setProvinciaSelezionata(null);
    setSelectRegione({});
    setSelectedProfessione(null);
    setListaProfessioni([]);
  };

  useEffect(() => {
    if (props.annunci.length > 0) {
      ProvinceER.forEach((province) => {
        let provinciaCode = province.cod;
        let annunci = props.annunci.filter((item) => item.comune.prov === provinciaCode);

        province.annunci = annunci;
        province.counter = annunci.length;
      });
      setCounterProvince(ProvinceER);
    }
  }, [props.annunci]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      backgroundColor={coloriVetrina.blue}
      sx={{
        display: { md: "flex" },
        paddingLeft: { xs: 2, md: 15 },
        paddingRight: { xs: 2, md: 15 },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingLeft: { xs: 10, md: 40 },
          paddingRight: { xs: 10, md: 40 },
        }}
      >
        <EmiliaRomagna
          className="er_svgmap"
          onClick={(e) => {
            onClickMappaEr(e, setSelectRegione, props.annunci, setFiltroAnnunci, provinciaSelezionata, setProvinciaSelezionata);
          }}
          style={{
            width: "100%",
            height: { xs: "fit-content!important", md: "auto" },
          }}
        ></EmiliaRomagna>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            color={coloriVetrina.white}
            sx={{
              textAlign: "left!important",
              mt: { md: "10vh", xs: "0px" },
              fontWeight: "bold",
            }}
          >
            Cerchiamo nuovi talenti in Emilia Romagna!
          </Typography>
        </Grid>
        <Grid container justifyContent="center" spacing={2} mt={2}>
          {Object.keys(selectRegione).length === 0 ? (
            counterProvince.map((obj, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4} textAlign="center" className="elenco_prov">
                  <JobButton
                    label={
                      <>
                        <CircleIcon
                          fontSize="10px"
                          style={{
                            color: obj.colore,
                          }}
                        />
                        {" Annunci di " + obj.descrizione + ": " + obj.counter}
                      </>
                    }
                    colorBackground={coloriVetrina.blue}
                    colorBorder={obj.colore}
                    colorLabel={coloriVetrina.white}
                    onClick={() => {
                      onClickAnnunciEr(obj, setSelectRegione, props.annunci, setFiltroAnnunci, provinciaSelezionata, setListaProfessioni);
                    }}
                  />
                </Grid>
              );
            })
          ) : (
            <>
              <Grid item xs={12}>
                <JobButton
                  label="Rimuovi Filtri"
                  colorBackground={coloriVetrina.blue}
                  colorBorder={coloriVetrina.error}
                  colorLabel={coloriVetrina.white}
                  onClick={resetFilter}
                />
              </Grid>
              <Grid item xs={6}>
                <JobErSelect
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder={"Filtra per professione"}
                  value={selectedProfessione}
                  sx={{
                    color: coloriVetrina.white,
                  }}
                  onChange={(data) => {
                    if (data) {
                      let annunci = props.annunci.filter((obj2) => obj2.comune.prov === selectRegione.value);
                      let filtro = annunci.filter((obj2) => obj2.posizioneLavorativa.descrizione === data.value);
                      setFiltroAnnunci(filtro);
                    } else {
                      let filtro = props.annunci.filter((obj2) => obj2.comune.prov === selectRegione.value);
                      setFiltroAnnunci(filtro);
                    }

                    setSelectedProfessione(data);
                  }}
                  options={listaProfessioni}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color={coloriVetrina.white}
                  sx={{
                    mt: "2vh",
                    fontWeight: "bold",
                  }}
                >
                  {filtroAnnunci.length > 0 ? (
                    <>{"Zona di " + selectRegione?.label + " e provincia "}</>
                  ) : (
                    "Nessun annuncio trovato nella zona di " + selectRegione?.label + " e provincia"
                  )}
                </Typography>

                {filtroAnnunci.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderLeft: {
                        xs: "none",
                        md: "1px solid lightgray",
                      },
                    }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "500px",
                        overflowY: "auto",
                      }}
                    >
                      {filtroAnnunci.map((annuncio, index) => (
                        <React.Fragment key={index}>
                          <ItemListaAnnunciEr annuncio={annuncio} />
                        </React.Fragment>
                      ))}
                    </List>
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ItemMappaJobER;
