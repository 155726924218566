import React from "react";
import { Grid } from "@mui/material";
import LayoutConfartigianato from "components/PortaleConfartigianato/LayoutConfartigianato";
import JobErCard from "components/JobErCard";
import JobTypography from "components/JobTypography";

function HomeConfartigianato(props) {
  return (
    <LayoutConfartigianato
      child={
        <Grid container>
          <Grid item xs={12}>
            <JobErCard
              title="Home"
              child={
                <Grid container>
                  <Grid item xs={6} pt={2}>
                    <JobTypography tipo="titoloLista" testo="Totale aziende attive: " />
                  </Grid>
                  <Grid item xs={6} pt={2}>
                    <JobTypography tipo="titoloLista" testo="Totale candidature:" />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
}
export default HomeConfartigianato;
