import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobButton from "components/JobButton";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "context/modalLogin";

function MainSlideJobMORE(props) {
  const navigate = useNavigate();
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  return (
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
            Stai cercando i migliori talenti per la tua azienda?
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh" }}>
            Assumi con successo con MyLapam Job!
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Strumenti avanzati:</b>
            <br></br>
            Pubblica annunci di lavoro con un'interfaccia semplice e intuitiva.
            <br></br>
            Gestisci le candidature e i colloqui in modo efficiente.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Vasto database di candidati</b>
            <br></br>
            Accedi a un bacino di talenti qualificati e in continua crescita.
            <br></br>Trova i candidati perfetti per le tue posizioni aperte, in qualsiasi settore.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={1} spacing={2}>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            label="Accedi"
            onClick={() => {
              setIsModalOpen(true);
            }}
            fontSize="18px"
          />
        </Grid>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.white}
            colorLabel={coloriVetrina.blue}
            colorBorder={coloriVetrina.white}
            size="large"
            label="Registrati"
            onClick={() => navigate("/Vetrina/Registrati")}
            fontSize="18px"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default MainSlideJobMORE;
