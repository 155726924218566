import React from "react";
import Chip from "@mui/material/Chip";
import ComputerIcon from "@mui/icons-material/Computer";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsProgrammi(props) {
  
  return props.programmi ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <ComputerIcon
              sx={{ fontSize: "18px", color: coloriVetrina.white }}
            />
          </Avatar>
        }
        label={props.programmi}
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsProgrammi;
