import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import coloriVetrina from "../json/coloriVetrina.json";

function JobErCard(props) {
  return (
    <Card style={{ borderRadius: 15, ...props.style }}>
      <CardContent>
        {props.title ? (
          <Grid container>
            <Grid item xs={12} textAlign="left">
              <Typography
                color={coloriVetrina.blue}
                sx={{
                  textAlign: "left",
                  mt: "1vh",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {props.child}
      </CardContent>
      {props.footer ? (
        <Grid container justifyContent="flex-end" p={2}>
          <Grid item xs={12} textAlign="right">
            {props.footer}
          </Grid>
        </Grid>
      ) : null}
    </Card>
  );
}
export default JobErCard;
