import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import { uuidv4 } from "utils/utilsDati";
import RichiestaInformazioniAzie from "../PerLeAziende/RichiestaInformazioni";
import RichiestaInformazioniPers from "../PerLePersone/RichiestaInformazioni";
import JobErSelect from "components/JobErSelect";
import options from "json/option_configuration.json";

function Contattaci(props) {
  const [naturaRichiedente, setNaturaRichiedente] = useState(null);
  const [dati, setDati] = useState({});

  useEffect(() => {
    setDati({
      uuid: uuidv4(),
      richiedente: naturaRichiedente ? naturaRichiedente.value : null,
    });
  }, [naturaRichiedente]);

  return (
    <Grid
      container
      sx={{
        pb: { xs: 4, md: 8 },
        pt: { xs: 4, md: 8 },
        pl: { xs: 2, md: 15 },
        pr: { xs: 2, md: 15 },
      }}
      backgroundColor={coloriVetrina.blue}
      justifyContent="center"
    >
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Grid container textAlign="start">
              <Grid item xs={12}>
                <Typography variant="h3" fontWeight="bold" style={{ color: coloriVetrina.white }}>
                  Hai bisogno di aiuto?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ color: coloriVetrina.white }}>
                  Il nostro team di supporto è a tua disposizione per qualsiasi domanda o problema.
                </Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography variant="h6" style={{ color: coloriVetrina.white }}>
                  <b>Compila il modulo di contatto:</b>
                  <br></br> Rispondi a poche semplici domande e un membro del nostro team ti risponderà al più presto.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} pt={2}>
                <Typography variant="h6" fontWeight="bold" style={{ color: coloriVetrina.white }}>
                  Natura del richiedente
                </Typography>
              </Grid>
              <Grid item xs={10} md={4} pt={2}>
                <JobErSelect
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder="Seleziona"
                  value={naturaRichiedente}
                  onChange={(data) => setNaturaRichiedente(data)}
                  options={options.candidatoAzienda}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container justifyContent="center">
              {naturaRichiedente && naturaRichiedente.value === "azienda" ? (
                <RichiestaInformazioniAzie />
              ) : naturaRichiedente && naturaRichiedente.value === "persona" ? (
                <RichiestaInformazioniPers />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Contattaci;
