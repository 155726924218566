import React, { useState } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import {
  completaRegistrazionePersona,
  verificaCampiRegistrazione,
  verificaEmailUtenteRegistrazione,
  verificaCodiceFiscaleRegistrazione,
  verificaPasswordRegistrazione,
} from "utils/utilsLogin";
import JobButton from "components/JobButton";
import JobErSelect from "components/JobErSelect";
import options from "json/option_configuration.json";

function RegistrazionePersona(props) {
  const [formData, setFormData] = useState({});
  const [dataError, setDataError] = useState([]);
  const [verificaMail, setVerificaMail] = useState(null);
  const [verificaCodiceFiscale, setVerificaCodiceFiscale] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificaPassword, setVerificaPassword] = useState(null);

  return (
    <>
      <Grid container justifyContent="center" mt={2}>
        <Grid item xs={6}>
          <Divider sx={{ borderColor: "#19405A" }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} mt={3}>
          <Typography variant="h7" color={coloriVetrina.white} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati utente
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Email"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("email") || (verificaMail !== "ok" && verificaMail) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText={verificaMail === "forbidden" ? "Email già utilizzata" : verificaMail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            onBlur={(e) => verificaEmailUtenteRegistrazione(e.target.value, setVerificaMail)}
            helperTextError={verificaMail !== "ok" && verificaMail}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Password"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("password") || (verificaPassword !== "ok" && verificaPassword) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText={verificaPassword !== "ok" && verificaPassword ? verificaPassword : "Inserisci la password"}
            value={formData.password || ""}
            type="password"
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            onBlur={(e) => verificaPasswordRegistrazione(e.target.value, setVerificaPassword)}
            helperTextError={verificaPassword !== "ok" && verificaPassword}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Ripeti Password"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("ripetiPassword") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci nuovamente la password"
            value={formData.ripetiPassword || ""}
            type="password"
            onChange={(e) => setFormData({ ...formData, ripetiPassword: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} mt={3}>
          <Typography variant="h7" color={coloriVetrina.white} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati Anagrafici
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Nome"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("nome") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo nome"
            value={formData.nome || ""}
            onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Cognome"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("cognome") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo cognome"
            value={formData.cognome || ""}
            onChange={(e) => setFormData({ ...formData, cognome: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Codice Fiscale"
            coloreTesto={coloriVetrina.white}
            coloreBordo={
              dataError.includes("codiceFiscale") || (verificaCodiceFiscale !== "ok" && verificaCodiceFiscale) ? coloriVetrina.error : coloriVetrina.gray
            }
            size="small"
            helperText={verificaCodiceFiscale === "invalid" ? "Codice fiscale non valido" : "Inserisci il tuo codice fiscale"}
            value={formData.codiceFiscale || ""}
            onChange={(e) => setFormData({ ...formData, codiceFiscale: e.target.value })}
            onBlur={(e) => verificaCodiceFiscaleRegistrazione(e.target.value, setVerificaCodiceFiscale)}
            helperTextError={verificaCodiceFiscale !== "ok" && verificaCodiceFiscale}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={4}>
          <JobErAsyncSelect
            menuPosition="fixed"
            isClearable="true"
            placeholder={"Ricerca Comune"}
            value={formData.comune}
            error={dataError.includes("comune")}
            loadOptions={asyncRicercaComuni}
            noOptionsMessage={() => "Nessun comune trovato"}
            onChange={(data) => {
              setFormData({ ...formData, comune: data });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Indirizzo"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("indirizzo") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo indirizzo"
            value={formData.indirizzo || ""}
            onChange={(e) => setFormData({ ...formData, indirizzo: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <JobErInput
            label="Civico"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("civico") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo N. civico"
            value={formData.civico || ""}
            onChange={(e) => setFormData({ ...formData, civico: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <JobErInput
            label="CAP"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("cap") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo cap"
            value={formData.cap || ""}
            onChange={(e) => setFormData({ ...formData, cap: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={4}>
          <JobErSelect
            menuPosition="fixed"
            isClearable="true"
            placeholder={"Fai parte di una categoria protetta?"}
            value={formData?.categoriaSpeciale ? formData.categoriaSpeciale : null}
            onChange={(data) => setFormData({ ...formData, categoriaSpeciale: data })}
            options={options.yes_no}
            error={dataError.includes("categoriaSpeciale")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12}>
          <JobButton
            loading={loading}
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            fontSize="16px"
            label={"Completa Registrazione"}
            onClick={() => {
              if (verificaCampiRegistrazione("persona", formData, setDataError)) {
                if (verificaMail === "ok" && verificaCodiceFiscale === "ok") {
                  completaRegistrazionePersona(formData, props.onRegistrazioneCompletata, setLoading);
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default RegistrazionePersona;
