import React from "react";
import Chip from "@mui/material/Chip";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsFiltriEsperienza(props) {
  /* const handleDelete = () => {
    const updatedFilter = { ...props.filtri };

    // Delete the key 'a' if it exists
    if (updatedFilter.esperienza !== undefined) {
      delete updatedFilter.esperienza;
    }

    // Update the state with the modified object
    props.setFilter(updatedFilter);
  }; */

  return props.esperienza ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <WorkHistoryIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
          </Avatar>
        }
        label={props.esperienza.label}
        /* onDelete={handleDelete} */
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
        }}
        /* deleteIcon={<CloseIcon />} */
      />
    </Grid>
  ) : null;
}
export default ChipsFiltriEsperienza;
