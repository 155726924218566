import React from "react";
import JobErCard from "components/JobErCard";
import FaceIcon from "@mui/icons-material/Face";
import { Grid, Chip, Avatar } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import SchoolIcon from "@mui/icons-material/School";
import LanguageIcon from "@mui/icons-material/Language";
import WorkIcon from "@mui/icons-material/Work";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

function CardProfiloFiltriMobile(props) {
  return (
    <JobErCard
      title="Sezioni Profilo"
      child={
        <Grid container mt={1} spacing={2} justifyContent="center">
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <FaceIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Dati Anagrafici"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <SchoolIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Titoli Di Studio"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <LanguageIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Lingue"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <WorkIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Esperienze Lavorative"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <TipsAndUpdatesIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Lavori Di Interesse"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Chip
              avatar={
                <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                  <DocumentScannerIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
                </Avatar>
              }
              label="Documenti"
              sx={{
                backgroundColor: coloriVetrina.bgActiveMenu,
                "& .MuiChip-label": {
                  fontSize: "14px",
                },
                "& .MuiChip-deleteIcon": {
                  color: coloriVetrina.error,
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloFiltriMobile;
