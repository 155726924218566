import Swal from 'sweetalert2'
import "../css/styleToast.css"

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});