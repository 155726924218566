import React from "react";
import JobErCard from "components/JobErCard";
import { Grid, Divider } from "@mui/material";
import options from "../../json/option_configuration.json";
import JobErSelect from "components/JobErSelect";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function CardProfiloLingue(props) {
  const alertDeleteLingua = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questa lingua?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <JobErCard
      title="Lingue"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((lingua, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <React.Fragment key={`${index}-first`}>
                  <Grid item xs={12} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Lingua madre"}
                      options={options.lingue}
                      onChange={(data, event) => {
                        props.onSetDati("linguaMadre", data?.label ? data?.label : null);
                      }}
                      value={lingua.linguaMadre ? options.lingue.filter((opt) => opt.label.toLowerCase() === lingua.linguaMadre.toLowerCase()) : null}
                    />
                  </Grid>
                  <Grid item xs={8} md={8} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Seconda lingua"}
                      options={options.lingue}
                      onChange={(data, event) => {
                        props.onSetDati("secondaLingua", data?.label ? data?.label : null);
                      }}
                      value={lingua.secondaLingua ? options.lingue.filter((opt) => opt.label.toLowerCase() === lingua.secondaLingua.toLowerCase()) : null}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Livello"}
                      options={options.livelli_lingue}
                      onChange={(data, event) => {
                        props.onSetDati("livelloSecondaLingua", data?.label ? data?.label : null);
                      }}
                      value={
                        lingua.livelloSecondaLingua
                          ? options.livelli_lingue.filter((opt) => opt.label.toLowerCase() === lingua.livelloSecondaLingua.toLowerCase())
                          : null
                      }
                    />
                  </Grid>
                </React.Fragment>
              )}
              {index > 0 && (
                <>
                  <Grid item xs={12} m={1.5}>
                    <Divider />
                  </Grid>
                  <Grid item xs={8} md={8} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Seconda lingua"}
                      options={options.lingue}
                      value={lingua?.secondaLingua ? options.lingue.filter((opt) => opt.label.toLowerCase() === lingua.secondaLingua.toLowerCase()) : null}
                      onChange={(data) => {
                        props.onSetNestedDati(lingua.uuid, "secondaLingua", data?.label ? data?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} textAlign="left">
                    <JobErSelect
                      mode="white"
                      menuPosition="fixed"
                      isClearable="true"
                      placeholder={"Livello"}
                      options={options.livelli_lingue}
                      value={
                        lingua?.livelloSecondaLingua
                          ? options.livelli_lingue.filter((opt) => opt.label.toLowerCase() === lingua.livelloSecondaLingua.toLowerCase())
                          : null
                      }
                      onChange={(data) => {
                        props.onSetNestedDati(lingua.uuid, "livelloSecondaLingua", data?.label ? data?.label : null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JobButton
                      label="Rimuovi"
                      colorLabel={coloriVetrina.error}
                      colorBorder={coloriVetrina.error}
                      onClick={() => alertDeleteLingua(lingua.uuid)}
                    />
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <JobButton label="Salva" colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave()} />
                <JobButton label="Aggiungi Lingua" onClick={() => props.onAddNestedObject()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloLingue;
