import LayoutPersone from "components/PortalePersone/LayoutPersone";
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { useJobErController } from "context";
import JobErCard from "components/JobErCard";
import { useSearchParams } from "react-router-dom";
import ListaCandidature from "components/PortalePersone/ListaCandidature";
import { getCandidaturePersona } from "components/PortalePersone/utils/utils_candidatura";
import CircularProgress from "@mui/material/CircularProgress";
import JobLoading from "components/JobLoading";

function CandidaturePersone(props) {
  const [candidature, setCandidature] = useState();
  const [loading, setLoading] = useState(false);
  const [controller] = useJobErController();
  const { authUser } = controller;

  const [searchParams] = useSearchParams();
  const [uuidParam, setUuidParam] = useState(null);

  const articleRefs = useRef([]);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key === "candidatura") {
        setUuidParam(value);
      }
    });

    if (authUser?.codiceFiscale) {
      getCandidaturePersona(authUser, setCandidature, setLoading);
    }

    const articleElement = articleRefs.current[uuidParam];
    if (articleElement) {
      articleElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [authUser]);

  if (loading) {
    return <JobLoading />;
  }

  return (
    <LayoutPersone
      child={
        <>
          {loading ? (
            <Grid container justifyContent="center" mt={4}>
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              style={{ height: "100vh" }}
              sx={{
                padding: { xs: 2, md: 0 },
                backgroundColor: "#002b48!important",
              }}
            >
              <Grid item xs={12}>
                <JobErCard
                  title="Elenco candidature"
                  style={{ minHeight: "300px" }}
                  child={<ListaCandidature candidature={candidature} setCandidature={setCandidature} searchParams={uuidParam} />}
                />
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
}
export default CandidaturePersone;
