import React, { useState } from "react";
import { Grid, Typography, Card, CardMedia, CardContent, CardActions, Chip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { decodeHTMLEntities } from "utils/utilsDati";
import ModalDettagliAnnuncio from "./ModalDettagliAnnuncio";
import Tooltip from "@mui/material/Tooltip";
import coloriVetrina from "json/coloriVetrina.json";

function CardAnnunciPersone(props) {
  const [open, setOpen] = useState(false);

  let description = null;

  if (props.descrizioneLavoro) {
    // Helper function to capitalize the first letter
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Remove HTML tags and convert to lowercase
    const cleanDescription = decodeHTMLEntities(props.descrizioneLavoro.toLowerCase());

    if (cleanDescription.length > 105) {
      description = capitalizeFirstLetter(cleanDescription.substring(0, 90)) + " ...";
    } else {
      description = capitalizeFirstLetter(cleanDescription);
    }
  }

  return (
    <>
      <Card
        sx={{ borderRadius: 10, maxHeight: 370, minHeight: 370 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <CardMedia
          component="img"
          height="160"
          image={props.gruppoLavoro ? require("../../../immagini/ambiti_lavoro/gr" + props.gruppoLavoro + "-260x145.jpg") : null}
          style={{ borderRadius: 20 }}
        />
        <CardContent>
          <Grid container justifyContent="center" mb={1}>
            <Grid item xs={12}>
              <Chip
                label={props.mansione}
                size="small"
                className="ChipJobER"
                style={{
                  color: "white",
                  backgroundColor: coloriVetrina.blue,
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                <b>Titolo: </b> {props.titolo ? (props.titolo.length > 30 ? props.titolo.substring(0, 30) + "..." : props.titolo) : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                <b>Descrizione: </b> {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid item xs={6}>
              <Tooltip title="Locazione">
                <LocationOnIcon sx={{ mr: 1, color: coloriVetrina.blue }} />
                <b style={{ color: coloriVetrina.blue }}>{props.comune}</b>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title="Posizioni aperte">
                <Diversity3Icon sx={{ mr: 1, color: coloriVetrina.blue }} />
                <b style={{ color: coloriVetrina.blue }}>{props.posizioniAperte}</b>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <ModalDettagliAnnuncio open={open} setOpen={setOpen} titolo={props.titolo} descrizioneLavoro={props.descrizioneLavoro} />
    </>
  );
}
export default CardAnnunciPersone;
