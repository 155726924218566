import React from "react";
import Chip from "@mui/material/Chip";
import FactoryIcon from "@mui/icons-material/Factory";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsFiltriSettori(props) {
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return props.settori
    ? props.settori.map((settore) => (
        <Grid item xs="auto">
          <Chip
            avatar={
              <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
                <FactoryIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
              </Avatar>
            }
            label={settore.label.length > 25 ? settore.label.substring(0, 25) + " ..." : settore.label}
            /* onDelete={handleDelete} */
            sx={{
              backgroundColor: coloriVetrina.bgActiveMenu,
              "& .MuiChip-label": {
                fontSize: "14px",
              },
              "& .MuiChip-deleteIcon": {
                color: coloriVetrina.error,
                fontSize: "14px",
              },
            }}
            /* deleteIcon={<CloseIcon />} */
          />
        </Grid>
      ))
    : null;
}
export default ChipsFiltriSettori;
