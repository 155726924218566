import JobTable from "components/JobTable";
import { useEffect, useMemo, useState } from "react";
import { getCandidatiConfartigianato } from "components/PortaleConfartigianato/utils_confartigianato/candidatureConfartigianato";
import { useJobErController } from "context";
import JobButton from "components/JobButton";
import Chip from "@mui/material/Chip";
import { downloadDocumento } from "utils/utilsDati";

function Candidature(props) {
  const [candidature, setCandidature] = useState([]);
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (props.value === props.index) {
      if (authUser) {
        getCandidatiConfartigianato(setCandidature, authUser.codConfartigianato, setPending);
      }
    }
  }, [props.value]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "nominativo",
        header: "Nominativo",
      },
      {
        accessorKey: "ragsPiva",
        header: "Ragione Sociale",
      },
      {
        accessorKey: "titoloAnnuncio",
        header: "Annuncio",
      },
      {
        accessorKey: "dataCandidaturaIta",
        header: "Data Candidatura",
      },
      {
        accessorKey: "user",
        header: "Contatti",
        accessorFn: (row) => row.user + " - " + row.telefono,
      },
      {
        accessorKey: "statoCandidaturaLabel",
        header: "Stato",
        Cell: ({ row }) => (
          <Chip
            label={row.original.statoCandidaturaLabel}
            variant="outlined"
            sx={{ color: row.original.statoCandidaturaColor, borderColor: row.original.statoCandidaturaColor }}
          />
        ),
      },
      {
        accessorKey: "countCandidature",
        header: "Cv",
        Cell: ({ renderedCellValue, row }) =>
          row.original.documento && (
            <JobButton
              label="Cv"
              onClick={() => {
                let nomeFile = "cv_" + row.original.nominativo;
                downloadDocumento(row.original.documento, nomeFile);
              }}
            />
          ),
      },
    ],
    []
  );

  return props.value === props.index ? <JobTable data={candidature} columns={columns} state={{ isLoading: pending }} /> : null;
}

export default Candidature;
