import React from "react";
import { Grid } from "@mui/material";
import JobButton from "components/JobButton";
import StepperHomepage from "components/PortaleAziende/StepperHomepage";
import JobErCard from "components/JobErCard";
import { useNavigate } from "react-router-dom";

function HomeAziendePresentazione(props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={4}>
      <JobErCard
        title="Presentazione"
        child={
          <Grid container>
            <Grid item xs={12} mt={2}>
              <StepperHomepage presentazione={props.presentazione.dati} />
            </Grid>
            <Grid item xs={12} mt={2} textAlign="center">
              <JobButton
                onClick={() => {
                  navigate("/Aziende/Profilo");
                }}
                label={"Gestisci presentazione"}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

export default HomeAziendePresentazione;
