import React, { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as BB_SHORT } from "../../svgicon/JOB_LOGO_CONFER_WHITE.svg";
import { useNavigate } from "react-router-dom";
import MenuPortableDevice from "./MenuPortableDevice";
import MenuAvatarPortableDevice from "./MenuAvatarPortableDevice";

const pages = [
  { descrizione: "Home", url: "Confartigianato/Home" },
  { descrizione: "Aziende", url: "Confartigianato/Aziende" },
  { descrizione: "Gestione", url: "Confartigianato/Gestione" },
];

function AppBarConfartigianato(props) {
  const activePage = props.activePage;
  const colorText = props.color;
  const bgcolor = props.bgcolor;

  /* Gestione popover login */
  const [anchorEl, setAnchorEl] = useState(null);
  const openLogin = Boolean(anchorEl);

  const btnLoginRef = useRef();
  /* Fine gestione popover login */

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handlePopoverOpen = () => {
    setAnchorEl(btnLoginRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
        position="static"
        sx={{
          backgroundColor: "#002b48!important",
          pl: { xs: 0, md: 20 },
          pr: 2,
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MenuPortableDevice pages={pages}/>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
              <img
                src={require("../../immagini/Logo_mylapam_tree_white.png")}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: 15,
                }}
              />
            ) : (
              <BB_SHORT
                fill={colorText}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_sm"
                onClick={() => navigate("/Vetrina/Home")}
              ></BB_SHORT>
            )}
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" }, mt: 1, mb: 1 }}>
            <MenuAvatarPortableDevice />
          </Box>
        </Toolbar>
      </AppBar>
  );
}
export default AppBarConfartigianato;
