import React, { useState } from "react";
import { Grid, Typography, ListItem, ListItemText } from "@mui/material";
import JobErCard from "components/JobErCard";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import { salvaSede, disattivaSede, modificaSede } from "pages/portale_aziende/utils/utilsSede";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function GestioneSede(props) {
  const [showForm, setShowForm] = useState(false);
  const [labelBottone, setLabelBottone] = useState("Inserisci una sede");
  const [titoloForm, setTitoloForm] = useState("Inserisci una nuova sede");

  const modificaForm = (sede) => {
    let copySede = sede;
    copySede.dataMod = new Date();
    copySede.userMod = props.authUser ? props.authUser.email : null;

    props.setNuovaSede(copySede);
    setShowForm(true);
    setLabelBottone("Chiudi");
    setTitoloForm("Modifica la sede di " + sede.comune);
  };

  return (
    <JobErCard
      title="Sedi"
      child={
        <Grid container>
          {props.sedi && props.sedi.length > 0 ? (
            <>
              {/* print sedi */}
              {props.sedi.map((obj, index) => (
                <ListItem alignItems="flex-start" key={obj.uuid}>
                  <ListItemText
                    key={obj.uuid + "_text"}
                    sx={{ marginLeft: 2 }}
                    primary={obj.descrizioneComune.toUpperCase()}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      color: coloriVetrina.blue,
                    }}
                    secondary={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            sx={{
                              fontSize: "14px!important",
                              color: coloriVetrina.blue,
                            }}
                          >
                            {"Indirizzo: " + obj.indirizzo}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} pt={1}>
                          <JobButton
                            colorLabel={coloriVetrina.warning}
                            colorBorder={coloriVetrina.warning}
                            label={"Modifica"}
                            onClick={() => {
                              modificaForm(obj);
                            }}
                          />
                          <JobButton
                            colorLabel={coloriVetrina.error}
                            colorBorder={coloriVetrina.error}
                            label={"Disattiva"}
                            onClick={() => {
                              Swal.fire({
                                text: "Sei sicuro di voler eliminare questa sede?",
                                icon: "warning",
                                showCancelButton: true,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  disattivaSede(obj.uuid, props.sedi, props.setSedi, props.authUser);
                                } else if (result.isDenied) {
                                }
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </>
          ) : (
            !showForm && (
              <Grid item xs={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
                <Grid item xs={12} textAlign="center">
                  <Typography fontSize="14px" fontWeight="bold" sx={{ color: coloriVetrina.blue }}>
                    Nessuna sede
                  </Typography>
                </Grid>
              </Grid>
            )
          )}

          {showForm ? (
            <Grid item xs={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center" pb={1}>
                  <Typography
                    component="h5"
                    /*fontSize="16px"*/
                    fontWeight="bold"
                    sx={{ color: coloriVetrina.blue }}
                  >
                    {titoloForm}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <JobErAsyncSelect
                    mode="white"
                    menuPosition="fixed"
                    isMulti={false}
                    /* isClearable="true" */
                    placeholder={"Comune Residenza"}
                    onChange={(data) => props.setNuovaSede({ ...props.nuovaSede, comune: data.value, descrizioneComune: data.label })}
                    value={
                      props.nuovaSede && props.nuovaSede.descrizioneComune && props.nuovaSede.comune
                        ? {
                            value: props.nuovaSede.comune,
                            label: props.nuovaSede.descrizioneComune,
                          }
                        : null
                    }
                    loadOptions={asyncRicercaComuni}
                    noOptionsMessage={() => "Nessun comune trovato"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <JobErInput
                    label="Indirizzo e numero civico"
                    coloreTesto={coloriVetrina.black}
                    coloreBordo={coloriVetrina.gray}
                    size="small"
                    value={props.nuovaSede ? props.nuovaSede.indirizzo : ""}
                    onChange={(e) => props.setNuovaSede({ ...props.nuovaSede, indirizzo: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12} textAlign="center" p={2}>
            <JobButton
              label={labelBottone}
              onClick={() => {
                if (showForm) {
                  setShowForm(false);
                  setLabelBottone("Inserisci una sede");
                  props.setNuovaSede({});
                  setTitoloForm("Inserisci una nuova sede");
                } else {
                  setTitoloForm("Inserisci una nuova sede");
                  setLabelBottone("Chiudi");
                  setShowForm(true);
                }
              }}
            />
            {labelBottone === "Chiudi" && (
              <JobButton
                colorLabel={coloriVetrina.green}
                colorBorder={coloriVetrina.green}
                label={"Salva"}
                onClick={() => {
                  if (titoloForm === "Inserisci una nuova sede") {
                    salvaSede(props.nuovaSede, props.authUser, props.sedi, props.setSedi, setShowForm, setLabelBottone, props.setNuovaSede, setTitoloForm);
                  } else {
                    modificaSede(props.nuovaSede, props.sedi, props.setSedi, setShowForm, setLabelBottone, props.setNuovaSede, setTitoloForm);
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
}
export default GestioneSede;
