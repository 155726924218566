import React from "react";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import coloriVetrina from "../json/coloriVetrina.json";

function JobErItemMenu(props) {
  return (
    <ListItemButton
      sx={{
        backgroundColor: props.active ? "#19405a33" : null,
        borderRadius: 4,
      }}
      onClick={() => props.onClick()}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        primary={props.title}
        style={{
          color: props.active ? coloriVetrina.blue : coloriVetrina.gray,
        }}
        primaryTypographyProps={{
          fontWeight: props.active ? "bold!important" : "regular!important",
        }}
      />
    </ListItemButton>
  );
}
export default JobErItemMenu;
