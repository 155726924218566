import React from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import JobErCard from "components/JobErCard";
import JobErDropzone from "components/JobErDropzone";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import LanguageIcon from "@mui/icons-material/Language";
import { getBase64 } from "utils/utilsDati";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function GestionePresentazione(props) {
  return (
    <JobErCard
      title="Presentazione"
      child={
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container mt={2}>
              <Grid item xs={12} textAlign="left" mb={1}>
                <Typography
                  color={coloriVetrina.blue}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginRight: "12px",
                  }}
                >
                  Copertina
                </Typography>
                {props.presentazione && props.presentazione.copertina ? (
                  <JobButton
                    onClick={() => {
                      Swal.fire({
                        text: "Sei sicuro di voler eliminare la copertina?",
                        icon: "warning",
                        showCancelButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onSetData("copertina", null);
                        } else if (result.isDenied) {
                        }
                      });
                    }}
                    colorLabel={coloriVetrina.error}
                    colorBorder={coloriVetrina.error}
                    label={"Elimina"}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {props.presentazione && props.presentazione.copertina ? (
                  <img
                    src={props.presentazione.copertina}
                    style={{
                      height: "150px",
                      width: "100%",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <JobErDropzone
                    maxFiles={1}
                    acceptedFilesType={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    }}
                    files={null}
                    placeholder="Seleziona l'immagine di copertina"
                    onSetFiles={(files) =>
                      getBase64(files[0]).then((data) => {
                        props.onSetData("copertina", data);
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} mt={2}>
            <Grid container>
              <Grid item xs={12} textAlign="left" mb={1}>
                <Typography
                  color={coloriVetrina.blue}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginRight: "12px",
                  }}
                >
                  Miniatura
                </Typography>
                {props.presentazione?.miniatura ? (
                  <JobButton
                    onClick={() => {
                      Swal.fire({
                        text: "Sei sicuro di voler eliminare la miniatura?",
                        icon: "warning",
                        showCancelButton: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onSetData("miniatura", null);
                        } else if (result.isDenied) {
                        }
                      });
                    }}
                    colorLabel={coloriVetrina.error}
                    colorBorder={coloriVetrina.error}
                    label={"Elimina"}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {props.presentazione?.miniatura ? (
                  <img
                    src={props.presentazione.miniatura}
                    style={{
                      height: "150px",
                      width: "100%",
                      borderRadius: 15,
                    }}
                  />
                ) : (
                  <JobErDropzone
                    maxFiles={1}
                    acceptedFilesType={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    }}
                    files={null}
                    placeholder="Seleziona l'immagine di miniatura"
                    onSetFiles={(files) =>
                      getBase64(files[0]).then((data) => {
                        props.onSetData("miniatura", data);
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <JobErInput
              label="Sito web"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.presentazione ? props.presentazione.sitoWeb : ""}
              onChange={(e) => props.onSetData("sitoWeb", e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LanguageIcon sx={{ fontSize: "18px", color: coloriVetrina.lightGray }} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <JobErInput
              label="Descrizione"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              multiline={true}
              rows={5}
              value={props.presentazione ? props.presentazione.descrizione : ""}
              onChange={(e) => props.onSetData("descrizione", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <JobButton colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} label={"Salva"} onClick={() => props.onSave()} />
          </Grid>
        </Grid>
      }
    />
  );
}
export default GestionePresentazione;
