import React, { useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Avatar, Badge, Grid, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSnackbar } from "notistack";
import coloriVetrina from "../json/coloriVetrina.json";

const baseStyleDark = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 3,
  borderColor: coloriVetrina.blue,
  borderStyle: "dashed",
  backgroundColor: "#19405a33",
  color: coloriVetrina.blue,
  outline: "none",
  transition: "border .24s ease-in-out",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  //border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "fit-content",
  height: "fit-content",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  margin: 3,
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

function JobErDropzone(props) {
  const { enqueueSnackbar } = useSnackbar();

  const style = useMemo(() => ({
    ...baseStyleDark,
  }));

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFilesType,
    maxFiles: props.maxFiles,
    multiple: props.maxFiles > 1 ? true : false,
    maxSize: props.maxSize ? props.maxSize : 30000000,
    onDropAccepted: (acceptedFiles) => {
      props.onSetFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropRejected: (rejectedFiles) => {
      var newFileList = props.files.filter((doc) => rejectedFiles.name !== doc.name);
      props.onSetFiles(newFileList);
    },
    disabled: props.disabled ? props.disabled : false,
  });

  const thumbs =
    props.files !== null && props.files.length > 0
      ? props.files.map((file) => {
          return (
            <div style={thumb} key={"1_" + file.name}>
              <div style={thumbInner} key={"2_" + file.name}>
                <Badge
                  badgeContent={
                    <IconButton
                      style={{
                        top: "10px",
                        right: "10px",
                        backgroundColor: "#f5f5f5",
                        width: "fit-content",
                        height: "fit-content",
                        padding: 0,
                        color: "#f5365cd4",
                      }}
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (file.name) {
                          var newFileList = props.files.filter((doc) => file.name !== doc.name);
                          props.onSetFiles(newFileList);
                        } else {
                          props.onRemoveFile(newFileList);
                        }
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  }
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 65,
                      height: 65,
                      borderRadius: 5,
                      backgroundColor: file && file.type ? (file.type.split("\\/")[0] !== "image" ? "#002b48" : "transparent !important") : "#002b48",
                    }}
                  >
                    {file && file.type ? (
                      file.type.split("\\/")[0] !== "image" ? (
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                          <Grid
                            item
                            xs={8}
                            align="center"
                            mb={2}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography component="p" sx={{ fontSize: "12px!important" }}>
                              {file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(1) + " Mb" : parseFloat(file.size / 1000).toFixed(1) + " Kb"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            align="center"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography component="p" sx={{ fontSize: "12px!important" }}>
                              {file.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <img
                          src={file.preview}
                          style={img}
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                          }}
                        />
                      )
                    ) : null}
                  </Avatar>
                </Badge>
              </div>
            </div>
          );
        })
      : [];

  useEffect(() => {
    if (props.files !== null && props.files.length > 0) {
      return () => props.files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, []);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <aside style={thumbsContainer}> {thumbs} </aside>
        <CloudUploadIcon fontSize="large" />
        <Typography component="p" sx={{ fontSize: "12px!important" }}>
          {props.placeholder ? props.placeholder : "Seleziona un file da caricare"}
        </Typography>
      </div>
    </div>
  );
}
export default JobErDropzone;
