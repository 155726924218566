import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Grid } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import PeopleIcon from "@mui/icons-material/People";
import FactoryIcon from "@mui/icons-material/Factory";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import { LockOpen } from "@mui/icons-material";
import { ModalContext } from "context/modalLogin";

function MenuPortableDevice(props) {
  /* Gestione popover login */
  const [anchorEl, setAnchorEl] = useState(null);
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const openLogin = Boolean(anchorEl);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  /* Fine gestione popover login */

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton size="large" aria-label="" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            width: "90%",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container>
            <Grid item xs={12} textAlign="center">
              <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg" style={{ height: "1em!important" }}></LOGO_CONFARTIGIANATO>
            </Grid>
          </Grid>
        </MenuItem>
        {props.pages.map((page, index) => {
          return (
            <MenuItem key={page.url + "_xs"} onClick={() => navigate("/" + page.url.replaceAll(" ", ""))} style={{ justifyContent: "center" }}>
              {page.url === "Vetrina/Home" ? (
                <HomeIcon sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
              ) : page.url === "Vetrina/Persone" ? (
                <PeopleIcon sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
              ) : page.url === "Vetrina/Aziende" ? (
                <FactoryIcon
                  sx={{
                    color: coloriVetrina.blue,
                    marginRight: 1,
                  }}
                />
              ) : null}
              <Typography
                component="p"
                sx={{
                  color: coloriVetrina.blue,
                }}
              >
                {page.descrizione}
              </Typography>
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem key={"registrati" + "_xs"} onClick={() => navigate("/Vetrina/Registrati")} style={{ justifyContent: "center" }}>
          <AddIcon sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
          <Typography
            component="p"
            sx={{
              color: coloriVetrina.blue,
            }}
          >
            Registrati
          </Typography>
        </MenuItem>
        <MenuItem
          key={"accedi" + "_xs"}
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{ justifyContent: "center" }}
        >
          <LockOpen sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
          <Typography
            component="p"
            sx={{
              color: coloriVetrina.blue,
            }}
          >
            Accedi
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuPortableDevice;
