export const ProvinceER = [
  {
    descrizione: "Piacenza",
    colore: "#006699",
    svg_key: "mappa_piacenza",
    cod: "PC",
    mappaId: "",
    label: "Piacenza",
    value: "PC",
  },
  {
    descrizione: "Parma",
    colore: "#00ff99",
    svg_key: "mappa_parma",
    cod: "PR",
    label: "Parma",
    value: "PR",
  },
  {
    descrizione: "Reggio Emilia",
    colore: "#edbb99",
    svg_key: "mappa_reggio",
    cod: "RE",
    label: "Reggio Emilia",
    value: "RE",
  },
  {
    descrizione: "Modena",
    colore: "#E0DD00",
    svg_key: "mappa_modena",
    cod: "MO",
    label: "Modena",
    value: "MO",
  },
  {
    descrizione: "Bologna",
    colore: "#ff6600",
    svg_key: "mappa_bologna",
    cod: "BO",
    label: "Bologna",
    value: "BO",
  },
  {
    descrizione: "Ferrara",
    colore: "#cc66ff",
    svg_key: "mappa_ferrara",
    cod: "FE",
    label: "Ferrara",
    value: "FE",
  },
  {
    descrizione: "Ravenna",
    colore: "#e74c3c",
    svg_key: "mappa_ravenna",
    cod: "RA",
    label: "Ravenna",
    value: "RA",
  },
  {
    descrizione: "Forli - Cesena",
    colore: "#7f8c8d",
    svg_key: "mappa_forli_cesena",
    cod: "FC",
    label: "Forli - Cesena",
    value: "FC",
  },
  {
    descrizione: "Rimini",
    colore: "#669900",
    svg_key: "mappa_rimini",
    cod: "RN",
    label: "Rimini",
    value: "RN",
  },
];
