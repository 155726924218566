import React, { useContext } from "react";
import JobErModal from "components/JobErModal";
import JobButton from "components/JobButton";
import { ModalContext } from "context/modalLogin";
import { Grid } from "@mui/material";
import coloriVetrina from "json/coloriVetrina";

function ModalDettagliAnnuncio(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  return (
    <JobErModal
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      title={props.titolo}
      showCloseButton={true}
      modalBody={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: props.descrizioneLavoro }} />
          </Grid>
        </Grid>
      }
      modalFooter={
        <JobButton
          colorBackground={coloriVetrina.blue}
          colorLabel={coloriVetrina.white}
          fullWidth={true}
          fontSize="18px"
          size="large"
          label={"Candidati"}
          onClick={() => {
            props.setOpen(false);
            setIsModalOpen(true);
          }}
        />
      }
    />
  );
}

export default ModalDettagliAnnuncio;
