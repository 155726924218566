import React from "react";
import Grid from "@mui/material/Grid";
import coloriVetrina from "json/coloriVetrina.json";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const Loading = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: "100vh", backgroundColor: coloriVetrina.blue }}>
      <Grid item xs={12}>
        <CircularProgress sx={{ color: "white" }} />
        <br></br>
        <br></br>
        <Typography variant="h6" sx={{ color: "white" }}>
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Loading;
