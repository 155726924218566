import React from "react";
import { Grid } from "@mui/material";
import LayoutConfartigianato from "components/PortaleConfartigianato/LayoutConfartigianato";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import JobErCard from "components/JobErCard";
import Candidature from "./Candidature";
import StoricoAnnunci from "./StoricoAnnunci";
import coloriVetrina from "json/coloriVetrina.json";

function GestioneConfartigianato(props)
{
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <LayoutConfartigianato
            child={
                <Grid container>
                    <Grid item xs={12}>
                        <JobErCard title="Gestione" child={
                            <Grid container>
                                <Grid item xs={12} pt={2}>
                                    <Tabs 
                                        value={value} 
                                        onChange={handleChange} 
                                        aria-label="gestione confartigianato"
                                        sx={{
                                        '& .MuiTab-root': { color: coloriVetrina.blue },
                                        '& .MuiTabs-indicator': { backgroundColor: coloriVetrina.blue },
                                        '& .Mui-selected': { color: coloriVetrina.blue }
                                        }}>
                                            <Tab label="Storico Annunci" sx={{color: coloriVetrina.blue}} {...a11yProps(0)} />
                                            <Tab label="Candidature" {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    <StoricoAnnunci value={value} index={0}></StoricoAnnunci>
                                    <Candidature value={value} index={1}></Candidature>
                                </Grid>
                            </Grid>
                            }
                        />
                    </Grid>
                </Grid>
            }
        />
    )

}

export default GestioneConfartigianato;