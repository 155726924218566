import { createContext, useContext, useReducer, useMemo } from "react";

import PropTypes from "prop-types";

const JobRegionale = createContext(null);

JobRegionale.displayName = "JobRegionaleContext";

function reducer(state, action) {
  switch (action.type) {
    case "AUTH_USER": {
      return { ...state, authUser: action.value };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function JobErControllerProvider({ children }) {
  const initialState = {
    authUser: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
    <JobRegionale.Provider value={value}>{children}</JobRegionale.Provider>
  );
}

function useJobErController() {
  const context = useContext(JobRegionale);

  if (!context) {
    throw new Error(
      "JobErControllerProvider should be used inside the JobErControllerProvider."
    );
  }

  return context;
}

JobErControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setAuthUser = (dispatch, value) => dispatch({ type: "AUTH_USER", value });

export { JobErControllerProvider, useJobErController, setAuthUser };
