import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import { Toast } from "components/Toast";
import JobButton from "components/JobButton";

function RichiestaInformazioni(props) {
  const [informazioni, setInformazioni] = useState({
    uuid: null,
    richiedente: "persona",
    ragioneSociale: null,
    partitaIva: null,
    email: null,
    richiesta: null,
    nome: null,
    cognome: null,
  });
  const [dataError, setDataError] = useState([]);
  const checkField = ["nome", "cognome", "email", "richiesta"];
  const [verificaEmail, setVerificaEmail] = useState(null);
  const [pending, setPending] = useState(false);

  const inviaRichiesta = () => {
    var objError = [];
    var checkMail = null;

    for (var i = 0; i < checkField.length; i++) {
      if (!informazioni[checkField[i]]) {
        objError.push(checkField[i]);
      }
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(informazioni.email) && informazioni.email) {
      checkMail = "ok";
    } else {
      checkMail = "invalid";
    }

    setDataError(objError);
    setVerificaEmail(checkMail);

    if (objError.length === 0 && checkMail === "ok") {
      setPending(true);

      const params = new URLSearchParams({
        linkAmbiente: process.env.REACT_APP_URL_FE,
        ambiente: process.env.REACT_APP_AMBIENTE,
      });

      fetch(process.env.REACT_APP_URL + "/vetrina/richiestaContatto?" + params, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(informazioni),
      }).then((res) => {
        if (res.status === 200) {
          setInformazioni({
            uuid: null,
            richiedente: "persona",
            ragioneSociale: null,
            partitaIva: null,
            email: null,
            richiesta: null,
            nome: null,
            cognome: null,
          });
          Toast.fire({ icon: "success", title: "Richiesta inviata!" });
        } else {
          Toast.fire({ icon: "error", title: "La tua richiesta non è stata inoltrata, riprova!" });
        }

        setPending(false);
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="p" sx={{ color: "#efefef", fontSize: "4vh !important" }}>
            <b>Hai bisogno di informazioni?</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <JobErInput
            label="Nome"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("nome") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo nome"
            value={informazioni.nome ? informazioni.nome : ""}
            onChange={(e) => setInformazioni({ ...informazioni, nome: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <JobErInput
            label="Cognome"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("cognome") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText=" Inserisci il tuo cognome"
            value={informazioni.cognome ? informazioni.cognome : ""}
            onChange={(e) => setInformazioni({ ...informazioni, cognome: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <JobErInput
            label="Email"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("email") || (verificaEmail !== "ok" && verificaEmail) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText={verificaEmail === "forbidden" ? "Email già utilizzata" : verificaEmail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
            value={informazioni.email ? informazioni.email : ""}
            onChange={(e) => setInformazioni({ ...informazioni, email: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <JobErInput
            label="Richiesta"
            multiline={true}
            rows={5}
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("richiesta") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci la tua richiesta"
            value={informazioni.richiesta ? informazioni.richiesta : ""}
            onChange={(e) => setInformazioni({ ...informazioni, richiesta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            label="Invia Richiesta"
            onClick={inviaRichiesta}
            loading={pending}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default RichiestaInformazioni;
