import React from "react";
import Select from "react-select";
import coloriVetrina from "../json/coloriVetrina.json";

function JobErSelect(props) {
  return (
    <Select
      isClearable={props.isClearable}
      isSearchable={props.isSearchable}
      menuPosition={props.menuPosition}
      placeholder={props.placeholder}
      isMulti={props.isMulti}
      isDisabled={props.disabled}
      name={props.name}
      onInputChange={props.onInputChange ? (e) => props.onInputChange(e) : null}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: props.error ? coloriVetrina.error : state.isFocused ? coloriVetrina.green : coloriVetrina.gray,
          backgroundColor: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
          color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
          borderRadius: 15,
          textAlign: "left",
        }),
        menu: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            backgroundColor: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
            color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
            borderRadius: 15,
          };
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: "15px",
            backgroundColor: props.mode === "white" ? (isFocused ? "#19405A" : coloriVetrina.white) : isFocused ? coloriVetrina.white : coloriVetrina.blue,
            color: props.mode === "white" ? (isFocused ? coloriVetrina.white : coloriVetrina.blue) : isFocused ? coloriVetrina.blue : coloriVetrina.white,
            fontWeight: isDisabled ? "normal" : "bold",
            borderRadius: 15,
          };
        },
        singleValue: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            fontWeight: "bold",
            color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
          };
        },
        multiValue: (styles) => {
          return {
            ...styles,
            borderRadius: "10px",
            backgroundColor: coloriVetrina.blue,
            padding: 2,
          };
        },
        multiValueLabel: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            fontWeight: "bold",
            color: coloriVetrina.white,
            marginRight: 10,
          };
        },
        multiValueRemove: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            color: coloriVetrina.error,
            backgroundColor: "#f5365c59",
            borderRadius: 10,
          };
        },
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: props.mode === "white" ? coloriVetrina.gray : coloriVetrina.white,
            fontSize: "14px",
            marginLeft: "5px",
          };
        },
      }}
      options={props.options}
      value={props.value}
      onChange={(option, event) => props.onChange(option, event)}
    />
  );
}
export default JobErSelect;
