import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaRuoli, asyncRicercaComuni } from "utils/utilsRicerca";
import JobErSelect from "components/JobErSelect";
import { convertCodificaMansioneToOptMansione } from "pages/portale_aziende/utils/utilsAnnunci";
import { getSediAzienda } from "pages/portale_aziende/utils/utilsHomepage";

function AnnuncioStep1(props) {
  const [optionSedi, setOptionSedi] = useState([]);

  useEffect(() => {
    getSediAzienda(localStorage.getItem("partitaIva")).then((res) => {
      var arraySede = [];
      res.map((sede) =>
        arraySede.push({
          label: sede.descrizioneComune.toUpperCase() + " - " + sede.indirizzo.toUpperCase(),
          value: sede.indirizzo + "_" + sede.comune,
          comune: sede.comune,
        })
      );
      setOptionSedi(arraySede);
    });
  }, []);

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" pr={2}>
          <Grid item xs={12} md={6}>
            <JobErInput
              label="Titolo annuncio *"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati ? props.dati.titoloAnnuncio : ""}
              onChange={(e) => props.onSetDati("titoloAnnuncio", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={0} spacing={2} pr={2}>
          <Grid item xs={12} md={3}>
            <JobErInput
              label="Data pubblicazione"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              disabled={true}
              value={props.dati ? props.dati.dataPubblicazione : ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={false}
              isClearable="true"
              placeholder={"Ruolo *"}
              loadOptions={asyncRicercaRuoli}
              noOptionsMessage={() => "Nessun ruolo trovato"}
              value={
                props.dati && props.dati.posizioneLavorativa
                  ? props.dati.posizioneLavorativa
                  : props.dati && props.dati.codificaMansione
                  ? convertCodificaMansioneToOptMansione(props.dati.codificaMansione)
                  : null
              }
              onChange={(data) => {
                props.onSetDati("posizioneLavorativa", data);
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={0} spacing={2} pr={2}>
          <Grid item xs={12} md={6} textAlign="left">
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Sede lavoro *"}
              onChange={(data) => props.onSetDati("indirizzo", data)}
              value={
                props.dati && props.dati.indirizzo && typeof props.dati.indirizzo === "object"
                  ? props.dati.indirizzo
                  : props.dati && props.dati.indirizzo && typeof props.dati.indirizzo === "string"
                  ? {
                      label: props.dati.indirizzo,
                      value: props.dati.indirizzo,
                    }
                  : null
              }
              options={optionSedi}
              onInputChange={(e) => {
                var opts = [...optionSedi].filter((opt) => opt.value !== "custom");
                if (e && e.trim() !== "") {
                  opts.push({
                    value: "custom",
                    label: e,
                  });
                }

                setOptionSedi(opts);
              }}
            />
          </Grid>{" "}
          {props.dati && props.dati.indirizzo && props.dati.indirizzo.value === "custom" ? (
            <Grid item xs={12} md={3} textAlign="left">
              <JobErAsyncSelect
                mode="white"
                menuPosition="fixed"
                isClearable="true"
                placeholder={"Seleziona Comune"}
                loadOptions={asyncRicercaComuni}
                noOptionsMessage={() => "Nessun comune trovato"}
                onChange={(data) => {
                  props.onSetDati("comuneSede", data);
                }}
                value={props.dati && props.dati.comuneSede ? props.dati.comuneSede : null}
              />{" "}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep1;
