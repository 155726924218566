import React from "react";
import Chip from "@mui/material/Chip";
import WorkIcon from "@mui/icons-material/Work";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsSettore(props) {
  return props.settore ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <WorkIcon sx={{ fontSize: "18px", color: coloriVetrina.white }} />
          </Avatar>
        }
        label={props.settore}
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsSettore;
