import React, { useState } from "react";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaComuni, asyncRicercaSettore } from "utils/utilsRicerca";
import { Collapse, Grid } from "@mui/material";
import JobErSelect from "components/JobErSelect";
import JobButton from "components/JobButton";
import { ProvinceER } from "utils/ProvinceER";

function SezioneFiltriAnnunci(props) {
  const [open, setOpen] = useState(true);
  ProvinceER.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Grid container mt={1} mb={2} justifyContent="center">
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }} mb={1}>
        <JobButton label={open ? "Nascondi Filtri" : "Visualizza Filtri"} onClick={() => setOpen(!open)} />
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              isMulti={true}
              placeholder={"Provincia"}
              onChange={(data) => {
                props.onSetFilter("provincia", data);
              }}
              value={props.filter.provincia}
              options={ProvinceER}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={true}
              isClearable="true"
              placeholder={"Comune"}
              value={props.filter.comuned}
              loadOptions={asyncRicercaComuni}
              noOptionsMessage={() => "Nessun comune trovato"}
              onChange={(data) => {
                props.onSetFilter("comune", data);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={true}
              isClearable="true"
              placeholder={"Settore"}
              value={props.filter.settore}
              loadOptions={asyncRicercaSettore}
              noOptionsMessage={() => "Nessun settore trovato"}
              onChange={(data) => {
                props.onSetFilter("settore", data);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Periodo"}
              onChange={(data) => {
                props.onSetFilter("periodo", data);
              }}
              value={props.filter.periodo}
              options={[
                {
                  value: "tutti",
                  label: "Tutti",
                  isSelected: true,
                },
                {
                  value: "ultima_settimana",
                  label: "Ultima Settimana",
                },
                {
                  value: "mese_corrente",
                  label: "Questo Mese",
                },
                {
                  value: "ultimi6",
                  label: "Ultimi 6 Mesi",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Titolo Studio"}
              onChange={(data) => {
                props.onSetFilter("titoloStudio", data);
              }}
              value={props.filter.titoloStudio}
              options={[
                {
                  value: "nessuno",
                  label: "Nessuno",
                },
                {
                  value: "diploma",
                  label: "Diploma",
                },
                {
                  value: "laurea",
                  label: "Laurea",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Esperienza Lavorativa"}
              onChange={(data) => {
                props.onSetFilter("esperienza", data);
              }}
              value={props.filter.esperienza}
              options={[
                {
                  value: "nessuno",
                  label: "Nessuna",
                },
                {
                  value: "5_anni",
                  label: "+5 anni",
                },
                {
                  value: "10_anni",
                  label: "+10 anni",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}
export default SezioneFiltriAnnunci;
