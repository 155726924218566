import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FooterBB from "components/Vetrina/Footer";
import coloriVetrina from "../../json/coloriVetrina.json";
import PresentazioneServizio from "components/Vetrina/PerLePersone/PresentazioneServizio";
import RichiestaInformazioni from "components/Vetrina/PerLePersone/RichiestaInformazioni";
import AppBarJob from "components/Vetrina/AppBarJob";
import ItemMappaJobMORE from "./ItemMappaJobMORE";
import ItemMappaJobER from "./ItemMappaJobER";
import { Toast } from "components/Toast";

/* function getTop3Mansioni(data) {
  let nestedMansioni = data.map(
    (annuncio) => annuncio.codificaMansione.descrizione
  );
  nestedMansioni = nestedMansioni.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
  }, {});
  let countMansioni = [];
  for (let key of Object.keys(nestedMansioni)) {
    let ck = key
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(" ");
    countMansioni.push({ chiave: ck, val: nestedMansioni[key] });
  }
  countMansioni = countMansioni.sort((a, b) => b.val - a.val);
  if (countMansioni.length > 3) {
    countMansioni = countMansioni.slice(0, 3);
  }
  return countMansioni;
} */

function Persone(props) {
  const [ultimiAnnunci, setUltimiAnnunci] = useState([]);
  const [annunci, setAnnunci] = useState([]);
  const [counterProvince, setCounterProvince] = useState([]);
  const [top3Mansioni, setTop3Mansioni] = useState(null);
  const [loading, setLoading] = useState(false);
  const [counterMoRe, setCounterMoRe] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetch(process.env.REACT_APP_URL + "/vetrina/elencoAnnunci", {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let arrayUltimiAnnunci = result.slice(0, 12);
          setUltimiAnnunci(arrayUltimiAnnunci);
          setAnnunci(result);
          setLoading(false);

          /* let mansioni = getTop3Mansioni(result);
          setTop3Mansioni(mansioni); */
        },
        (error) => {
          console.error("Si è verificato un errore nel caricamento degli annunci");
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
        }
      );

    setLoading(false);
  }, []);

  return (
    <>
      <AppBarJob bgcolor={coloriVetrina.blue} color={coloriVetrina.white} activePage={"Persone"} onUserLogin={(user) => props.onUserLogin(user)}></AppBarJob>
      <Grid container backgroundColor={coloriVetrina.blue} sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 8, md: 8 } }}>
        <PresentazioneServizio annunci={ultimiAnnunci} loading={loading} />
      </Grid>
      <Grid
        container
        backgroundColor={coloriVetrina.blue}
        sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 8, md: 8 } }}
        spacing={3}
        justifyContent="center"
        textAlign="center"
      >
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? <ItemMappaJobMORE annunci={annunci} /> : <ItemMappaJobER annunci={annunci} />}
      </Grid>
      {/* <Grid
        container
        backgroundColor={coloriVetrina.blue}
        sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 4, md: 8 }, pt: { xs: 4, md: 8 },
        justifyContent: "center", display: { md: "flex" }, }}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="h1" color={coloriVetrina.white}>
            Sei alla ricerca di un'occupazione stabile e gratificante?
          </Typography>
          <Typography variant="h6" color={coloriVetrina.white}>
            Ecco alcuni dei settori che offrono le migliori opportunità
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{ justifyContent: "center" }}
        backgroundColor={coloriVetrina.blue}
      >
        {top3Mansioni
          ? top3Mansioni.map((mansione, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={11}
                  md={3}
                  sx={{
                    p: 4,
                    borderRight: {
                      md:
                        index < 2 && top3Mansioni.length === 3
                          ? "solid .2vh #efefef"
                          : "none",
                      xs: "none",
                    },
                    textAlign: { md: "center" },
                  }}
                >
                  <Typography
                    component="p"
                    sx={{ color: "#efefef", fontSize: "3.5vh !important" }}
                  >
                    <b>{mansione.chiave}</b>
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#efefef",
                      fontSize: "18px!important",
                      fontWeight: "regular",
                    }}
                  >
                    <CountUp
                      end={mansione.val}
                      start={0}
                      style={{ fontSize: 60 }}
                    />
                  </Typography>
                </Grid>
              );
            })
          : null}
      </Grid> */}
      <Grid container backgroundColor={coloriVetrina.blue} sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 4, md: 8 }, pt: { xs: 4, md: 8 } }}>
        <RichiestaInformazioni />
      </Grid>
      <FooterBB color1={coloriVetrina.lightGray} color2="#19405A" color3="white" />
    </>
  );
}
export default Persone;
