import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function getAziendeHomepage(provinciaSelezionata, setAziendeTerritorio, setLoading, page, setPagination) {
  setLoading(true);

  fetch(process.env.REACT_APP_URL + "/persone/getListaAziendeProvincia/" + (provinciaSelezionata ? provinciaSelezionata.value : "null") + "/" + page, {
    headers: getAuthHeader(),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        setAziendeTerritorio(result.content);
        setPagination(result);
        setLoading(false);
      },
      (error) => {
        console.error("Si è verificato un errore");
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
        setLoading(false);
      }
    );
}
