import { getAuthHeaderVetrina, getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function cercaAnnunciVetrina(formData, setAnnunci, setPendingFilter, setMessaggio) {
  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  let filtro = {
    comune: { label: formData?.comune?.label, value: formData?.comune?.value },
    professione: { label: formData?.professione?.label, value: formData?.professione?.uuid },
    provincia: { label: formData?.provincia?.label, value: formData?.provincia?.value },
  };

  setPendingFilter(true);

  fetch(process.env.REACT_APP_URL + "/vetrina/annunciPerComuneMoRe", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(filtro),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
        setPendingFilter(false);
        return null;
      }
    })
    .then(
      (result) => {
        setAnnunci(result);
        setPendingFilter(false);

        if (result.length === 0) {
          setMessaggio("Nessun annuncio trovato");
        } else {
          setMessaggio("");
        }
      },
      (error) => {
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
        setPendingFilter(false);
      }
    );
}

export function onClickMappaMore(e, setSelectProv, annunci, setFiltroAnnunci) {
  if (e.target.id && e.target.getAttribute("descrizione")) {
    if (e.target.getAttribute("codice") === "MO") {
      setSelectProv({ label: "Modena", prov: e.target.getAttribute("codice") });
    } else if (e.target.getAttribute("codice") === "RE") {
      setSelectProv({ label: "Reggio Nell'Emilia", prov: e.target.getAttribute("codice") });
    }

    let filtro = annunci.filter((obj) => obj.comune.prov === e.target.getAttribute("codice"));
    setFiltroAnnunci(filtro);
  }
}

export function onClickAnnunciMore(setSelectProv, annunci, setFiltroAnnunci, obj, setListaProfessioni) {
  if (obj.prov === "MO") {
    setSelectProv({ label: "Modena", prov: obj.prov });
  } else if (obj.prov === "RE") {
    setSelectProv({ label: "Reggio Nell'Emilia", prov: obj.prov });
  }

  let filtro = annunci.filter((obj2) => obj2.comune.prov === obj.prov);
  let arrayProfessioni = [];

  for (let t = 0; t < filtro.length; t++) {
    arrayProfessioni.push(filtro[t].posizioneLavorativa.descrizione);
  }

  const distinctArray = [...new Set(arrayProfessioni)].sort();
  arrayProfessioni = [];

  for (let y = 0; y < distinctArray.length; y++) {
    arrayProfessioni.push({
      label: distinctArray[y],
      value: distinctArray[y],
    });
  }

  setListaProfessioni(arrayProfessioni);
  setFiltroAnnunci(filtro);
}

export function onClickMappaEr(e, setSelectRegione, annunci, setFiltroAnnunci, provinciaSelezionata, setProvinciaSelezionata) {
  if (e.target.id && e.target.getAttribute("descrizione")) {
    setSelectRegione({ label: e.target.getAttribute("descrizione"), value: e.target.getAttribute("codice") });

    let filtro = annunci.filter((obj) => obj.comune.prov === e.target.getAttribute("codice"));

    setFiltroAnnunci(filtro);

    if (provinciaSelezionata) {
      const previousElement = document.querySelector(`.selectedprov_${provinciaSelezionata.idmap}`);
      if (previousElement) {
        previousElement.classList.remove(`selectedprov_${provinciaSelezionata.idmap}`);
      }
    }

    // Extract province identifier from the clicked element's ID
    const strProvincia = e.target.id.replace(/[0-9]/g, "");

    // Set the new selected province details
    const newProvincia = {
      label: `${e.target.getAttribute("descrizione").toUpperCase()} ( ${e.target.getAttribute("codice")} )`,
      value: e.target.getAttribute("codice"),
      idmap: strProvincia,
    };
    setProvinciaSelezionata(newProvincia);

    // Add selected class to the clicked element
    e.target.classList.add(`selectedprov_${strProvincia}`);
  }
}

export function onClickAnnunciEr(obj, setSelectRegione, annunci, setFiltroAnnunci, provinciaSelezionata, setListaProfessioni) {
  if (obj.cod) {
    setSelectRegione({ label: obj.descrizione, value: obj.cod });
  }

  let filtro = annunci.filter((obj2) => obj2.comune.prov === obj.cod);
  let arrayProfessioni = [];

  for (let t = 0; t < filtro.length; t++) {
    arrayProfessioni.push(filtro[t].posizioneLavorativa.descrizione);
  }

  const distinctArray = [...new Set(arrayProfessioni)].sort();
  arrayProfessioni = [];

  for (let y = 0; y < distinctArray.length; y++) {
    arrayProfessioni.push({
      label: distinctArray[y],
      value: distinctArray[y],
    });
  }

  setListaProfessioni(arrayProfessioni);
  setFiltroAnnunci(filtro);

  if (provinciaSelezionata) {
    const previousElement = document.querySelector(`.selectedprov_${provinciaSelezionata.idmap}`);
    if (previousElement) {
      previousElement.classList.remove(`selectedprov_${provinciaSelezionata.idmap}`);
    }
  }
}
