import React, { useEffect, useState } from "react";
import RegistrazioneAzienda from "components/Vetrina/Registrazione/RegistrazioneAzienda";
import RegistrazionePersona from "components/Vetrina/Registrazione/RegistrazionePersona";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import FooterBB from "components/Vetrina/Footer";
import PngRegistrazione from "../../immagini/ASSETMLJC_ER_03_B.png";
import RegistrazioneCompletata from "components/Vetrina/Registrazione/RegistrazioneCompletata";
import AppBarJob from "components/Vetrina/AppBarJob";
import JobErSelect from "components/JobErSelect";
import options from "json/option_configuration.json";

function Registrazione(props) {
  const [tipoUtente, setTipoUtente] = useState(null);
  const [registrazioneCompletata, setRegistrazioneCompletata] = useState(null);

  useEffect(() => {
    setRegistrazioneCompletata(null);
  }, []);

  return (
    <>
      <AppBarJob bgcolor={coloriVetrina.blue} color={coloriVetrina.white} activePage={"Home"} onUserLogin={(user) => props.onUserLogin(user)}></AppBarJob>
      <Grid
        container
        backgroundColor={coloriVetrina.blue}
        sx={{
          pl: { xs: 2, md: 15 },
          pr: { xs: 2, md: 15 },
          pb: { xs: 4, md: 8 },
          pt: { xs: 4, md: 8 },
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "flex" } }}>
          <img src={PngRegistrazione} style={{ height: "60vh" }} />
        </Grid>
        <Grid item xs={12} md={8}>
          {registrazioneCompletata === null ? (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "center!important", mt: "2vh" }}>
                    Benvenuto sul portale!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" color={coloriVetrina.white} sx={{ textAlign: "center", mt: "2vh" }}>
                    Registrati per accedere a funzionalità esclusive e contenuti riservati.<br></br>È facile, veloce e gratuito
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" mt={3}>
                <Grid item xs={12} md={4}>
                  <JobErSelect
                    menuPosition="fixed"
                    isClearable="true"
                    placeholder="Seleziona tipo registrazione"
                    value={tipoUtente}
                    onChange={(data) => setTipoUtente(data)}
                    options={
                      process.env.REACT_APP_AMBIENTE === "modena-reggio"
                        ? [
                            {
                              value: "persona",
                              label: "Candidato",
                            },
                          ]
                        : options.candidatoAzienda
                    }
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          {registrazioneCompletata === null && tipoUtente?.value === "azienda" ? (
            <RegistrazioneAzienda onRegistrazioneCompletata={(data) => setRegistrazioneCompletata(data)} />
          ) : registrazioneCompletata === null && tipoUtente?.value === "persona" ? (
            <RegistrazionePersona
              onRegistrazioneCompletata={(data) => {
                setRegistrazioneCompletata(data);
              }}
            />
          ) : registrazioneCompletata !== null ? (
            <RegistrazioneCompletata data={registrazioneCompletata} tipoUtente={tipoUtente?.value} />
          ) : null}
        </Grid>
      </Grid>

      <FooterBB color1={coloriVetrina.lightGray} color2="#19405A" color3="white" />
    </>
  );
}
export default Registrazione;
