import React, { useState, useEffect, cloneElement } from "react";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useJobErController } from "context";

export const ProtectedRoute = ({ children }) => {
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [url, setUrl] = useState(null);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    setUrl(searchParams);
  }, []);

  if (!localStorage.getItem("accessToken")) {
    return <Navigate to="/Login" />;
  } else if (authUser && url) {
    return cloneElement(children, {
      url: url,
    });
  } else {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100vh",
          backgroundColor: "#44a18038",
          display: "flex",
        }}
      >
        <Grid item xs={12} style={{}} alignContent="center" textAlign="center">
          <Grid container textAlign="center">
            {/*<Grid item xs={12}>
              <BoxContainer component="img" src={brandIcon} width="6rem" mr={0.25} />
            </Grid>*/}
            <Grid item xs={12} textAlign="center">
              <CircularProgress color="success" thickness={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
