import { Buffer } from "buffer";

function encodeBase64(data) {
  return Buffer.from(data).toString("base64");
}

export function checkPassword(password, setErrori, errori) {

  if (password) {
    setErrori({ ...errori, password: "ok" })
  }
  else {
    setErrori({ ...errori, password: "invalid" })
  }
}

export function checkEmail(mail, setErrori, errori) {

  const path = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (path.test(mail) && mail) {
    setErrori({ ...errori, email: "ok" })
  }
  else {
    setErrori({ ...errori, email: "invalid" })
  }
}

export async function loginConfartigianato(utente) {
  return new Promise((resolve, reject) => {

    let credenziali = utente.email + ":" + utente.password;
    credenziali = encodeBase64(credenziali);

    fetch(process.env.REACT_APP_URL + "/user/login_confartigianato/" + utente.email, {
      method: "GET",
      headers: {
        Authorization: "Basic " + credenziali,
        From: "confartigianato",
      },
    }).then((res) => {
      if (res.status === 200) {
        localStorage.setItem("accessToken", res.headers.get("Authorization"));
        resolve(res.json());
      } else {
        reject(res);
      }
    });
  })
}