import React, { useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import coloriVetrina from "../json/coloriVetrina.json";

function JobErAsyncSelect(props) {
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  return (
    <AsyncSelect
      defaultOptions
      cacheOptions
      loadingMessage={(e) => {
        if (e.length > 3) {
          return "Sto ricercando...";
        } else {
          return "Digita almeno 4 caratteri!";
        }
      }}
      isDisabled={props.disabled}
      isMulti={props.isMulti}
      menuPosition={props.menuPosition}
      isClearable={props.isClearable}
      placeholder={props.placeholder}
      value={props.value}
      noOptionsMessage={props.noOptionsMessage}
      loadOptions={(e, callback) => {
        if (e.length > 3) {
          setIsTyping(true);

          if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
          }

          typingTimeoutRef.current = setTimeout(() => {
            setIsTyping(false);
            const loadOptionsPromise = props.loadOptions(e);

            // Log when the promise is finished
            loadOptionsPromise
              .then((result) => {
                setIsTyping(false);
                callback(result);
              })
              .catch((error) => {
                console.error("Error loading options:", error);
                setIsTyping(false);
              });
          }, 1000);
        }

        if (!e) {
          callback(null);
        }
      }}
      onChange={(option) => props.onChange(option)}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: props.error ? coloriVetrina.error : state.isFocused ? coloriVetrina.green : coloriVetrina.gray,
          backgroundColor: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
          color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
          borderRadius: 15,
          textAlign: "left",
        }),
        menu: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            backgroundColor: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
            color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
            borderRadius: 15,
          };
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: "15px",
            backgroundColor: props.mode === "white" ? (isFocused ? "#19405A" : coloriVetrina.white) : isFocused ? "#19405A" : coloriVetrina.blue,
            color: isFocused ? coloriVetrina.white : props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
            fontWeight: isDisabled ? "normal" : "bold",
            borderRadius: 15,
          };
        },
        singleValue: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            fontWeight: "bold",
            color: props.disabled ? coloriVetrina.lightGray : props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
          };
        },
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: props.mode === "white" ? coloriVetrina.gray : coloriVetrina.white,
            fontSize: 14,
            marginLeft: 5,
          };
        },
        input: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
            fontSize: 14,
            marginLeft: 5,
          };
        },
        multiValue: (styles) => {
          return {
            ...styles,
            fontSize: "15px",
            fontWeight: "bold",
            color: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
            borderRadius: 10,
            backgroundColor: props.mode === "white" ? coloriVetrina.blue : coloriVetrina.white,
          };
        },
        multiValueLabel: (styles) => {
          return {
            ...styles,
            fontSize: "13px",
            fontWeight: "bold",
            color: props.mode === "white" ? coloriVetrina.white : coloriVetrina.blue,
          };
        },
        multiValueRemove: (styles) => {
          return {
            ...styles,
            borderRadius: 10,
          };
        },
      }}
    />
  );
}
export default JobErAsyncSelect;
