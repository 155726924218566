import React from "react";
import Chip from "@mui/material/Chip";
import LanguageIcon from "@mui/icons-material/Language";
import { Grid, Avatar } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";

function ChipsLingua(props) {
  return props.lingua ? (
    <Grid item xs="auto">
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: coloriVetrina.blue }}>
            <LanguageIcon
              sx={{ fontSize: "18px", color: coloriVetrina.white }}
            />
          </Avatar>
        }
        label={props.lingua}
        sx={{
          backgroundColor: coloriVetrina.bgActiveMenu,
          "& .MuiChip-label": {
            fontSize: "14px",
          },
          "& .MuiChip-deleteIcon": {
            color: coloriVetrina.error,
            fontSize: "14px",
          },
          textTransform: "capitalize",
        }}
      />
    </Grid>
  ) : null;
}
export default ChipsLingua;
