import React, { useEffect } from "react";
import MenuJOB from "../../components/Vetrina/AppBarJob.js";
import FooterBB from "../../components/Vetrina/Footer.js";
import "../../css/App.css";
import "../../css/JobEr.css";
import "../../css/JobMORE.css";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import coloriVetrina from "../../json/coloriVetrina.json";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import PresentazioneCandidato from "components/Vetrina/Home/PresentazioneCandidato.js";
import PresentazioneAzienda from "components/Vetrina/Home/PresentazioneAzienda.js";
import Contattaci from "components/Vetrina/Home/Contattaci.js";
import FactoryIcon from "@mui/icons-material/Factory";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Slider from "react-slick";
import SliderWrapper from "../../components/Vetrina/PerLePersone/_SlickSliderStyle.js";
import { Toast } from "components/Toast";

function Home(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = [];
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    if (params.tipo && params.token && params.tipo === "azienda") {
      fetch(process.env.REACT_APP_URL + "/user/confermaRegistrazioneAzienda/" + params.token, {
        method: "GET",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              Toast.fire({ icon: "success", title: "Utente attivato con successo!" });
            } else {
              Toast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
            }
          },
          (error) => {
            Toast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
          }
        );
    } else if (params.tipo && params.token && params.tipo === "persona") {
      fetch(process.env.REACT_APP_URL + "/user/confermaRegistrazionePersona/" + params.token, {
        method: "GET",
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              Toast.fire({ icon: "success", title: "Utente attivato con successo!" });
            } else {
              Toast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
            }
          },
          (error) => {
            Toast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
          }
        );
    }
  }, []);

  return (
    <>
      <MenuJOB bgcolor={coloriVetrina.blue} color={coloriVetrina.white} activePage={"Home"} onUserLogin={(user) => props.onUserLogin(user)}></MenuJOB>
      <Grid container backgroundColor={coloriVetrina.blue} sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 2, md: 8 } }}>
        <Grid item xs={12}>
          <SliderWrapper>
            <Slider
              dots={true}
              fade={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              waitForAnimate={true}
              arrows={false}
              swipe={true}
              autoplay={true}
              autoplaySpeed={2500}
              centerMode={true}
              appendDots={(dots) => <ul>{dots}</ul>}
              customPaging={() => (
                <div className="ft-slick__dots--custom">
                  <div className="loading" />
                </div>
              )}
            >
              <div key={"presentazione_candidato"}>
                <PresentazioneCandidato />
              </div>
              <div key={"presentazione_azienda"}>
                <PresentazioneAzienda />
              </div>
            </Slider>
          </SliderWrapper>
        </Grid>
      </Grid>
      {/* Inizio seconda parte della pagina */}
      <Grid container spacing={0} sx={{ justifyContent: "center", pb: { xs: 4, md: 8 }, pt: { xs: 4, md: 8 } }} backgroundColor={coloriVetrina.blue}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            p: 4,
            borderRight: { md: "solid .2vh #efefef", xs: "none" },
            textAlign: { md: "center" },
          }}
        >
          <Diversity3Icon style={{ color: "white", fontSize: 120 }} />
          <Typography component="p" sx={{ color: "#efefef", fontSize: "4vh !important" }}>
            <b>Per i candidati</b>
          </Typography>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <Typography
              component="p"
              sx={{
                paddingTop: "10px",
                color: "#efefef",
                fontSize: "18px!important",
                fontWeight: "regular",
              }}
            >
              Trova il lavoro su misura per te. Su MyLapam Job potrai consultare gli annunci delle aziende del tuo territorio.
            </Typography>
          ) : (
            <Typography
              component="p"
              sx={{
                paddingTop: "10px",
                color: "#efefef",
                fontSize: "18px!important",
                fontWeight: "regular",
              }}
            >
              Trova il lavoro su misura per te. Su Job Confartigianato potrai consultare gli annunci delle aziende del tuo territorio.
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            mt: { xs: 3, md: 0 },
            mb: { xs: 3, md: 0 },
            ml: { xs: 0, md: 3 },
            mr: { xs: 0, md: 3 },
            p: 4,
            textAlign: { md: "center" },
          }}
        >
          <FactoryIcon style={{ color: "white", fontSize: 120 }} />
          <Typography component="p" sx={{ color: "#efefef", fontSize: "4vh !important" }}>
            <b>Per Le Aziende</b>
          </Typography>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <Typography
              component="p"
              sx={{
                paddingTop: "10px",
                color: "#efefef",
                fontSize: "18px!important",
                fontWeight: "regular",
              }}
            >
              Potenzia la tua azienda con i migliori talenti.<br></br>
              Su My Lapam Job potrai amministrare le tue ricerche di personale.
            </Typography>
          ) : (
            <Typography
              component="p"
              sx={{
                paddingTop: "10px",
                color: "#efefef",
                fontSize: "18px!important",
                fontWeight: "regular",
              }}
            >
              Potenzia la tua azienda con i migliori talenti.<br></br>
              Su Job Confartigianato potrai amministrare le tue ricerche di personale.
            </Typography>
          )}
        </Grid>
      </Grid>
      <Contattaci />
      <FooterBB color1={coloriVetrina.lightGray} color2="#19405A" color3="white" />
    </>
  );
}

export default Home;
