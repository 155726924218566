import React, { useEffect, useState } from "react";
import { Grid, List, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import ItemListaAnnunciMore from "components/Vetrina/PerLePersone/ItemListaAnnunciMore";
import JobButton from "components/JobButton";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaRuoli, asyncRicercaComuni2 } from "utils/utilsRicerca";
import JobErSelect from "components/JobErSelect";
import { cercaAnnunciVetrina } from "./utils/UtilsVetrina";

function ItemMappaJobMORE(props) {
  const [annunci, setAnnunci] = useState([]);
  const [formData, setFormData] = useState({ provincia: null, comune: null, professione: null });
  const [pendingFilter, setPendingFilter] = useState(false);
  const [messaggio, setMessaggio] = useState("");

  const listProv = [
    { label: "Modena", value: "MO" },
    { label: "Reggio Emilia", value: "RE" },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          color={coloriVetrina.white}
          sx={{
            textAlign: "center!important",
            fontWeight: "bold",
            fontSize: "4vh !important",
          }}
        >
          Che lavoro stai cercando?
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <JobErAsyncSelect
          menuPosition="fixed"
          isClearable="true"
          placeholder={"Ricerca Professione"}
          value={formData.professione}
          loadOptions={asyncRicercaRuoli}
          noOptionsMessage={() => "Nessuna professione trovata"}
          onChange={(data) => {
            setFormData({ ...formData, professione: data });
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <JobErSelect
          menuPosition="fixed"
          isClearable="true"
          placeholder={"Ricerca Provincia"}
          onChange={(data) => setFormData({ ...formData, provincia: data, comune: null })}
          value={formData.provincia}
          options={listProv}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <JobErAsyncSelect
          menuPosition="fixed"
          isClearable="true"
          placeholder={"Ricerca Comune"}
          value={formData.comune}
          loadOptions={(inputValue) => {
            return asyncRicercaComuni2(inputValue, formData.provincia.value);
          }}
          noOptionsMessage={() => "Nessun comune trovato"}
          onChange={(data) => {
            setFormData({ ...formData, comune: data });
          }}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <JobButton
          fontSize={"16px"}
          label="Cerca"
          colorBackground={coloriVetrina.white}
          colorBorder={coloriVetrina.blue}
          colorLabel={coloriVetrina.blue}
          loading={pendingFilter}
          onClick={() => {
            cercaAnnunciVetrina(formData, setAnnunci, setPendingFilter, setMessaggio);
          }}
        />
      </Grid>
      <Grid
        container
        backgroundColor={coloriVetrina.blue}
        sx={{
          display: { md: "flex" },
          pl: { xs: 2, md: 15 },
          pr: { xs: 2, md: 15 },

          pt: { xs: 4, md: 8 },
        }}
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {annunci.length > 0 ? (
            <Grid item xs={12}>
              <List
                sx={{
                  width: "100%",
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                {annunci.map((annuncio, index) => (
                  <React.Fragment key={index}>
                    <ItemListaAnnunciMore annuncio={annuncio} />
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          ) : (
            <Typography
              variant="p"
              color={coloriVetrina.white}
              sx={{
                textAlign: "center!important",
              }}
            >
              {messaggio}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default ItemMappaJobMORE;
