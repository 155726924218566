import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography, Radio, FormControl, RadioGroup, FormControlLabel } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobErInput from "components/JobErInput";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import JobButton from "components/JobButton";
import {
  completaRegistrazioneAzienda,
  verificaCampiRegistrazione,
  verificaAziendaUtenteMailRegistrazione,
  verificaPasswordRegistrazione,
  verificaAziendaUtentePivaRegistrazione,
} from "utils/utilsLogin";
import { asyncRicercaComuni, asyncRicercaSettore, ricercaConfartigiananto } from "utils/utilsRicerca";
import JobErSelect from "components/JobErSelect";

function RegistrazioneAzienda(props) {
  const [formData, setFormData] = useState({});
  const [dataError, setDataError] = useState([]);
  const [verificaPassword, setVerificaPassword] = useState(null);
  const [verificaEmail, setVerificaEmail] = useState(null);
  const [verificaPiva, setVerificaPiva] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listaConfartigianato, setListaConfartigianato] = useState([]);

  useEffect(() => {
    ricercaConfartigiananto(setListaConfartigianato);
  }, []);

  return (
    <>
      <Grid container justifyContent="center" mt={2}>
        <Grid item xs={6}>
          <Divider sx={{ borderColor: "#19405A" }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} mt={3}>
          <Typography variant="h7" color={coloriVetrina.white} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati utente
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Email"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("email") || (verificaEmail !== "ok" && verificaEmail) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            onBlur={(e) => verificaAziendaUtenteMailRegistrazione(e.target.value, setVerificaEmail)}
            helperText={verificaEmail === "forbidden" ? "Email già utilizzata" : verificaEmail === "invalid" ? "Email non valida" : "Inserisci la tua email"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Password"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("password") || (verificaPassword !== "ok" && verificaPassword) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText={verificaPassword !== "ok" && verificaPassword ? verificaPassword : "Inserisci la password"}
            value={formData.password || ""}
            type="password"
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            onBlur={(e) => verificaPasswordRegistrazione(e.target.value, setVerificaPassword)}
            helperTextError={verificaPassword !== "ok" && verificaPassword}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Ripeti Password"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("password") ? coloriVetrina.error : coloriVetrina.gray}
            type="password"
            size="small"
            helperText="Inserisci nuovamente la password"
            value={formData.ripetiPassword || ""}
            onChange={(e) => setFormData({ ...formData, ripetiPassword: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} mt={3}>
          <Typography variant="h7" color={coloriVetrina.white} sx={{ textAlign: "center", fontWeight: "bold" }}>
            Dati azienda
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Ragione Sociale"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("ragioneSociale") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci la ragione sociale"
            value={formData.ragioneSociale || ""}
            onChange={(e) => setFormData({ ...formData, ragioneSociale: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Partita IVA"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("partitaIva") || (verificaPiva !== "ok" && verificaPiva) ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            value={formData.partitaIva || ""}
            onChange={(e) => setFormData({ ...formData, partitaIva: e.target.value })}
            helperText={verificaPiva === "forbidden" ? "Piva già utilizzata" : verificaPiva === "invalid" ? "Piva non valida" : "Inserisci la tua Piva"}
            onBlur={(e) => verificaAziendaUtentePivaRegistrazione(e.target.value, setVerificaPiva)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobErInput
            label="Codice Fiscale"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("codiceFiscale") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci il tuo codice fiscale"
            value={formData.codiceFiscale || ""}
            onChange={(e) => setFormData({ ...formData, codiceFiscale: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid item xs={12} md={3}>
          <JobErAsyncSelect
            menuPosition="fixed"
            isClearable="true"
            placeholder={"Ricerca Comune"}
            value={formData.comune}
            loadOptions={asyncRicercaComuni}
            noOptionsMessage={() => "Nessun comune trovato"}
            error={dataError.includes("comune")}
            onChange={(data) => {
              setFormData({ ...formData, comune: data });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <JobErInput
            label="Indirizzo"
            coloreTesto={coloriVetrina.white}
            coloreBordo={dataError.includes("indirizzo") ? coloriVetrina.error : coloriVetrina.gray}
            size="small"
            helperText="Inserisci l'indirizzo"
            value={formData.indirizzo || ""}
            onChange={(e) => setFormData({ ...formData, indirizzo: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid item xs={12} md={3}>
          <JobErAsyncSelect
            menuPosition="fixed"
            isClearable="true"
            placeholder={"Ricerca Settore"}
            value={formData.settore}
            loadOptions={asyncRicercaSettore}
            noOptionsMessage={() => "Nessun settore trovato"}
            error={dataError.includes("settore")}
            onChange={(data) => {
              setFormData({ ...formData, settore: data });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color={coloriVetrina.white}
                sx={{
                  textAlign: "center!important",
                  fontSize: "16px",
                }}
              >
                Sei già associato ad una confartigianato?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup row onChange={(e) => setFormData({ ...formData, associato: e.target.value })}>
                  <FormControlLabel
                    value="si"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: dataError.includes("associato") ? coloriVetrina.error : coloriVetrina.green,
                          },
                          "&": {
                            color: dataError.includes("associato") ? coloriVetrina.error : coloriVetrina.white,
                          },
                        }}
                      />
                    }
                    label="Sì"
                    style={{ color: coloriVetrina.white }}
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: dataError.includes("associato") ? coloriVetrina.error : coloriVetrina.green,
                          },
                          "&": {
                            color: dataError.includes("associato") ? coloriVetrina.error : coloriVetrina.white,
                          },
                        }}
                      />
                    }
                    label="No"
                    style={{ color: coloriVetrina.white }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {formData.associato === "si" && (
          <Grid item xs={12} md={3}>
            <JobErSelect
              name="confartigianato"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Ricerca Confartigianato *"}
              onChange={(data) => {
                setFormData({ ...formData, provConfartigianato: data });
              }}
              error={dataError.includes("provConfartigianato")}
              value={formData.provConfartigianato}
              options={listaConfartigianato}
            />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" mt={1} spacing={1}>
        <Grid item xs="auto">
          <JobButton
            loading={loading}
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            fontSize="16px"
            label={"Completa Registrazione"}
            onClick={() => {
              if (verificaCampiRegistrazione("azienda", formData, setDataError)) {
                if (verificaEmail === "ok" && verificaPiva === "ok") {
                  completaRegistrazioneAzienda(formData, props.onRegistrazioneCompletata, setLoading);
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default RegistrazioneAzienda;
