import React, { useEffect, useState } from "react";
import coloriVetrina from "../../json/coloriVetrina.json";
import FooterBB from "components/Vetrina/Footer";
import { Grid } from "@mui/material";
import RichiestaInformazioni from "components/Vetrina/PerLeAziende/RichiestaInformazioni";
import PngAzienda from "../../immagini/vetrina_aziende.png";
import AppBarJob from "components/Vetrina/AppBarJob";
import MainSlideJobMORE from "components/Vetrina/PerLeAziende/MainSlideJobMORE";
import MainSlideJobER from "components/Vetrina/PerLeAziende/MainSlideJobER";

function Aziende(props) {
  const [top3Interessi, setTop3Interessi] = useState(null);

  /*useEffect(() => {
    fetch(process.env.REACT_APP_URL + "/vetrina/elencoLavoriInteresse", {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTop3Interessi(result);
        },
        (error) => {
          console.error("Si è verificato un errore nel caricamento");
        }
      );
  }, []);*/

  return (
    <>
      <AppBarJob bgcolor={coloriVetrina.blue} color={coloriVetrina.white} activePage={"Aziende"} onUserLogin={(user) => props.onUserLogin(user)}></AppBarJob>
      <Grid container backgroundColor={coloriVetrina.blue} sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, display: { md: "flex" } }}>
        <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" }, justifyContent: "center" }}>
          <img
            src={PngAzienda}
            style={{
              height: "65vh",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" }, justifyContent: "center" }}>
          <img
            src={PngAzienda}
            style={{
              height: "43vh",
            }}
          />
        </Grid>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? <MainSlideJobMORE /> : <MainSlideJobER />}
      </Grid>
      {/*       <Grid
        container
        spacing={0}
        sx={{ justifyContent: "center" }}
        backgroundColor={coloriVetrina.blue}
        pb={8}
        pt={8}
      >
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h2"
            color={coloriVetrina.white}
            sx={{
              textAlign: "center!important",
              fontSize: "45px!important",
              fontWeight: "bold!important",
            }}
          >
            I lavori più ricercati nel nostro territorio
          </Typography>
        </Grid>
        {top3Interessi
          ? top3Interessi.map((row, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={11}
                  md={3}
                  alignSelf="end"
                  sx={{
                    p: 4,
                    borderRight: {
                      md:
                        index === 0 && top3Interessi.length === 3
                          ? "solid .2vh #efefef"
                          : "none",
                      xs: "none",
                    },
                    borderLeft: {
                      md:
                        index === 2 && top3Interessi.length === 3
                          ? "solid .2vh #efefef"
                          : "none",
                      xs: "none",
                    },
                    textAlign: { md: "center" },
                  }}
                >
                  {index === 1 ? (
                    <StarIcon
                      sx={{ color: coloriVetrina.warning, fontSize: 70 }}
                    />
                  ) : null}
                  <Typography
                    component="p"
                    sx={{ color: "#efefef", fontSize: "3.5vh !important" }}
                  >
                    {row.interesse}
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{
                      color: "#efefef",
                      fontSize: "50px!important",
                      fontWeight: "regular",
                    }}
                  >
                    {index === 0 ? 2 : index === 1 ? 1 : 3}
                  </Typography>
                </Grid>
              );
            })
          : null}
      </Grid> */}
      <Grid
        container
        backgroundColor={coloriVetrina.blue}
        style={{ paddingBottom: 50 }}
        sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: 4, md: 8 }, pt: { xs: 4, md: 8 } }}
      >
        <RichiestaInformazioni />
      </Grid>
      <FooterBB color1={coloriVetrina.lightGray} color2="#19405A" color3="white" />
    </>
  );
}
export default Aziende;
