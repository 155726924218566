import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import Slider from "react-slick";
import SliderWrapper from "./_SlickSliderStyle";
import { useNavigate } from "react-router-dom";
import CardAnnunciPersone from "./CardAnnunciPersone";
import JobButton from "components/JobButton";
import CircularProgress from "@mui/material/CircularProgress";
import { ModalContext } from "context/modalLogin";

function PresentazioneServizio(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <Grid container spacing={2} backgroundColor={coloriVetrina.blue}>
      <Grid item xs={12} md={6}>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
                  Cerchi lavoro? Non perdere tempo, affidati a MyLapam Job!
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                  Mylapam job mette a disposizione per i candidati in forma totalmente gratuita, il proprio servizio, la propria professionalità e il proprio
                  supporto affinché si possa andare insieme con fiducia verso una nuova sfida e un nuovo inizio.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                  Nuove esperienze professionali, nuovi inserimenti e nuove crescite: gli annunci di lavoro pubblicati sulla nostra piattaforma sono rivolti sia
                  a chi cerca un primo lavoro sia a chi cerca un nuovo lavoro.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh", fontSize: "18px" }}>
                  L’inserimento ti permetterà di essere:
                  <ul>
                    <li>contattato dai nostri consulenti MyLapam Job;</li>
                    <li>proposto per tutte le ricerche di personale che risultino compatibili con i propri dati personali e professionali;</li>
                    <li>
                      convocato per un colloquio conoscitivo presso la nostra sede di Modena o attraverso un video colloquio nel caso tu non abbia la
                      possibilità di raggiungerci.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
                  Cerchi lavoro? Non perdere tempo, affidati a Job Confartigianato ER!
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                  Job Confartigianato mette a disposizione per i candidati in forma totalmente gratuita, il proprio servizio, la propria professionalità e il
                  proprio supporto affinché si possa andare insieme con fiducia verso una nuova sfida e un nuovo inizio.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                  Nuove esperienze professionali, nuovi inserimenti e nuove crescite: gli annunci di lavoro pubblicati sulla nostra piattaforma sono rivolti sia
                  a chi cerca un primo lavoro sia a chi cerca un nuovo lavoro.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh", fontSize: "18px" }}>
                  L’inserimento ti permetterà di essere:
                  <ul>
                    <li>contattato dai nostri consulenti Job Confartigianato;</li>
                    <li>proposto per tutte le ricerche di personale che risultino compatibili con i propri dati personali e professionali;</li>
                    <li>
                      convocato per un colloquio conoscitivo presso la nostra sede di Modena o attraverso un video colloquio nel caso tu non abbia la
                      possibilità di raggiungerci.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container mt={1} spacing={2}>
          <Grid item xs="auto">
            <JobButton
              colorBackground={coloriVetrina.blue}
              colorLabel={coloriVetrina.white}
              colorBorder={coloriVetrina.white}
              size="large"
              label="Accedi"
              onClick={() => {
                setIsModalOpen(true);
              }}
              fontSize="18px"
            />
          </Grid>
          <Grid item xs="auto">
            <JobButton
              colorBackground={coloriVetrina.white}
              colorLabel={coloriVetrina.blue}
              colorBorder={coloriVetrina.white}
              size="large"
              label="Registrati"
              onClick={() => navigate("/Vetrina/Registrati")}
              fontSize="18px"
            />
          </Grid>
        </Grid>
      </Grid>
      {props.loading ? (
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          <SliderWrapper>
            <Slider
              dots={true}
              fade={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              waitForAnimate={true}
              arrows={false}
              swipe={true}
              autoplay={true}
              autoplaySpeed={2500}
              centerMode={true}
              appendDots={(dots) => <ul>{dots}</ul>}
              customPaging={(i) => (
                <div className="ft-slick__dots--custom">
                  <div className="loading" />
                </div>
              )}
            >
              {props.annunci && props.annunci.length > 0
                ? props.annunci.map((annuncio, index) => {
                    if (index % 2 == 0) {
                      var prossimoAnnuncio = props.annunci[index + 1];
                      return (
                        <div key={annuncio + "_" + index}>
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12} md={6} style={{ padding: { md: 20 }, paddingBottom: 0 }}>
                              <CardAnnunciPersone
                                gruppoLavoro={annuncio?.posizioneLavorativa?.gruppo}
                                mansione={annuncio?.posizioneLavorativa?.descrizione}
                                titolo={annuncio?.titoloAnnuncio}
                                descrizioneLavoro={annuncio?.descrizioneLavoro}
                                comune={annuncio?.comune?.des ? annuncio.comune.des : null}
                                posizioniAperte={annuncio?.posizioniAperte}
                              />
                            </Grid>
                            {prossimoAnnuncio ? (
                              <Grid item xs={12} md={6} style={{ padding: { md: 20 }, paddingBottom: 0 }}>
                                <CardAnnunciPersone
                                  gruppoLavoro={prossimoAnnuncio?.posizioneLavorativa?.gruppo}
                                  mansione={prossimoAnnuncio?.posizioneLavorativa?.descrizione}
                                  titolo={prossimoAnnuncio?.titoloAnnuncio}
                                  descrizioneLavoro={prossimoAnnuncio?.descrizioneLavoro}
                                  comune={prossimoAnnuncio?.comune ? (prossimoAnnuncio?.comune?.des ? prossimoAnnuncio.comune.des : null) : null}
                                  posizioniAperte={prossimoAnnuncio?.posizioniAperte}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </Slider>
          </SliderWrapper>
        </Grid>
      )}
    </Grid>
  );
}
export default PresentazioneServizio;
