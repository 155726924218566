import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import JobButton from "components/JobButton";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "context/modalLogin";

function MainSlideJobER(props) {
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
            Stai cercando i migliori talenti per la tua azienda?
          </Typography>
        </Grid>
      </Grid>
      {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                MyLapam Job mette a disposizione per le aziende associate e non associate questo servizio di ricerca e selezione del personale.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh", fontSize: "18px" }}>
                Le fasi del nostro lavoro:
                <ul>
                  <li>analisi dell'esigenza del cliente;</li>
                  <li>elaborazione della job description condivisa con il cliente;</li>
                  <li>attività accurata di ricerca e selezione:</li>
                  <li>attivazione dei canali di recruiting;</li>
                  <li>individuazione delle candidature idonee;</li>
                  <li>colloqui individuali con i nostri esperti;</li>
                  <li>presentazione delle candidature al cliente finale;</li>
                  <li>reporting sui risultati ottenuti;</li>
                  <li>supporto al cliente nella fase di colloquio in presenza del candidat*;</li>
                  <li>periodo di valutazione del candidat* e del suo percorso in azienda;</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh", fontSize: "18px" }}>
                Job Confartigianato mette a disposizione per le aziende associate e non associate questo servizio di ricerca e selezione del personale.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh", fontSize: "18px" }}>
                Le fasi del nostro lavoro:
                <ul>
                  <li>analisi dell'esigenza del cliente;</li>
                  <li>elaborazione della job description condivisa con il cliente;</li>
                  <li>attività accurata di ricerca e selezione:</li>
                  <li>attivazione dei canali di recruiting;</li>
                  <li>individuazione delle candidature idonee;</li>
                  <li>colloqui individuali con i nostri esperti;</li>
                  <li>presentazione delle candidature al cliente finale;</li>
                  <li>reporting sui risultati ottenuti;</li>
                  <li>supporto al cliente nella fase di colloquio in presenza del candidat*;</li>
                  <li>periodo di valutazione del candidat* e del suo percorso in azienda;</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container mt={1} spacing={2}>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            label="Accedi"
            onClick={() => {
              setIsModalOpen(true);
            }}
            fontSize="18px"
          />
        </Grid>
        <Grid item xs="auto">
          <JobButton
            colorBackground={coloriVetrina.white}
            colorLabel={coloriVetrina.blue}
            colorBorder={coloriVetrina.white}
            size="large"
            label="Registrati"
            onClick={() => navigate("/Vetrina/Registrati")}
            fontSize="18px"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default MainSlideJobER;
