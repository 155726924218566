import React from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import { asyncRicercaProgrammi } from "utils/utilsRicerca";

function AnnuncioStep4(props) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left">
          <Grid item xs={12} md={3}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Richiesto titolo studio?"}
              value={
                props.dati && props.dati.richiestoTitolo && typeof props.dati.richiestoTitolo === "object"
                  ? props.dati.richiestoTitolo
                  : props.dati && props.dati.titoloStudio
                  ? {
                      value: "si",
                      label: "Sì",
                    }
                  : null
              }
              onChange={(data) => props.onSetDati("richiestoTitolo", data)}
              options={options.yes_no}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Livello titolo"}
              onChange={(data) => props.onSetDati("titoloStudio", data ? data.value : null)}
              value={
                props.dati && props.dati.titoloStudio
                  ? options.titoli_studio.filter((opt) => opt.label === props.dati.titoloStudio || opt.value === props.dati.titoloStudio)
                  : null
              }
              options={options.titoli_studio}
              disabled={
                props.dati && props.dati.richiestoTitolo && props.dati.richiestoTitolo.value === "no" ? true : !props.dati.richiestoTitolo ? true : false
              }
            />
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Specifica"}
              onChange={(data) => props.onSetDati("specifica", data ? data.value : null)}
              value={
                props.dati && props.dati.specifica
                  ? options.corso_frequentato.filter((opt) => opt.label === props.dati.specifica || opt.value === props.dati.specifica)
                  : null
              }
              options={options.corso_frequentato}
              disabled={
                props.dati && props.dati.richiestoTitolo && props.dati.richiestoTitolo.value === "no" ? true : !props.dati.richiestoTitolo ? true : false
              }
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12} md={4}>
            <Typography
              component="p"
              sx={{
                fontSize: "16px!important",
                textAlign: "center",
                color: coloriVetrina.blue,
                fontWeight: "bold!important",
              }}
            >
              SOFTWARE
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12} md={6}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={true}
              isClearable="true"
              placeholder={"Programmi"}
              loadOptions={asyncRicercaProgrammi}
              noOptionsMessage={() => "Nessun programma trovato"}
              onChange={(data) => props.onSetDati("programmiRichiesti", data)}
              value={props.dati && props.dati.programmiRichiesti ? props.dati.programmiRichiesti : null}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep4;
