import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import LayoutAziende from "components/PortaleAziende/LayoutAziende";
import { useJobErController } from "context";
import GestionePresentazione from "components/PortaleAziende/GestionePresentazione";
import GestioneSede from "components/PortaleAziende/GestioneSede";
import { getHomePresentazioneAzienda, getSediAzienda } from "./utils/utilsHomepage";
import { savePresentazione } from "./utils/utils_profilo_aziende";

function ProfiloAziende(props) {
  const [presentazione, setPresentazione] = useState(null);
  const [sedi, setSedi] = useState([]);
  const [nuovaSede, setNuovaSede] = useState({})

  const [controller] = useJobErController();
  const { authUser } = controller;

  useEffect(() => {
    if (authUser) {
      getHomePresentazioneAzienda(localStorage.getItem("partitaIva")).then((res) =>
        setPresentazione(res)
      );
      getSediAzienda(localStorage.getItem("partitaIva")).then((res) =>
        setSedi(res)
      );
    }
  }, [authUser]);

  return (
    <LayoutAziende
      child={
          <Grid
            container
            spacing={2}
            sx={{
              padding: { xs: 2, md: 0 },
              backgroundColor: "#002b48!important",
              minHeight: "100vh",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: { xs: 2, md: 0 },
                  backgroundColor: "#002b48!important",
                }}
              >
                <Grid item xs={12} md={6}>
                  <GestionePresentazione
                    presentazione={presentazione}
                    onSetData={(key, val) => {
                      setPresentazione({ ...presentazione, [key]: val });
                    }}
                    onSave={() => {
                      savePresentazione(presentazione, setPresentazione, authUser)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GestioneSede sedi={sedi} setSedi={setSedi} nuovaSede={nuovaSede} setNuovaSede={setNuovaSede} authUser={authUser}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      }
    />
  );
}
export default ProfiloAziende;
