import React from "react";
import { Grid } from "@mui/material";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import { convertOrariLavoroToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";

function AnnuncioStep3(props) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          pr={2}
          spacing={2}
          textAlign="left"
        >
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Trasferte temporanee"}
              onChange={(data) =>
                props.onSetDati("trasferteTemporanee", data ? data.value : null)
              }
              value={
                props.dati && props.dati.trasferteTemporanee
                  ? options.yes_no.filter(
                      (opt) =>
                        opt.label === props.dati.trasferteTemporanee ||
                        opt.value === props.dati.trasferteTemporanee
                    )
                  : null
              }
              options={options.yes_no}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Trasferte definitive"}
              onChange={(data) =>
                props.onSetDati("trasferteDefinitive", data ? data.value : null)
              }
              value={
                props.dati && props.dati.trasferteDefinitive
                  ? options.yes_no.filter(
                      (opt) =>
                        opt.label === props.dati.trasferteDefinitive ||
                        opt.value === props.dati.trasferteDefinitive
                    )
                  : null
              }
              options={options.yes_no}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          pr={2}
          spacing={2}
          textAlign="left"
          mt={0}
        >
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Automunito"}
              onChange={(data) =>
                props.onSetDati("automunito", data ? data.value : null)
              }
              value={
                props.dati && props.dati.automunito
                  ? options.yes_no.filter(
                      (opt) =>
                        opt.label === props.dati.automunito ||
                        opt.value === props.dati.automunito
                    )
                  : null
              }
              options={options.yes_no}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              isMulti={true}
              placeholder={"Orari lavoro"}
              onChange={(data) => props.onSetDati("orariLavoro", data)}
              value={
                props.dati &&
                props.dati.orariLavoro &&
                typeof props.dati.orariLavoro === "object"
                  ? props.dati.orariLavoro
                  : props.dati && typeof props.dati.orariLavoro === "string"
                  ? convertOrariLavoroToOptSelect(props.dati.orariLavoro)
                  : null
              }
              options={options.turni_lavoro}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep3;
