import React, { useEffect, useState } from "react";
import JobErCard from "components/JobErCard";
import { ReactComponent as BbLong } from "../../svgicon/er_italy_ppersone.svg";
//import { ReactComponent as BbLongMore } from "../../svgicon/MORE_pmappa.svg";
import { Grid, Typography, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaProvince } from "utils/utilsRicerca";
import { getAziendeHomepage } from "components/PortalePersone/utils/utils_homepage";
import CardAziendeHomepageDettaglio from "./CardAziendeHomepageDettaglio";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import JobButton from "components/JobButton";
import coloriVetrina from "json/coloriVetrina.json";

function CardAziendeHomepage(props) {
  const [provinciaSelezionata, setProvinciaSelezionata] = useState(null);
  const [aziendeTerritorio, setAziendeTerritorio] = useState([]);
  const [open, setOpen] = useState(false);
  const [aziendaSelezionata, setAziendaSelezionata] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    if (provinciaSelezionata) {
      setAziendeTerritorio([]);
      getAziendeHomepage(provinciaSelezionata, setAziendeTerritorio, setLoading, page, setPagination);
    }
  }, [provinciaSelezionata, page]);

  return (
    <JobErCard
      title="Le nostre aziende"
      style={{ minHeight: "300px" }}
      child={
        <Grid container mt={2} mb={2}>
          <Grid item xs={12} mt={2} mb={2} justifyContent="center">
            {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
              <>
                <JobButton
                  label="Modena e Provincia"
                  colorBackground={provinciaSelezionata?.value === "MO" ? coloriVetrina.blue : coloriVetrina.white}
                  colorLabel={provinciaSelezionata?.value === "MO" ? coloriVetrina.white : coloriVetrina.blue}
                  colorBorder={provinciaSelezionata?.value === "MO" ? coloriVetrina.white : coloriVetrina.blue}
                  onClick={() => {
                    const newProvincia = {
                      label: "Modena e Provincia",
                      value: "MO",
                      idmap: "MO",
                    };

                    setProvinciaSelezionata(newProvincia);
                  }}
                ></JobButton>
                <JobButton
                  label="Reggio Emilia e Provincia"
                  colorBackground={provinciaSelezionata?.value === "RE" ? coloriVetrina.blue : coloriVetrina.white}
                  colorLabel={provinciaSelezionata?.value === "RE" ? coloriVetrina.white : coloriVetrina.blue}
                  colorBorder={provinciaSelezionata?.value === "RE" ? coloriVetrina.white : coloriVetrina.blue}
                  onClick={() => {
                    const newProvincia = {
                      label: "Reggio Emilia e Provincia",
                      value: "RE",
                      idmap: "RE",
                    };

                    setProvinciaSelezionata(newProvincia);
                  }}
                ></JobButton>
              </>
            ) : (
              <BbLong
                className="er_svgmap"
                onClick={(e) => {
                  if (provinciaSelezionata) {
                    const previousElement = document.querySelector(`.selectedprov_${provinciaSelezionata.idmap}`);
                    if (previousElement) {
                      previousElement.classList.remove(`selectedprov_${provinciaSelezionata.idmap}`);
                    }
                  }

                  // Extract province identifier from the clicked element's ID
                  const strProvincia = e.target.id.replace(/[0-9]/g, "");

                  // Set the new selected province details
                  const newProvincia = {
                    label: `${e.target.getAttribute("descrizione").toUpperCase()} ( ${e.target.getAttribute("codice")} )`,
                    value: e.target.getAttribute("codice"),
                    idmap: strProvincia,
                  };
                  setProvinciaSelezionata(newProvincia);

                  // Add selected class to the clicked element
                  e.target.classList.add(`selectedprov_${strProvincia}`);
                }}
                style={{ width: "100%" }}
                sx={{
                  width: { xs: "90.4365mm!important", md: "100mm!important" },
                }}
              ></BbLong>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container justifyContent="center">
              {process.env.REACT_APP_AMBIENTE !== "modena-reggio" && (
                <Grid item xs={12} md={8}>
                  <JobErAsyncSelect
                    mode="white"
                    menuPosition="fixed"
                    isClearable="true"
                    placeholder={"Ricerca Provincia"}
                    value={provinciaSelezionata}
                    loadOptions={asyncRicercaProvince}
                    noOptionsMessage={() => "Nessuna provincia trovata"}
                    onChange={(data) => {
                      setProvinciaSelezionata(data);
                    }}
                  />
                </Grid>
              )}
              {aziendeTerritorio.length > 0 && loading === false && (
                <Grid item xs={12} align="center" mt={1}>
                  <Typography
                    variant="h6"
                    color={coloriVetrina.blue}
                    sx={{
                      textAlign: "center!important",
                      fontSize: "16px",
                    }}
                  >
                    Sono state trovate:{" "}
                    <b>
                      {pagination?.totalElements}
                      {pagination?.totalElements === 1 ? " azienda" : " aziende"}
                    </b>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                {aziendeTerritorio.length > 0 && loading === false ? (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {aziendeTerritorio.map((azienda, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            alignItems="flex-start"
                            key={azienda.partitaIva + "_" + index}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAziendaSelezionata(azienda);
                              setOpen(true);
                            }}
                          >
                            {azienda?.miniatura ? (
                              <ListItemAvatar>
                                <Avatar src={azienda.miniatura} />
                              </ListItemAvatar>
                            ) : (
                              <ListItemAvatar>
                                <Avatar>{azienda?.ragioneSociale ? azienda?.ragioneSociale?.substring(0, 2).toUpperCase() : ""}</Avatar>
                              </ListItemAvatar>
                            )}

                            <ListItemText
                              primary={azienda?.ragioneSociale ? azienda?.ragioneSociale.toUpperCase() : ""}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                color: coloriVetrina.blue,
                              }}
                              secondary={
                                <Grid container>
                                  {/* <Grid item xs={12} mt={1}>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {azienda?.com?.des ? azienda?.com?.des : null}
                                  </Typography>
                                </Grid> */}
                                  {/* <Grid item xs={12}>
                                  <JobButton
                                    onClick={() =>

                                    }
                                    label={"Visualizza"}
                                  />
                                </Grid> */}
                                </Grid>
                              }
                            />
                          </ListItem>
                          {index < aziendeTerritorio?.length - 1 ? <Divider variant="inset" component="li" key={"divider_" + index} /> : null}
                        </React.Fragment>
                      ))}
                    </List>
                    <Grid container justifyContent="center" mt={2}>
                      <Pagination
                        count={pagination.totalPages}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "rgb(0, 43, 72) !Important",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "rgb(0, 43, 72) !Important",
                            color: "white !Important",
                          },
                        }}
                        variant="outlined"
                        defaultPage={page + 1}
                        onChange={(e, value) => {
                          setPage(value - 1);
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
                {loading === true && provinciaSelezionata !== null && aziendeTerritorio?.length === 0 && (
                  <Grid container justifyContent="center" mt={4}>
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <CardAziendeHomepageDettaglio open={open} azienda={aziendaSelezionata} setOpen={setOpen} />
        </Grid>
      }
    />
  );
}
export default CardAziendeHomepage;
