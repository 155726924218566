import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, List } from "@mui/material";
import JobErCard from "components/JobErCard";
import LayoutAziende from "components/PortaleAziende/LayoutAziende";
import { useJobErController } from "context";
import coloriVetrina from "../../json/coloriVetrina.json";
import { useSearchParams } from "react-router-dom";
import { getCandidatureAzienda } from "components/PortaleAziende/utils/utils_candidature";
import ViewCandidatura from "components/PortaleAziende/ViewCandidatura";

function CandidatureAziende(props) {
  const [openDettaglio, setOpenDettaglio] = useState(null);
  const [candidati, setCandidati] = useState([]);

  const [controller] = useJobErController();
  const { authUser } = controller;

  const [searchParams] = useSearchParams();
  const [uuidParam, setUuidParam] = useState(null);

  const articleRefs = useRef([]);

  const apriDettaglio = (uuid) => {
    if (uuid) {
      setOpenDettaglio(uuid);
    }
  };

  useEffect(() => {
    if (authUser) {
      searchParams.forEach((value, key) => {
        if (key === "candidatura") {
          setUuidParam(value);

          if (value) {
            apriDettaglio(value);
          }
        }
      });

      getCandidatureAzienda(setCandidati);

      const articleElement = articleRefs.current[uuidParam];
      if (articleElement) {
        articleElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [authUser]);

  return (
    <LayoutAziende
      child={
        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: 2, md: 0 },
            backgroundColor: "#002b48!important",
            minHeight: "100vh",
          }}
        >
          <Grid item xs={12}>
            <JobErCard
              title="Candidature"
              child={
                <Grid container mt={2}>
                  {/* <Grid item xs={12} md={6}>
                      <Grid container>
                       <Grid item xs={12}>
                          <Typography
                            
                            color={coloriVetrina.blue}
                            sx={{
                              textAlign: "left",
                              mt: "1vh",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            Candidati Sbloccati
                          </Typography>
                        </Grid> */}
                  <Grid item xs={12}>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "500px",
                        overflowY: "auto",
                      }}
                    >
                      {candidati && candidati.length > 0 ? (
                        candidati.map((candidatura) => (
                          <ViewCandidatura
                            key={candidatura.uuidCandidatura}
                            candidatura={candidatura}
                            candidati={candidati}
                            setOpenDettaglio={setOpenDettaglio}
                            openDettaglio={openDettaglio}
                            uuidParam={uuidParam}
                          />
                        ))
                      ) : (
                        <Grid item xs={12} mt={2} sx={{ minHeight: "100px" }} alignSelf="center">
                          <Grid item xs={12} textAlign="center">
                            <Typography fontSize="14px" fontWeight="bold" sx={{ color: coloriVetrina.blue }}>
                              Nessuna candidatura
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </List>
                  </Grid>
                </Grid>
                /* </Grid>
                  </Grid> */
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
}
export default CandidatureAziende;
