import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import coloriVetrina from "../json/coloriVetrina.json";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function JobErModal({ title, showCloseButton, onClose, modalBody, modalFooter, ...props }) {
  return (
    <Dialog {...props} PaperProps={{ sx: { borderRadius: "20px" } }}>
      {title ? (
        <DialogTitle>
          {title}
          {showCloseButton ? (
            <IconButton
              title="Chiudi"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: coloriVetrina.error,
                fontSize: "14px",
              }}
            >
              <CloseIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : null}

      <DialogContent>{modalBody}</DialogContent>
      <DialogActions>{modalFooter}</DialogActions>
    </Dialog>
  );
}

JobErModal.propTypes = {
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  modalBody: PropTypes.element,
  modalFooter: PropTypes.element,
};

export default JobErModal;
