import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";

function JobTable(props) {
  const table = useMaterialReactTable({
    localization: MRT_Localization_IT,
    columns: props.columns,
    data: props.data,
    muiTablePaperProps: ({ table }) => ({
      elevation: 3,
      sx: {
        borderRadius: 4,
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#19405a33',
        color: "#002b48"
      },
    },
  });

  return (
    <MaterialReactTable
      table={table}
    />
  );
}

export default JobTable;
