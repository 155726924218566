import React from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import { convertLinguaToOptSelect, convertLivelloLinguaToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";

function AnnuncioStep5(props) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left">
          <Grid item xs={12}>
            <Typography
              component="p"
              sx={{
                fontSize: "16px!important",
                textAlign: "center",
                color: coloriVetrina.blue,
                fontWeight: "bold!important",
              }}
            >
              LINGUA MADRE
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Lingua richiesta"}
              onChange={(data) => props.onSetDati("linguaPrincipale", data)}
              value={
                props.dati && props.dati.linguaPrincipale && typeof props.dati.linguaPrincipale === "object"
                  ? props.dati.linguaPrincipale
                  : props.dati && typeof props.dati.linguaPrincipale === "string"
                  ? convertLinguaToOptSelect(props.dati.linguaPrincipale)
                  : null
              }
              options={options.lingue}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12}>
            <Typography
              component="p"
              sx={{
                fontSize: "16px!important",
                textAlign: "center",
                color: coloriVetrina.blue,
                fontWeight: "bold!important",
              }}
            >
              SECONDA LINGUA
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Seconda lingua"}
              onChange={(data) => props.onSetDati("linguaSecondaria", data)}
              value={
                props.dati && props.dati.linguaSecondaria && typeof props.dati.linguaSecondaria === "object"
                  ? props.dati.linguaSecondaria
                  : props.dati && typeof props.dati.linguaSecondaria === "string"
                  ? convertLinguaToOptSelect(props.dati.linguaSecondaria)
                  : null
              }
              options={options.lingue}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Livello"}
              onChange={(data) => props.onSetDati("livelloLinguaSecondaria", data)}
              value={
                props.dati && props.dati.livelloLinguaSecondaria && typeof props.dati.livelloLinguaSecondaria === "object"
                  ? props.dati.livelloLinguaSecondaria
                  : props.dati && typeof props.dati.livelloLinguaSecondaria === "string"
                  ? convertLivelloLinguaToOptSelect(props.dati.livelloLinguaSecondaria)
                  : null
              }
              options={options.livelli_lingue}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep5;
