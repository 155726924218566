import React from "react";
import { Grid, Card, CardContent, Divider, List, ListSubheader, IconButton, Typography, Avatar } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactoryIcon from "@mui/icons-material/Factory";
import HomeIcon from "@mui/icons-material/Home";
import coloriVetrina from "../../json/coloriVetrina.json";
import { useLocation, useNavigate } from "react-router-dom";
import JobErItemMenu from "components/JobErItemMenu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useJobErController } from "context";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import AppBarAziende from "./AppBarAziende";

function LayoutAziende(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useJobErController();
  const { authUser } = controller;

  return (
    <>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: "#002b48!important",
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            padding: 12,
          }}
        >
          <Card sx={{ borderRadius: 7, height: "98vh" }}>
            <CardContent sx={{ paddingTop: 0 }}>
              <Grid container>
                <Grid item xs={12} mt={1} mb={1}>
                  {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                    <img
                      src={require("../../immagini/MyLapamJOB_Logo.png")}
                      style={{
                        height: "70px",
                        width: "250px",
                        borderRadius: 15,
                      }}
                    />
                  ) : (
                    <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
                  )}

                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader" style={{ fontWeight: "bold" }}>
                        Menu
                      </ListSubheader>
                    }
                  >
                    <JobErItemMenu
                      title="Home"
                      active={location.pathname === "/Aziende/Home"}
                      icon={
                        <HomeIcon
                          sx={{
                            color: location.pathname === "/Aziende/Home" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Home")}
                    />
                    <JobErItemMenu
                      title="Annunci"
                      active={location.pathname === "/Aziende/Annunci"}
                      icon={
                        <FactoryIcon
                          sx={{
                            color: location.pathname === "/Aziende/Annunci" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Annunci")}
                    />
                    <JobErItemMenu
                      title="Candidature"
                      active={location.pathname === "/Aziende/Candidature"}
                      icon={
                        <HandshakeIcon
                          sx={{
                            color: location.pathname === "/Aziende/Candidature" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Candidature")}
                    />
                    <JobErItemMenu
                      title="Presentazione"
                      active={location.pathname === "/Aziende/Profilo"}
                      icon={
                        <CoPresentIcon
                          sx={{
                            color: location.pathname === "/Aziende/Profilo" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Aziende/Profilo")}
                    />
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10} style={{ padding: 12 }}>
          <Grid container justifyContent="flex-end" pr={2} mb={3} textAlign="right" spacing={1} alignSelf="center">
            {/* <Grid
              item
              xs="auto"
              style={{ textAlign: "right!important" }}
              alignSelf="center"
            >
              <IconButton aria-label="delete">
                <NotificationsIcon
                  sx={{ fontSize: 32, color: coloriVetrina.warning }}
                />
              </IconButton>
            </Grid> */}
            <Grid item xs="auto" style={{ textAlign: "right!important" }} alignSelf="center">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("partitaIva");
                  navigate("/Vetrina/Home");
                }}
              >
                <PowerSettingsNewIcon sx={{ fontSize: 32, color: coloriVetrina.error }} />
              </IconButton>
            </Grid>
            <Grid item xs="auto" style={{ textAlign: "left!important" }}>
              <Grid container spacing={1}>
                {/* <Grid item xs="auto" alignSelf="center">
                  <KeyboardArrowDownIcon sx={{ color: coloriVetrina.white }} />
                </Grid> */}
                <Grid item xs="auto">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        component="p"
                        ml={1}
                        sx={{
                          fontSize: "14px!important",
                          textAlign: "center",
                          color: coloriVetrina.white,
                          fontWeight: "bold!important",
                          maxWidth: "250px",
                        }}
                      >
                        {authUser?.ragioneSociale ? authUser.ragioneSociale : ""}
                      </Typography>
                      <Typography
                        component="p"
                        ml={1}
                        sx={{
                          fontSize: "14px!important",
                          textAlign: "center",
                          color: coloriVetrina.white,
                          fontWeight: "regular!important",
                        }}
                      >
                        {authUser?.email ? authUser.email : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="auto">
                  <Avatar alt="Remy Sharp" sx={{ width: 55, height: 55 }} src={authUser?.miniatura ? authUser.miniatura : null} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {props.child}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "block", md: "none" },
          backgroundColor: coloriVetrina.bgPortale + "!important",
        }}
      >
        <AppBarAziende />
        <Grid container>{props.child}</Grid>
      </Grid>
    </>
  );
}
export default LayoutAziende;
