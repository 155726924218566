import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import coloriVetrina from "../../../json/coloriVetrina.json";
import { useNavigate } from "react-router-dom";
import JobButton from "components/JobButton";
import { ModalContext } from "context/modalLogin";

function SlideCandidatoJobMORE(props) {
  const navigate = useNavigate();
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" color={coloriVetrina.white} sx={{ textAlign: "left!important" }}>
            Stanco di cercare lavoro invano?
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "2vh" }}>
            Allora non cercare oltre: MyLapam Job è la soluzione perfetta per te!
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Offerte di lavoro aggiornate quotidianamente:</b>
            <br></br>
            Con un'ampia varietà di annunci in diversi settori e per tutti i livelli di esperienza, troverai sicuramente la posizione che fa per te.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Crea il tuo profilo personalizzato:</b>
            <br></br>
            descrivi le tue competenze e i tuoi traguardi, carica il tuo CV e sarai subito pronto a candidarti;
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color={coloriVetrina.white} sx={{ textAlign: "left", mt: "1vh" }}>
            <b>Consigli e supporto:</b>
            <br></br>
            il nostro team di esperti è a tua disposizione per orientarti nel mondo del lavoro;
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md="auto">
          <JobButton
            colorBackground={coloriVetrina.blue}
            colorLabel={coloriVetrina.white}
            colorBorder={coloriVetrina.white}
            size="large"
            label={"Effettua L'Accesso"}
            onClick={() => {
              setIsModalOpen(true);
            }}
            fontSize="18px"
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <JobButton
            colorBackground={coloriVetrina.white}
            colorLabel={coloriVetrina.blue}
            colorBorder={coloriVetrina.white}
            size="large"
            label={"Registrati"}
            onClick={() => navigate("/Vetrina/Registrati")}
            fontSize="18px"
          />
        </Grid>
      </Grid>
    </>
  );
}
export default SlideCandidatoJobMORE;
