import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import coloriVetrina from "../../json/coloriVetrina.json";
import { Typography } from "@mui/material";
import FactoryIcon from "@mui/icons-material/Factory";
import WorkIcon from "@mui/icons-material/Work";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import SchoolIcon from "@mui/icons-material/School";
import PublicIcon from "@mui/icons-material/Public";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: coloriVetrina.blue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: coloriVetrina.blue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: coloriVetrina.lightGray,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: coloriVetrina.blue,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: coloriVetrina.green,
  }),
  ...(ownerState.warning && {
    backgroundColor: coloriVetrina.warning,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, className, onclick } = props;
  const warning = props.stepError.includes(props.icon);
  const completed = props.stepCompleted.includes(props.icon);

  const icons = {
    1: <SearchIcon />,
    2: <FactoryIcon />,
    3: <WorkIcon />,
    4: <FlightTakeoffIcon />,
    5: <SchoolIcon />,
    6: <PublicIcon />,
    7: <AccessibilityNewIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, warning }} className={className} sx={{ cursor: "pointer" }} onClick={onclick}>
      {warning ? <WarningIcon /> : completed ? <CheckIcon /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
  onclick: PropTypes.func,
  warning: PropTypes.bool,
};

const steps = ["Modalità di ricerca", "Titolo", "Figura professionale", "Trasferte", "Titoli studio", "Lingue", "Competenze"];

function StepperCreazioneAnnuncio(props) {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper alternativeLabel activeStep={props.activeStep} connector={<ColorlibConnector />} sx={{ zIndex: 0 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              StepIconProps={{
                onclick: () => props.onSetActiveStep(index),
                stepError: props.stepWithError,
                stepCompleted: props.stepCompleted,
              }}
            >
              <Typography
                component="h6"
                fontSize="12px"
                fontWeight="bold"
                sx={{
                  color: index === props.activeStep ? coloriVetrina.blue : coloriVetrina.gray,
                  fontWeight: index === props.activeStep ? "bold" : "regular",
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
export default StepperCreazioneAnnuncio;
