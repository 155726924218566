export function setStatoLabel(stato) {

    if (stato) {
        switch (stato) {
            case 0:
                return "Disattivato"
            case 1:
                return "Approvato"
            case 2:
                return "Ricerca Terminata"
            case 3:
                return "In attesa di approvazione"
            case 4:
                return "Rifiutata"
            default:
                return null
        }
    }
}

export function modificaAnnuncio(annuncio, setNewAnnuncio, setActiveStep, setStepWithError, setStepCompleted, setDatiAnnuncio){ 

    setNewAnnuncio(true);
    setActiveStep(0);
    setStepWithError([]);
    setStepCompleted([
        1, 2, 3, 4, 5, 6, 7
    ]);

    let cpAnnuncio = {
        ...annuncio,
    };

    if (
        cpAnnuncio.anniEsperienza &&
        cpAnnuncio.anniEsperienza >
        0
    ) {
        cpAnnuncio.richiestaEsperienza =
            "si";
    } else {
        cpAnnuncio.richiestaEsperienza =
            "no";
    }
    setDatiAnnuncio(cpAnnuncio);
}