import React from "react";
import "../../css/App.css";
import "../../css/JobEr.css";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as BB_LONG_ER } from "../../svgicon/JOB_LOGO_CONFER_WHITE.svg";
import { ReactComponent as BB_LONG_MORE } from "../../svgicon/Logo_MylapamJob_WHITE.svg";
import { useNavigate } from "react-router-dom";

function FooterBB(props) {
  const navigate = useNavigate();

  let [color1, color2, color3] = [props.color1, props.color2, props.color3];

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      sx={{
        backgroundColor: color2,
        pl: { xs: 2, md: 15 },
        pr: { xs: 2, md: 15 },
        /*paddingLeft: { xs: 2, md: 9 },
          paddingRight: { xs: 2, md: 9 },*/
      }}
    >
      <Grid item xs={6} md={6} style={{ textAlign: "left" }}>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <BB_LONG_MORE
            fill={color3}
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xl bb_menu"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/Vetrina/Home")}
          ></BB_LONG_MORE>
        ) : (
          <BB_LONG_ER
            fill={color3}
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xl bb_menu"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/Vetrina/Home")}
          ></BB_LONG_ER>
        )}
      </Grid>
      <Grid item xs={6} md={6} alignSelf={"center"}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="p" color={color3} sx={{ textAlign: "right" }}>
              <b>Note Legali</b>
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography component="p" color={color3} sx={{ textAlign: "right" }}>
              <b>Privacy Policy</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FooterBB;
