import React from "react";
import { Grid } from "@mui/material";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import { convertRetribuzioneSuppToOptSelect, convertBenefitToOptSelect } from "pages/portale_aziende/utils/utilsAnnunci";

function AnnuncioStep2(props) {
  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left">
          <Grid item xs={12} md={3}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Richiesta esperienza? *"}
              value={
                props.dati?.richiestaEsperienza && typeof props.dati?.richiestaEsperienza === "object"
                  ? props.dati?.richiestaEsperienza
                  : props.dati?.richiestaEsperienza && typeof props.dati?.richiestaEsperienza === "string"
                  ? options.yes_no.filter((opt) => opt.value === props.dati?.richiestaEsperienza)
                  : null
              }
              onChange={(data) => props.onSetDati("richiestaEsperienza", data)}
              options={options.yes_no}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <JobErInput
              label={
                props.dati && props.dati.richiestaEsperienza && props.dati.richiestaEsperienza.value === "no"
                  ? "Anni"
                  : !props.dati.richiestaEsperienza && !props.dati.anniEsperienza
                  ? "Anni"
                  : "Anni *"
              }
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              type="number"
              disabled={
                props.dati && props.dati.richiestaEsperienza && props.dati.richiestaEsperienza.value === "no"
                  ? true
                  : !props.dati.richiestaEsperienza && !props.dati.anniEsperienza
                  ? true
                  : false
              }
              value={props.dati && props.dati.anniEsperienza ? props.dati.anniEsperienza : ""}
              onChange={(e) => props.onSetDati("anniEsperienza", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <JobErInput
              label="Posizioni aperte *"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              type="number"
              value={props.dati && props.dati.posizioniAperte ? props.dati.posizioniAperte : ""}
              onChange={(e) => props.onSetDati("posizioniAperte", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Tipologia contratto"}
              onChange={(data) => props.onSetDati("tipologiaContratto", data ? data.value : null)}
              value={
                props.dati && props.dati.tipologiaContratto
                  ? options.tipologie_contratto.filter((opt) => opt.label === props.dati.tipologiaContratto || opt.value === props.dati.tipologiaContratto)
                  : null
              }
              options={options.tipologie_contratto}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Dettaglio compenso"}
              onChange={(data) => props.onSetDati("dettaglioCompenso", data)}
              value={
                props.dati && props.dati.dettaglioCompenso
                  ? props.dati.dettaglioCompenso
                  : props.dati.nettoMensile
                  ? {
                      value: "netto",
                      label: "Netto",
                    }
                  : props.dati.ral
                  ? {
                      value: "ral",
                      label: "Ral",
                    }
                  : null
              }
              options={options.dettaglio_compenso}
            />
          </Grid>
          {props.dati && (props.dati.dettaglioCompenso || props.dati.nettoMensile || props.dati.ral) ? (
            <Grid item xs={12} md={3}>
              <JobErInput
                label={props.dati && props.dati.dettaglioCompenso ? (props.dati.dettaglioCompenso.value === "netto" ? "Inserisci netto" : "Inserisci ral") : ""}
                coloreTesto={coloriVetrina.black}
                coloreBordo={coloriVetrina.gray}
                size="small"
                type="number"
                value={
                  props.dati && props.dati.stipendio
                    ? props.dati.stipendio
                    : props.dati.nettoMensile
                    ? props.dati.nettoMensile
                    : props.dati.ral
                    ? props.dati.ral
                    : null
                }
                onChange={(e) => props.onSetDati("stipendio", e.target.value)}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              isMulti={true}
              placeholder={"Retribuzione supplementare"}
              onChange={(data) => props.onSetDati("retribuzioneSupplementare", data)}
              value={
                props.dati && props.dati.retribuzioneSupplementare && typeof props.dati.retribuzioneSupplementare === "object"
                  ? props.dati.retribuzioneSupplementare
                  : props.dati && typeof props.dati.retribuzioneSupplementare === "string"
                  ? convertRetribuzioneSuppToOptSelect(props.dati.retribuzioneSupplementare)
                  : null
              }
              options={options.retribuzione_supplementare}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              isMulti={true}
              placeholder={"Benefit"}
              onChange={(data) => props.onSetDati("benefit", data)}
              value={
                props.dati && props.dati.benefit && typeof props.dati.benefit === "object"
                  ? props.dati.benefit
                  : props.dati && typeof props.dati.benefit === "string"
                  ? convertBenefitToOptSelect(props.dati.benefit)
                  : null
              }
              options={options.benefit}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pr={2} spacing={2} textAlign="left" mt={0}>
          <Grid item xs={12}>
            <JobErInput
              label="Descrizione lavoro *"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati && props.dati.descrizioneLavoro ? props.dati.descrizioneLavoro : ""}
              onChange={(e) => props.onSetDati("descrizioneLavoro", e.target.value)}
              multiline={true}
              rows={5}
              e
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep2;
