import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import FactoryIcon from "@mui/icons-material/Factory";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import { useNavigate } from "react-router-dom";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";

function MenuPortableDevice(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <IconButton size="large" aria-label="" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiPaper-root": {
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            borderBottomLeftRadius: "20px!important",
            minWidth: "60%",
            width: "fit-content",
          },
        }}
      >
        <MenuItem key={"headerlogin_menu_xs"}>
          <Grid container>
            <Grid item xs={12} textAlign="center">
              {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                <img
                  src={require("../../immagini/MyLapamJOB_Logo.png")}
                  style={{
                    height: "70px",
                    width: "250px",
                    borderRadius: 15,
                  }}
                />
              ) : (
                <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg" style={{ height: "1em!important" }}></LOGO_CONFARTIGIANATO>
              )}
            </Grid>
          </Grid>
        </MenuItem>
        {props.pages.map((page, index) => {
          return (
            <MenuItem key={page.url + "_xs"} onClick={() => navigate("/" + page.url.replaceAll(" ", ""))} style={{ justifyContent: "center" }}>
              {page.url === "Persone/Home" ? (
                <HomeIcon sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
              ) : page.url === "Persone/Annunci" ? (
                <FactoryIcon sx={{ color: coloriVetrina.blue, marginRight: 1 }} />
              ) : page.url === "Persone/Candidature" ? (
                <HandshakeIcon
                  sx={{
                    color: coloriVetrina.blue,
                    marginRight: 1,
                  }}
                />
              ) : page.url === "Persone/Profilo" ? (
                <PersonIcon
                  sx={{
                    color: coloriVetrina.blue,
                    marginRight: 1,
                  }}
                />
              ) : null}
              <Typography
                component="p"
                sx={{
                  color: coloriVetrina.blue,
                }}
              >
                {page.descrizione}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
export default MenuPortableDevice;
